import { useState, useEffect } from "react"
import styled from "styled-components"
import { SelectThemeCustom } from "../myprofile/EditMyProfileLayout"
import Select, { OptionTypeBase } from "react-select"
import { useHallplanLink } from "./HallplanNavLink"
import hallplan, { Hall } from "./HallplanWrapper"
import { device } from "../../utils/Device"
import branding from "../../branding/branding"

export interface HallSelectProps {
    selectedHall?: string
    setIsStandSelected?: (value: boolean) => void | undefined
}
export default function HallSelect(props: HallSelectProps) {
    const hallLink = useHallplanLink()
    const [halls, setHalls] = useState<Hall[]>(hallplan.getHalls() ?? [])

    useEffect(() => {
        hallplan.addHallsChangedHandler(setHalls)
        return () => {
            hallplan.removeHallsChangedHandler(setHalls)
        }
    }, [])

    const options: { value: string; label: string }[] = []
    halls.map((hall) => options.push({ label: hall.getName(), value: hall.getId() }))
    let selectedValue = options[0] ?? undefined

    const hall = hallplan.getHall(props.selectedHall)
    if (hall) {
        selectedValue = { label: hall.getName(), value: hall.getId() }
    }

    return (
        <HallSelectRoot
            styles={styledSelectStyles}
            options={options}
            value={selectedValue}
            onChange={(value: OptionTypeBase) => {
                if (value !== null) {
                    props.setIsStandSelected!(false)
                    hallLink.goTo(value.value)
                }
            }}
            theme={SelectThemeCustom}
        />
    )
}
const styledSelectStyles = {
    // eslint-disable-next-line
    control: (provided: any, state: any) => ({
        borderRadius: "5px",
        display: "flex",
        height: "35px",
        fontFamily: branding.font1,
        width: "100%",
        border: state.isFocused ? branding.networkingArea.filterBorderBottomColor : "1px solid #d9d9d9",
        color: branding.networkingArea.filterInputColor,
        outline: "none"
    }),
    option: (styles: { [x: string]: any }, { isFocused, isSelected }: any) => {
        return {
            backgroundColor: isSelected ? "#C9C9C9" : isFocused ? "#C9C9C9" : undefined,
            height: "35px",
            fontSize: "12px",
            width: "auto",
            display: "flex",
            fontFamily: branding.font1,
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "0px",
            marginBottom: "0px",
            textAlign: "left",
            alignItems: "center",
            cursor: "pointer"
        }
    },
    input: (provided: any) => ({
        ...provided,
        width: "100% !important",
        div: {
            width: "100% !important"
        },
        input: {
            opacity: "1 !important",
            width: "100% !important"
        }
    }),
    menu: (provided: any, state: any) => ({
        ...provided,
        border: branding.networkingArea.filterBorderBottomColor,
        boxShadow: "none",
        fontFamily: branding.font1,
        borderRadius: "3px",
        marginTop: "0px",
        marginBottom: "0px",
        padding: "0px"
    }),
    placeholder: (provided: any) => ({
        ...provided,
        fontFamily: branding.font1,
        fontSize: "12px",
        color: branding.networkingArea.filterPlaceholderColor
    })
}

const HallSelectRoot = styled(Select)`
    width: 230px;
    font-family: ${branding.font1};

    font-size: 12px;
    font-family: ${branding.font1};

    @media ${device.tablet} {
        flex: 0 200px;
        width: 100%;
    }

    @media ${device.laptop} {
        flex: 0 250px;
    }

    @media ${device.mobile} {
        font-size: 12px;
        width: 100%;
    }

    & > div[class*="menu"] {
        margin-top: -2px;
    }
`
