import styled from "styled-components"
import * as React from "react"
import branding from "../branding/branding"
import { Category } from "../backendServices/Types"
import { useSuggestContext } from "../navigationArea/useSuggestContext"
import { useHistory } from "react-router-dom"
import { globalSearchResultPageRoute } from "../navigationArea/RoutePaths"
import { CategoryBadge, CategoryText } from "./BadgeArea"
import _ from "lodash"

export const BadgesContainerRoot = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3px;
    margin-top: 10px;

    &.profile {
        width: 290px;
    }
`

const BadgesLimitMarker = styled.div`
    margin-top: 8px;
    font-size: 16px;
    margin-left: 5px;
    color: ${branding.crsTabs.defaultActionItemActiveStateColor};
`
interface BadgesProps {
    topics?: string[]
    categoryBadges?: Category[]
    otherBadges?: string[]
    communicationArea?: boolean
}

const BadgesContainer: React.FC<BadgesProps> = (props) => {
    const suggestState = useSuggestContext()
    const history = useHistory()

    const topicBadges = branding.topicBadges
    const categoryBadgesIds = branding.categoryBadges
    //const categoryBadges = (props.categoryBadges || []).filter((item: Category) => categoryBadgesIds.indexOf(item.id) >= 0)

    const categoryBadges = _.sortBy(
        _.uniqBy(
            (props.categoryBadges || []).filter((item: Category) => categoryBadgesIds.indexOf(item.id) >= 0),
            function (item: Category) {
                return item.id
            }
        ),
        "id"
    )

    const topics = props.topics
    const className = props.communicationArea ? "communicationArea" : ""

    let badgesNumber: number = 0

    const onCategorySelect = (category: Category | any) => {
        if ((category.key || category.id) && category.badgeName) {
            suggestState.addSuggestion({
                icon: "",
                id: "",
                subtitle: "",
                title: category.badgeName,
                value: "cat_" + (category.key || category.id),
                active: true
            })
            history.push(globalSearchResultPageRoute)
        }
    }

    if (
        (topicBadges !== undefined && topicBadges.length > 0 && topics !== undefined && topics.length > 0) ||
        (categoryBadgesIds !== undefined && categoryBadgesIds.length > 0 && categoryBadges && categoryBadges.length > 0) ||
        (props.otherBadges !== undefined && props.otherBadges?.length! > 0)
    ) {
        return (
            <BadgesContainerRoot className={props.communicationArea ? "" : "profile"}>
                {topicBadges &&
                    topicBadges.length > 0 &&
                    topicBadges
                        .sort((topicBadge1, topicBadge2) => {
                            return topicBadge1.badgeText > topicBadge2.badgeText ? 1 : -1
                        })
                        .map((topicBadge) => {
                            return (
                                topics &&
                                topics.map((topicName: string) => {
                                    if (topicBadge.topicName === topicName) {
                                        if (
                                            props.communicationArea &&
                                            badgesNumber === branding.communicationArea.userBadgesLimit
                                        ) {
                                            badgesNumber += 1
                                            return <BadgesLimitMarker>...</BadgesLimitMarker>
                                        } else if (
                                            props.communicationArea &&
                                            badgesNumber > branding.communicationArea.userBadgesLimit
                                        ) {
                                            return null
                                        } else {
                                            badgesNumber += 1
                                            return (
                                                <CategoryBadge
                                                    key={topicName}
                                                    bgImage=""
                                                    bgColor={topicBadge.backgroundColor}
                                                    borderColor={topicBadge.borderColor ?? "black"}
                                                    textColor={topicBadge.textColor ?? "black"}
                                                    className={"person profile " + className}
                                                >
                                                    <CategoryText className="noIcon">{topicBadge.badgeText}</CategoryText>
                                                </CategoryBadge>
                                            )
                                        }
                                    }
                                    return null
                                })
                            )
                        })}
                {categoryBadges &&
                    categoryBadges.length > 0 &&
                    categoryBadges.map((categoryBadge) => {
                        if (categoryBadgesIds.indexOf(categoryBadge.id) >= 0) {
                            if (props.communicationArea && badgesNumber === branding.communicationArea.userBadgesLimit) {
                                badgesNumber += 1
                                return <BadgesLimitMarker>...</BadgesLimitMarker>
                            } else if (props.communicationArea && badgesNumber > branding.communicationArea.userBadgesLimit) {
                                return null
                            } else {
                                badgesNumber += 1

                                return (
                                    <CategoryBadge
                                        key={categoryBadge.id + categoryBadge.badgeName}
                                        bgImage=""
                                        bgColor={categoryBadge.colorFill}
                                        borderColor={categoryBadge.colorBorder}
                                        textColor={categoryBadge.colorText}
                                        onClick={(e: any) => {
                                            onCategorySelect(categoryBadge)

                                            e.stopPropagation()
                                            e.preventDefault()
                                        }}
                                        className={"profile " + className}
                                    >
                                        <CategoryText className="noIcon">{categoryBadge.badgeName}</CategoryText>
                                    </CategoryBadge>
                                )
                            }
                        } else {
                            return null
                        }
                    })}
                {props.otherBadges &&
                    props.otherBadges.map((badge) => {
                        if (props.communicationArea && badgesNumber === branding.communicationArea.userBadgesLimit) {
                            badgesNumber += 1
                            return <BadgesLimitMarker>...</BadgesLimitMarker>
                        } else if (props.communicationArea && badgesNumber > branding.communicationArea.userBadgesLimit) {
                            return null
                        } else {
                            badgesNumber += 1
                            return (
                                <CategoryBadge
                                    key={badge}
                                    bgImage=""
                                    bgColor="#fff"
                                    borderColor={branding.mainInfoColor ?? "black"}
                                    textColor={branding.mainInfoColor ?? "black"}
                                    className={"person profile " + className}
                                >
                                    <CategoryText className="noIcon">{badge}</CategoryText>
                                </CategoryBadge>
                            )
                        }
                    })}
            </BadgesContainerRoot>
        )
    } else {
        return null
    }
}

export default BadgesContainer
