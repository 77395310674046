import { format } from "date-fns"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import { orderBy } from "lodash"
import moment, { Moment } from "moment"
import queryString from "query-string"
import { useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import { Col, Form, Row } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import InView, { useInView } from "react-intersection-observer"
import { useHistory, useRouteMatch } from "react-router-dom"
import Select, { ActionMeta, OptionTypeBase, ValueType } from "react-select"
import styled from "styled-components"
import { CalendarEntryParticipationStatus } from "../../API"
import { syncFavorites } from "../../backendServices/FavoritesServices"
import { Category, EventDate } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { NextPageLoader } from "../../communicationArea/CommunicationArea"
import { useAppState } from "../../globalStates/AppState"
import { useCategoriesState } from "../../globalStates/CategoriesState"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import { homePageRoute, mySchedulePageRoute } from "../../navigationArea/RoutePaths"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import { IconArrowDown, IconClose, IconProgramArrowLeft, IconProgramArrowRight, IconReset } from "../../ui/Icons"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import SearchBar from "../../ui/SearchBar"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { checkLatestTime, getIndexOfInitialDayToShow, isToday, showCurrentTimeMarkerHelper } from "../../utils/DateUtils"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import { isLivePhase } from "../../utils/EventPhaseChecker"
import { OnlyBookmarksButton } from "../BusinessAreaPageContent"
import { SelectThemeCustom } from "../myprofile/EditMyProfileLayout"
import EmptyTile from "../reception/EmptyTile"
import EventDateEntry, { ListEventDateEntryColumn } from "./EventDateEntry"
import { DayData, ScheduleHelper } from "./ScheduleHelper"
import { DatePickerRootTemplate } from "../detailPages/components/StyledComponents"
import { HistoryState } from "./ProgramPageContent"
import { getTileViewMinReqWidth, PagesUsingTileView } from "../../globalStates/TileViewConfig"
import { loadEventDateDates, loadEventDateList, loadRoundTableList } from "../../backendServices/EventdateServices"
import { CalendarEntry, CalendarEntrySortType, getCalendarEntries } from "../../backendServices/GraphQLServices"
import { DisagreeButton, ModalRoot, SubmitButtonContainer, SubmitButtonNew } from "../../ui/modals/SayHelloModal"

const ResetFilterButton = styled.div<{ visible?: boolean }>`
    display: flex;
    font-family: ${branding.font1};
    height: 38px;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px;
    border-style: solid;
    background-color: ${branding.exhibitorsPageContent.resetFilterButtonBgColor};
    border-color: ${branding.exhibitorsPageContent.resetFilterBorderColor ?? "#000"};
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    visibility: ${(props) => (props.visible ? "visible" : "hidden")};

    @media ${device.mobile} {
        width: 43vw;
    }

    @media (max-width: 380px) {
        width: 42vw;
    }
`
const FilterResetIconSpan = styled.span`
    margin-left: 10px;
`
const FilterResetTextSpan = styled.span`
    position: relative;
    width: auto;
    text-align: center;
    padding: 0.3rem 1rem;
    display: inline-block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const FilterBarRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
        gap: 17px;
    }
`

interface FilterButtonsComponentProps {
    onResetFilterButtonClick: () => void
    strings: any
    resetFilterVisible: boolean
}

const FilterButtonsComponent: React.FunctionComponent<FilterButtonsComponentProps> = (props: FilterButtonsComponentProps) => {
    const content: JSX.Element = (
        <>
            <ResetFilterButton visible={props.resetFilterVisible} onClick={() => props.onResetFilterButtonClick()}>
                <FilterResetIconSpan>{IconReset({ fill: "#fff" })} </FilterResetIconSpan>
                <FilterResetTextSpan>{props.strings.businessAreaPageContent.resetFilterButtonText}</FilterResetTextSpan>
            </ResetFilterButton>
        </>
    )
    return (
        <>
            <DesktopVersionContainer>
                <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                <Row style={{ marginLeft: "0px", marginRight: "0px" }}>
                    <FilterBarRoot style={{ padding: 0 }}>{content}</FilterBarRoot>
                </Row>
            </MobileVersionContainer>
        </>
    )
}

const ScheduleRoot = styled.div<{ isFavourite?: boolean }>`
    font-family: ${branding.font1};
    position: relative;
    background-color: #fff;
    ${(props) => props.isFavourite && "width: 100%; margin-right: 30px;"}
    padding: 0 25px 20px 25px;

    @media ${device.mobile} {
        padding-left: 20px;
        padding-right: 15px;
    }
`

const FiltersRoot = styled.div`
    display: flex;
    align-items: start;
    margin-left: 0px;
    flex-wrap: wrap;
    gap: 10px;

    @media ${device.mobile} {
        gap: 4.6vw;
    }

    @media (width: 414px) and (height: 736px) {
        gap: 5.5vw;
    }
`
const Revealer = styled.div`
    position: absolute;
    width: 1px;
    height: 1px;
    bottom: 300px;
`

const ScheduleGroupHeaderRoot = styled.div<{ visible: boolean }>`
    display: ${(props) => (props.visible ? "block" : "none")};
    @media ${device.mobile} {
        box-shadow: ${branding.primaryScrollDarkShadowTTB};
        margin-left: -20px;
        padding-left: 20px;
        margin-right: -15px;
        padding-right: 15px;
        margin-top: -25px;
        height: auto;
        min-height: 180px;
    }
`

const ScheduleGroupHeaderContainer = styled(Row)`
    margin: 20px 0 40px 0;

    @media ${device.mobile} {
        margin-bottom: 0px;
    }
`

const ArrowButtonGroup = styled.div`
    border-radius: 5px;
    display: flex;
    background-color: #fff;
    height: 35px;
`
const DatePickerRoot = styled(DatePickerRootTemplate)`
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 8px;

    & .react-datepicker-popper > div {
        /* This fixes the problem with closing the 
          datepicker by clicking
          manywhere on the DOM */
        width: 0;
    }

    & .react-datepicker {
        border: 1px solid #727272;
        right: 82px;
        top: 10px;
    }

    & .react-datepicker__day-name {
        width: 30px;
        margin: 3px 8px;
    }

    & .react-datepicker__day {
        width: 30px;
        margin: 1px 8px;
    }

    & .react-datepicker__triangle {
        position: absolute !important;
        left: 50% !important;
        border-bottom-color: #fff !important;
    }

    & .react-datepicker__triangle::before {
        border-bottom-color: #727272 !important;
    }
`

const NowButton = styled.div`
    min-width: 100px;
    max-width: 400px;
    padding: 0 20px;
    margin-left: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: ${branding.programSchedule.nowButtonBgColor ?? "#FFF"};
    color: ${branding.programSchedule.nowButtonColor ?? "#000"};
    font-family: ${branding.font1};
    font-size: 14px;
    font-weight: normal;
    border-radius: 5px;

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
        border: none;
    }

    @media ${device.mobile} {
        display: none;
    }
`

export const ArrowButton = styled.div`
    cursor: pointer;
    padding: 5px 15px;

    :nth-child(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    :nth-child(2) {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

const MobileSearchRoot = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 25px;
    flex-wrap: wrap;

    @media ${device.mobile} {
        padding: 0;
        gap: 15px;
    }
`

const BookmarkButtonCol = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    svg {
        color: ${branding.primaryColor};
    }
`

const SearchBarContainer = styled.div`
    margin-top: "1px";
    svg {
        color: ${branding.primaryColor};
    }

    @media (max-width: 1200px) {
        & div.MuiTextField-root {
            width: 200px;
        }
    }
`

const styledSelectStyles = {
    control: (provided: any, state: any) => ({
        border: state.isFocused ? "1px solid #000" : "1px solid #c9c9c9",
        borderRadius: "5px",
        display: "flex"
    })
}

const StyledSelect = styled(Select)`
    width: 185px;
    z-index: 6;
    font-size: 12px;
    z-index: 110;

    @media ${device.mobile} {
        width: 43vw;
    }

    @media (max-width: 380px) {
        width: 42vw;
    }

    &-menu {
        z-index: 1000;
    }
`

const isNowButtonEnabled = (days: Moment[]) => {
    const liveEventsToday = days.filter((day) => isToday(day))
    return isLivePhase && liveEventsToday && liveEventsToday.length > 0
}

interface ScheduleProps {
    category?: String
    onlyFavorites?: boolean
    addBannerHeight?: number
    guestBannerHeight: number
    onScroll?: Function
    dateColumns?: boolean
    dateColumnsDays?: moment.Moment[]
    mySchedule?: boolean
    myScheduleResponsive?: boolean
    day?: number
    removeOnBookmarkClick?: boolean
    roundScroller?: boolean
    isInMyFairPage?: boolean
    listViewMode: boolean
    filtersVisible?: boolean

    setScheduleListView?: (value: boolean) => void
}

function buildFilterlist(
    props: ScheduleProps,
    isBookmarked: boolean,
    favorites: string,
    searchParam: string,
    stage: string,
    selectedBadgeFilter: string,
    categoryFilter: string,
    secondCategoryFilter: string
) {
    const parsedSearchParam = searchParam.replaceAll("_", "\\_")
    const filterlist = isBookmarked && favorites ? [favorites, parsedSearchParam] : ["entity_evtd", parsedSearchParam]
    const catfilter = props.category ? "cat_" + props.category : undefined

    if (catfilter) filterlist.push(catfilter)
    if (selectedBadgeFilter) filterlist.push(`cat_${selectedBadgeFilter}`)
    if (secondCategoryFilter && branding.programSchedule.showSecondCategoriesFilter)
        filterlist.push(`cat_${secondCategoryFilter}`)
    if (branding.programSchedule.useCategoriesInsteadOfStages && categoryFilter) filterlist.push(`cat_${categoryFilter}`)
    else filterlist.push(stage)

    return filterlist
}

const Schedule: React.FunctionComponent<ScheduleProps> = (props: ScheduleProps) => {
    const appState = useAppState()
    const userAccessState = useUserRestrictedAreaAccess()
    const history = useHistory()
    const [revealerRef] = useInView()
    const [error, setError] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const [eventDates, setEventDates] = useState<EventDate[]>([])
    const [meetings, setMeetings] = useState<CalendarEntry[]>([])
    const [roundTables, setRoundTables] = useState<EventDate[]>([]) // eslint-disable-line
    const [days, setDays] = useState<moment.Moment[]>([])
    const [startResultRow, setStartResultRow] = useState(0)
    const favorites = useFavoriteState()
    const [refreshKey, setRefreshKey] = useState(0)
    const loggedInUserId = useLoggedInState().user()?.profileId
    const strings = useLanguageState().getStrings()
    const [groups, setGroups] = useState<DayData[]>([])
    const [index, setIndex] = useState((history.location.state as HistoryState)?.selectedDateIndex ?? 0)
    const [isBookmarked, setIsBookmarked] = useState(props.onlyFavorites ? true : false)
    const [stages, setStages] = useState<{ stage: string; filter: string; order?: number }[]>([])
    const [allStages] = useState<{ stage: string; filter: string }[]>([])
    const categories = strings.programSchedule.categoriesFilterList
    const secondCategories = strings.programSchedule.secondCategoriesFilterList
    const badgeFilterList = strings.programSchedule.badgeFilterList
    const [initLoad, setInitLoad] = useState(true)

    // search filters
    const [searchParam, setSearchParam] = useState((history.location.state as HistoryState)?.searchParam ?? "")
    const [stageFilter, setstageFilter] = useState<{ stage: string; filter: string } | null>(
        (history.location.state as HistoryState)?.stageFilter || null
    )
    const [categoryFilter, setCategoryFilter] = useState<{ label: string; value: string } | null>(
        (history.location.state as HistoryState)?.firstCategoryFilter ?? null
    )
    const [secondCategoryFilter, setSecondCategoryFilter] = useState<{ label: string; value: string } | null>(
        (history.location.state as HistoryState)?.secondCategoryFilter ?? null
    )
    const [selectedBadgeFilter, setSelectedBadgeFilter] = useState<{ label: string; value: string } | null>(
        (history.location.state as HistoryState)?.badgeFilter ?? null
    )

    const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)

    const [ordered, setOrdered] = useState<boolean>(true)

    const [nowButtonEnabled, setNowButtonEnabled] = useState(isNowButtonEnabled(days))
    const [jumpToLive, setJumpToLive] = useState(0)

    const [updatedMeetingKey, setUpdatedMeetingKey] = useState("")

    const [stageRefreshKey, setStageRefreshKey] = useState(0)
    const [countEventdates, setCountEventdates] = useState(0)
    const numResultRow = 100

    const categoriesState = useCategoriesState()
    const [pointsBadgeData, setPointsBadgeData] = useState<Category>()
    const [showResetNowButtonModal, setShowResetNowButtonModal] = useState<boolean>(false)

    const windowSize = useWindowDimensions()

    let datePickerRootRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setPointsBadgeData(categoriesState.getCategoryByAlias(branding.programSchedule.pointsBadgeCategoryAlias))

        userAccessState.fetchUserAccessForAllEventDates()
        // eslint-disable-next-line
    }, [])

    const [showAsList, setShowAsList] = useState(branding.programSchedule.listViewAsDefault ?? false)

    const lang = useLanguageState().getLanguage()
    let date: Date // Selected Date, quite important

    const eventDateFavorites = favorites.get("eventdate", true)
    const tileViewMinReqWidth = getTileViewMinReqWidth(PagesUsingTileView.SCHEDULE)

    useEffect(() => {
        setShowAsList(windowSize.width < tileViewMinReqWidth ? true : props.listViewMode)
        // eslint-disable-next-line
    }, [windowSize, appState.isNetworkingOpen()])

    useEffect(() => {
        if (props.onlyFavorites)
            setEventDates((eventDates) => eventDates.filter((eventDate) => favorites.is("eventdate", eventDate.id)))
    }, [eventDateFavorites]) //eslint-disable-line

    const isMoreDataToLoad = useCallback(() => {
        return countEventdates - numResultRow > startResultRow
    }, [countEventdates, startResultRow])

    useEffect(() => {
        //added because of press and media page (need to show only press conference events)
        const queryParam = queryString.parse(window.location.search)
        if (queryParam && queryParam.pressStage)
            setstageFilter({
                stage: branding.pressMediaPageContent.pressEventStage.stage,
                filter: branding.pressMediaPageContent.pressEventStage.filter
            })
    }, []) //eslint-disable-line

    useEffect(() => {
        setShowAsList(windowSize.isMobile || (windowSize.width < tileViewMinReqWidth ? true : props.listViewMode))

        if (props.setScheduleListView) {
            props.setScheduleListView(props.listViewMode)
        }
        // eslint-disable-next-line
    }, [props.listViewMode])

    useEffect(() => {
        setIsBookmarked(props.onlyFavorites ?? false)
        if (!initLoad) setstageFilter(null)
        setInitLoad(false)
    }, [props.onlyFavorites]) // eslint-disable-line

    useEffect(() => {
        const indexFromState = (history.location.state as HistoryState)?.selectedDateIndex
        const indexOfDayToDisplay = indexFromState !== undefined ? indexFromState : getIndexOfInitialDayToShow(days)
        setIndex(indexOfDayToDisplay)
    }, [days]) // eslint-disable-line

    useEffect(() => {
        setStages([])
        stages.map((element) => {
            if (
                !allStages.find(({ stage, filter }) => {
                    return element.stage === stage && element.filter === filter
                })
            )
                allStages.push(element)
            return 1
        })

        // eslint-disable-next-line
    }, [index, isBookmarked])

    useEffect(() => {
        setStartResultRow(0)
    }, [
        isBookmarked,
        index,
        stageFilter,
        searchParam,
        showAsList,
        selectedBadgeFilter,
        appState.timezone,
        categoryFilter,
        secondCategoryFilter
    ]) // eslint-disable-line

    useEffect(() => {
        setStageRefreshKey(stageRefreshKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stageFilter, searchParam, appState.timezone, categoryFilter, secondCategoryFilter])

    useEffect(() => {
        allStages.splice(0, allStages.length)
        setstageFilter(null)
    }, [lang]) // eslint-disable-line

    useEffect(() => {
        loadEventDateDates({})
            .then((resp) => {
                if (resp.dates?.length > 0) {
                    const daysMoment = resp.dates.map((date) => moment(date))
                    setDays(daysMoment)
                    setNowButtonEnabled(isNowButtonEnabled(daysMoment))
                } else {
                    setError("no dates found")
                }
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                return setError(e.message)
            })
    }, [])

    async function loadCalendarEntries() {
        let data
        let temp: CalendarEntry[] = []
        let nextToken
        if (loggedInUserId) {
            do {
                data = await getCalendarEntries(
                    loggedInUserId,
                    CalendarEntrySortType.ALL,
                    CalendarEntryParticipationStatus.ACCEPTED,
                    nextToken
                )
                if (data) {
                    data.items.forEach((x) => {
                        temp.push(x.calendarEntry)
                    })
                    nextToken = data?.nextToken
                }
            } while (nextToken) // TODO paginate on user scrolling

            setMeetings(temp)
        }
    }

    async function loadRoundTables() {
        if (loggedInUserId) {
            const data = await loadRoundTableList({}, loggedInUserId)
            setRoundTables(data.eventDates)
        }
    }

    useEffect(() => {
        if (props.mySchedule) {
            loadCalendarEntries()
            loadRoundTables()
        }

        //eslint-disable-next-line
    }, [props.mySchedule, updatedMeetingKey, appState.timezone])

    useEffect(() => {
        let date = new Date()
        date.setMonth(date.getMonth() - 6)
        if (loggedInUserId) {
            setIsLoaded(false)
            syncFavorites({
                profileId: loggedInUserId,
                body: {
                    currentTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    lastSyncTime: favorites.getLastSyncTime()
                }
            })
                .then((resp) => {
                    setIsLoaded(true)
                    favorites.setLastSyncTime(new Date())
                    const temp: Array<any> = resp.content.favorites
                    temp.forEach((item) => {
                        if (!item.deleted && !favorites.is("eventdate", item.id)) {
                            favorites.add("eventdate", item.id)
                        }
                    })
                    setRefreshKey(1)
                })
                .catch((e: { message: React.SetStateAction<string> }) => {
                    setIsLoaded(true)
                    setError(e.message)
                })
        }

        //eslint-disable-next-line
    }, [loggedInUserId])

    useEffect(() => {
        let abortController = new AbortController()
        if (refreshKey > 0 && days[index] !== undefined) {
            const selectedDate = days[index].format("YYYY-MM-DD")
            setIsLoaded(false)

            const timeout = setTimeout(() => {
                loadEventDateList({
                    filterlist: buildFilterlist(
                        props,
                        isBookmarked,
                        favorites.get("eventdate"),
                        searchParam,
                        stageFilter?.filter || "",
                        selectedBadgeFilter?.value || "",
                        categoryFilter?.value || "",
                        secondCategoryFilter?.value || ""
                    ),
                    startresultrow: startResultRow,
                    numresultrows: numResultRow,
                    day: !props.mySchedule ? selectedDate : null,
                    order: "chrono"
                })
                    //}, false, abortController.signal)
                    //FIXME - the abortController signal sometimes causes schedule to not be loaded
                    .then((resp) => {
                        if (favorites.get("eventdate") === "" && isBookmarked) {
                            setEventDates([])
                            setCountEventdates(0)
                        } else if (refreshKey === 1) {
                            if (showAsList) {
                                setEventDates((e) =>
                                    startResultRow === 0
                                        ? (e = orderBy(resp.eventDates, ["startHour", "startMinutes"], ["asc"]))
                                        : e.concat(orderBy(resp.eventDates, ["startHour", "startMinutes"], ["asc"]))
                                )
                            } else {
                                const stagesOrder = branding.programSchedule.stagesOrder
                                resp.eventDates.forEach((element) => {
                                    element.order = parseInt(
                                        stagesOrder.find((x) => x.stage === element.location)?.order.toString() || "-1"
                                    )
                                    if (
                                        element.dateTimeStart.indexOf(selectedDate) >= 0 &&
                                        element.dateTimeEnd.indexOf(selectedDate) < 0
                                    ) {
                                        element.endMinutes = 59
                                        element.endHour = 23
                                    } else if (
                                        element.dateTimeEnd.indexOf(selectedDate) >= 0 &&
                                        element.dateTimeStart.indexOf(selectedDate) < 0
                                    ) {
                                        element.startMinutes = 0
                                        element.startHour = 0
                                        element.date = selectedDate
                                    }
                                })
                                setEventDates((e) =>
                                    startResultRow === 0
                                        ? (e = orderBy(resp.eventDates, ["order"], ["asc"]))
                                        : e.concat(orderBy(resp.eventDates, ["order"], ["asc"]))
                                )
                            }
                            setCountEventdates((e) => (e = resp.count!))
                        }
                        setIsLoaded(true)
                    })
                    .catch((e: { message: React.SetStateAction<string> }) => {
                        setIsLoaded(true)
                        return setError(e.message)
                    })
            }, 600)

            return () => clearTimeout(timeout)
        }

        if (!props.mySchedule) {
            // Quick fix for mySchedule favorites not loading, TODO fix this page
            return () => {
                abortController.abort()
            }
        }
        //eslint-disable-next-line
    }, [
        startResultRow,
        refreshKey,
        isBookmarked,
        props.myScheduleResponsive,
        props.day,
        stageRefreshKey,
        stageFilter,
        days,
        selectedBadgeFilter,
        lang
    ])

    useEffect(() => {
        if (!showAsList) {
            const stagesOrder = branding.programSchedule.stagesOrder

            eventDates.forEach((element) => {
                element.order = parseInt(stagesOrder.find((x) => x.stage === element.location)?.order.toString() || "-1")
            })

            setEventDates(orderBy(eventDates, ["order"], ["asc"]))
        } else {
            setEventDates(orderBy(eventDates, ["startHour", "startMinutes"], ["asc"]))
        }

        setOrdered(true)

        // eslint-disable-next-line
    }, [showAsList])

    const handleClickHelper = (idx: number) => {
        setIndex(idx)
        history.replace({
            state: { ...(history.location.state as HistoryState), selectedDateIndex: idx }
        })

        setStages([])
        allStages.splice(0, allStages.length)
        setStageRefreshKey(stageRefreshKey + 1)
        setstageFilter(null)
    }

    function resetSearch() {
        setCategoryFilter(null)
        setSecondCategoryFilter(null)
        setSelectedBadgeFilter(null)
        setstageFilter(null)
        setSearchParam("")
        history.replace({ state: null })
    }

    function isScheduleEmpty(): boolean {
        if (props.mySchedule) {
            return (
                (!eventDates || eventDates.length === 0) &&
                (!meetings || meetings.length === 0) &&
                (!roundTables || roundTables.length === 0)
            )
        } else {
            return !eventDates || eventDates.length === 0
        }
    }

    function resetResults() {
        if (nowButtonEnabled) {
            if (!isToday(days[index])) handleClickHelper(getIndexOfInitialDayToShow(days))
            setJumpToLive(jumpToLive + 1)
        }

        resetSearch()
    }

    useEffect(() => {
        if (isScheduleEmpty()) {
            setGroups(getGroupsByDay(eventDates, date))
            return
        }

        if (props.mySchedule) {
            let eventsArray: EventDate[] = []
            if (eventDates) {
                eventsArray = eventsArray.concat(eventDates)
            }
            if (roundTables) {
                eventsArray = eventsArray.concat(roundTables)
            }
            setGroups(getGroupsByDayMySchedule(eventsArray, meetings, appState.timezone, days))
        } else {
            setGroups(getGroupsByDay(eventDates, date))
        }

        let stagesTemp = getStagesByDay(eventDates, date)

        const stagesOrder = branding.programSchedule.stagesOrder

        stagesTemp.forEach((element) => {
            element.order = parseInt(stagesOrder.find((x) => x.stage === element.stage)?.order.toString() || "-1")
        })
        setStages(orderBy(stagesTemp, ["order"], ["asc"]))

        stagesTemp = orderBy(stagesTemp, ["order"], ["asc"])

        stagesTemp.map((element) => {
            if (
                !allStages.find(({ stage, filter }) => {
                    return element.stage === stage && element.filter === filter
                })
            )
                allStages.push(element)
            return 1
        })

        //eslint-disable-next-line
    }, [eventDates, meetings])

    const handleClickOutside = (event: any) => {
        if (datePickerRootRef.current && !datePickerRootRef.current.contains(event.target)) {
            setDatePickerOpen(false)
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)
        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [])

    if (error) {
        return (
            <div style={{ marginTop: "20%" }}>
                <BackendError />
            </div>
        )
    } else {
        if (!eventDates) return <div>{strings.programSchedule.noDataMessage}</div>

        if (days.length === 0) {
            return <CenteredLoader />
        }

        const isTemporaryDate = (date: any) => {
            const temporaryDate = new Date(0) //returns 1 Jan 1970

            return (
                date.getDate() === temporaryDate.getDate() &&
                date.getMonth() === temporaryDate.getMonth() &&
                date.getFullYear() === temporaryDate.getFullYear()
            )
        }

        date = days[index] === undefined ? new Date(0) : days[index].toDate()

        const dayOfWeek = [
            strings.programSchedule.sunday,
            strings.programSchedule.monday,
            strings.programSchedule.tuesday,
            strings.programSchedule.wednesday,
            strings.programSchedule.thursday,
            strings.programSchedule.friday,
            strings.programSchedule.saturday
        ][date.getDay()] //TODO` Set back this to date asap
        const dateString = new Intl.DateTimeFormat(lang, {
            year: "numeric",
            month: "long",
            day: "numeric"
        }).format(date) //TODO Set back this to date asap

        const labelString = dayOfWeek + ", " + dateString

        const handleClickPreviousDay = () => {
            if (index > 0) handleClickHelper(index - 1)
        }

        const handleClickNextDay = () => {
            if (index < days.length - 1) handleClickHelper(index + 1)
        }

        const handleClickDatePicker = (date: Date) => {
            const clickedDate = moment(date)
            const i = days.findIndex((day) => day.isSame(clickedDate))
            if (i <= days.length - 1) handleClickHelper(i)
        }

        return (
            <ScheduleRoot isFavourite={props.onlyFavorites} className="schedule-root" onClick={() => setDatePickerOpen(false)}>
                {!isLoaded && startResultRow === 0 && <CenteredLoader />}
                {!props.dateColumns && (
                    <ScheduleGroupHeaderRoot visible={props.filtersVisible ?? true}>
                        <ScheduleGroupHeaderContainer style={{ fontSize: "12px" }}>
                            <Col
                                xs={12}
                                style={{
                                    marginBottom: "15px",
                                    paddingLeft: "0px",
                                    paddingRight: "0px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexWrap: "wrap",
                                    gap: "10px"
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ display: "flex" }}>
                                        <ArrowButtonGroup>
                                            <ArrowButton
                                                style={{
                                                    pointerEvents: index === 0 ? "none" : "auto",
                                                    fontSize: "16px",
                                                    borderLeft: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderRight: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderTop: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderBottom: index === 0 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    marginRight: index === 0 ? "-1" : "0",
                                                    zIndex: index === 0 ? 0 : 1
                                                }}
                                                onClick={handleClickPreviousDay}
                                            >
                                                {IconProgramArrowLeft({
                                                    fill: index === 0 ? "#c9c9c9" : branding.sideIconBar.sideIconColorDark
                                                })}
                                            </ArrowButton>

                                            <ArrowButton
                                                style={{
                                                    pointerEvents: index >= days.length - 1 ? "none" : "auto",
                                                    fontSize: "16px",
                                                    borderLeft:
                                                        index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderRight:
                                                        index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderTop:
                                                        index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    borderBottom:
                                                        index >= days.length - 1 ? "1px solid #c9c9c9 " : "1px solid #8c8c8c",
                                                    marginLeft: -1,
                                                    zIndex: index >= days.length - 1 ? 0 : 1
                                                }}
                                                onClick={handleClickNextDay}
                                            >
                                                {IconProgramArrowRight({
                                                    fill:
                                                        index >= days.length - 1
                                                            ? "#c9c9c9"
                                                            : branding.sideIconBar.sideIconColorDark
                                                })}
                                            </ArrowButton>
                                        </ArrowButtonGroup>

                                        <DatePickerRoot
                                            ref={datePickerRootRef}
                                            onClick={(e: any) => {
                                                e.stopPropagation()
                                            }}
                                        >
                                            <DatePicker
                                                locale={lang === "de" ? de : en}
                                                selected={days[index].toDate()}
                                                open={datePickerOpen}
                                                useWeekdaysShort={true}
                                                onChange={handleClickDatePicker}
                                                customInput={
                                                    <GroupHeaderLabel datePickerOpen={datePickerOpen}>
                                                        <div
                                                            onClick={() => {
                                                                setDatePickerOpen(!datePickerOpen)
                                                            }}
                                                            style={{ position: "relative", marginLeft: "20px" }}
                                                        >
                                                            {isTemporaryDate(date) ? "" : labelString}
                                                            <span>
                                                                {IconArrowDown({
                                                                    fill: branding.mainInfoColor,
                                                                    height: "22",
                                                                    width: "22"
                                                                })}
                                                            </span>
                                                        </div>
                                                    </GroupHeaderLabel>
                                                }
                                                includeDates={days.map((day) => day.toDate())}
                                                disabledKeyboardNavigation
                                            />
                                        </DatePickerRoot>
                                        {!showAsList && (
                                            <NowButton
                                                onClick={() => {
                                                    if (
                                                        stageFilter?.filter ||
                                                        selectedBadgeFilter?.value ||
                                                        categoryFilter?.value ||
                                                        secondCategoryFilter?.value ||
                                                        searchParam
                                                    ) {
                                                        if (appState.isDontShowModalClicked) {
                                                            if (!isToday(days[index]))
                                                                handleClickHelper(getIndexOfInitialDayToShow(days))

                                                            setJumpToLive(jumpToLive + 1)
                                                            resetResults()
                                                        } else setShowResetNowButtonModal(true)
                                                    }
                                                }}
                                                className={
                                                    nowButtonEnabled ||
                                                    stageFilter?.filter ||
                                                    selectedBadgeFilter?.value ||
                                                    categoryFilter?.value ||
                                                    secondCategoryFilter?.value
                                                        ? ""
                                                        : "disabled"
                                                }
                                            >
                                                {strings.programSchedule.nowButtonText}
                                            </NowButton>
                                        )}
                                        {showResetNowButtonModal && (
                                            <NowButtonResetModal
                                                toggleResults={() => resetResults()}
                                                onClose={() => setShowResetNowButtonModal(false)}
                                            />
                                        )}
                                    </div>
                                    <div style={{ display: "flex", color: branding.programSchedule.iconsColor ?? "#000" }}>
                                        {!ordered && (
                                            <div style={{ marginRight: "5px", textAlign: "center", cursor: "pointer" }}>
                                                <div style={{ height: "30px", width: "30px", margin: "0 auto" }} className="size">
                                                    <CenteredLoader size="sm" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!props.onlyFavorites && strings.programSchedule.allStagesText && (
                                    <div
                                        className="d-flex justify-content-flex-end align-items-end"
                                        style={{ paddingLeft: "0px", marginTop: "2px" }}
                                    >
                                        <FiltersRoot>
                                            {" "}
                                            <MobileVersionContainer style={{ width: "100%" }}>
                                                <MobileSearchRoot>
                                                    <SearchBarComponent
                                                        currentSearch={searchParam}
                                                        setCurrentSearch={(search: string) => {
                                                            setSearchParam(search)
                                                            history.replace({
                                                                state: {
                                                                    ...(history.location.state as HistoryState),
                                                                    searchParam: search
                                                                }
                                                            })
                                                        }}
                                                        bookmarkFilter={isBookmarked}
                                                        setBookmarkFilter={setIsBookmarked}
                                                        strings={strings}
                                                    />
                                                </MobileSearchRoot>
                                            </MobileVersionContainer>
                                            {/* style={{ marginLeft: "20px" }} */}
                                            {branding.programSchedule.useCategoriesInsteadOfStages ? (
                                                <StyledSelect
                                                    styles={styledSelectStyles}
                                                    placeholder={strings.programSchedule.allCategoriesFilterText}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={categories}
                                                    value={categoryFilter}
                                                    noOptionsMessage={() => strings.programSchedule.filterNoOptionsMessage}
                                                    onChange={(
                                                        value: ValueType<OptionTypeBase, boolean>,
                                                        action: ActionMeta<OptionTypeBase>
                                                    ) => {
                                                        let stateValue = null
                                                        if (value !== null && action.action === "select-option") {
                                                            const option = value as OptionTypeBase
                                                            const newCategory = { label: option.label, value: option.value }
                                                            if (categoryFilter?.value !== newCategory.value) {
                                                                setCategoryFilter(newCategory)
                                                                stateValue = newCategory
                                                            }
                                                        } else setCategoryFilter(null)

                                                        history.replace({
                                                            state: {
                                                                ...(history.location.state as HistoryState),
                                                                firstCategoryFilter: stateValue
                                                            }
                                                        })
                                                    }}
                                                    theme={SelectThemeCustom}
                                                />
                                            ) : (
                                                <StyledSelect
                                                    styles={styledSelectStyles}
                                                    placeholder={strings.programSchedule.allStagesText}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={allStages.map((stage) => {
                                                        return { label: stage.stage, value: stage.filter }
                                                    })}
                                                    value={
                                                        stageFilter
                                                            ? { label: stageFilter?.stage, value: stageFilter?.filter }
                                                            : null
                                                    }
                                                    noOptionsMessage={() => strings.programSchedule.filterNoOptionsMessage}
                                                    onChange={(
                                                        value: ValueType<OptionTypeBase, boolean>,
                                                        action: ActionMeta<OptionTypeBase>
                                                    ) => {
                                                        let stateValue = null
                                                        if (value !== null && action.action === "select-option") {
                                                            const option = value as OptionTypeBase
                                                            const newStage = { stage: option.label, filter: option.value }
                                                            if (stageFilter?.filter !== newStage.filter) {
                                                                setstageFilter(newStage)
                                                                stateValue = newStage
                                                            }
                                                        } else setstageFilter(null)

                                                        history.replace({
                                                            state: {
                                                                ...(history.location.state as HistoryState),
                                                                stageFilter: stateValue
                                                            }
                                                        })
                                                    }}
                                                    theme={SelectThemeCustom}
                                                />
                                            )}
                                            {branding.programSchedule.showBadgeFilter && (
                                                <StyledSelect
                                                    styles={styledSelectStyles}
                                                    placeholder={strings.programSchedule.badgeAllText}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={badgeFilterList}
                                                    value={selectedBadgeFilter}
                                                    noOptionsMessage={() => strings.programSchedule.filterNoOptionsMessage}
                                                    onChange={(
                                                        value: ValueType<OptionTypeBase, boolean>,
                                                        action: ActionMeta<OptionTypeBase>
                                                    ) => {
                                                        let stateValue = null
                                                        if (value !== null && action.action === "select-option") {
                                                            const option = value as OptionTypeBase
                                                            const newBadgeFilter = { label: option.label, value: option.value }
                                                            if (selectedBadgeFilter?.value !== newBadgeFilter.value) {
                                                                setSelectedBadgeFilter(newBadgeFilter)
                                                                stateValue = newBadgeFilter
                                                            }
                                                        } else setSelectedBadgeFilter(null)

                                                        history.replace({
                                                            state: {
                                                                ...(history.location.state as HistoryState),
                                                                badgeFilter: stateValue
                                                            }
                                                        })
                                                    }}
                                                    theme={SelectThemeCustom}
                                                />
                                            )}
                                            {branding.programSchedule.showSecondCategoriesFilter && (
                                                <StyledSelect
                                                    styles={styledSelectStyles}
                                                    placeholder={strings.programSchedule.secondCategoriesFilterText}
                                                    isMulti={false}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={secondCategories}
                                                    value={secondCategoryFilter}
                                                    noOptionsMessage={() => strings.programSchedule.filterNoOptionsMessage}
                                                    onChange={(
                                                        value: ValueType<OptionTypeBase, boolean>,
                                                        action: ActionMeta<OptionTypeBase>
                                                    ) => {
                                                        let stateValue = null
                                                        if (value !== null && action.action === "select-option") {
                                                            const option = value as OptionTypeBase
                                                            const newCategory = { label: option.label, value: option.value }
                                                            if (secondCategoryFilter?.value !== newCategory.value) {
                                                                setSecondCategoryFilter(newCategory)
                                                                stateValue = newCategory
                                                            }
                                                        } else setSecondCategoryFilter(null)

                                                        history.replace({
                                                            state: {
                                                                ...(history.location.state as HistoryState),
                                                                secondCategoryFilter: stateValue
                                                            }
                                                        })
                                                    }}
                                                    theme={SelectThemeCustom}
                                                />
                                            )}
                                            <DesktopVersionContainer>
                                                <BookmarkButtonCol>
                                                    <SearchBarComponent
                                                        currentSearch={searchParam}
                                                        setCurrentSearch={(search: string) => {
                                                            setSearchParam(search)
                                                            history.replace({
                                                                state: {
                                                                    ...(history.location.state as HistoryState),
                                                                    searchParam: search
                                                                }
                                                            })
                                                        }}
                                                        bookmarkFilter={isBookmarked}
                                                        setBookmarkFilter={setIsBookmarked}
                                                        strings={strings}
                                                    />
                                                </BookmarkButtonCol>
                                            </DesktopVersionContainer>
                                            {(stageFilter !== null ||
                                                categoryFilter !== null ||
                                                secondCategoryFilter !== null ||
                                                selectedBadgeFilter !== null ||
                                                searchParam !== "") && (
                                                <FilterButtonsComponent
                                                    onResetFilterButtonClick={() => resetSearch()}
                                                    resetFilterVisible={
                                                        stageFilter !== null ||
                                                        categoryFilter !== null ||
                                                        secondCategoryFilter !== null ||
                                                        selectedBadgeFilter !== null ||
                                                        searchParam !== ""
                                                            ? true
                                                            : false
                                                    }
                                                    strings={strings}
                                                />
                                            )}
                                        </FiltersRoot>
                                    </div>
                                )}
                            </Col>
                        </ScheduleGroupHeaderContainer>
                    </ScheduleGroupHeaderRoot>
                )}
                {
                    <ScheduleGroupsContainer
                        showAsList={showAsList}
                        roundScroller={props.roundScroller}
                        onScroll={(e: any) => {
                            if (props.onScroll) props.onScroll(e)
                        }}
                        addBannerHeight={props.addBannerHeight}
                        groups={groups}
                        revealerRef={revealerRef}
                        isFavourite={props.onlyFavorites}
                        dateColumns={props.dateColumns}
                        dateColumnDays={props.dateColumnsDays}
                        myScheduleResponsive={props.myScheduleResponsive}
                        day={props.day || index}
                        setUpdatedMeetingKey={setUpdatedMeetingKey}
                        guestBannerHeight={props.guestBannerHeight}
                        eventdates={eventDates}
                        hasMoreData={isMoreDataToLoad()}
                        setStartResultRow={setStartResultRow}
                        numResultRow={numResultRow}
                        isLoaded={isLoaded}
                        startResultRow={startResultRow}
                        pointsBadgeData={pointsBadgeData}
                        isInMyFairPage={props.isInMyFairPage}
                        searchParam={searchParam}
                        strings={strings}
                        dateToShow={days[index]}
                        jumpToLive={jumpToLive}
                        optionsOpen={props.filtersVisible ?? true}
                    />
                }
            </ScheduleRoot>
        )
    }
}
export default Schedule

const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
`

const Content = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`

const CheckboxDiv = styled.div`
    margin-top: 15px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding-left: 20px;

    & .form-check-label {
        margin-top: 3px;
    }
`

interface NowButtonResetModalProps {
    toggleResults: () => void
    onClose: () => void
}

const NowButtonResetModal: React.FunctionComponent<NowButtonResetModalProps> = (props: NowButtonResetModalProps) => {
    const strings = useLanguageState().getStrings()
    const [checkboxClicked, setCheckboxClicked] = useState<boolean>(false)
    const appState = useAppState()

    function handleClose() {
        props.onClose()
    }

    function handleContinueButton() {
        if (checkboxClicked) appState.setIsDontShowModalClicked(true)

        props.toggleResults()
        props.onClose()
    }

    return (
        <>
            <ModalRoot backdrop="static" show={true} animation={false}>
                <div onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h3 className="modal-title">{strings.programSchedule.nowButtonResetModalTitle}</h3>
                        <CloseButton onClick={() => handleClose()}>
                            {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                        </CloseButton>
                    </div>
                    <div className="modal-body">
                        <Content>
                            {strings.programSchedule.nowButtonResetModalText1}{" "}
                            <b>{strings.programSchedule.nowButtonResetModalText2}</b>{" "}
                            {strings.programSchedule.nowButtonResetModalText3}
                        </Content>
                        <Content>{strings.programSchedule.nowButtonResetModalSubtitleText}</Content>
                    </div>
                    <CheckboxDiv>
                        <Form.Check
                            type="checkbox"
                            label={strings.programSchedule.nowButtonResetModalCheckboxText}
                            checked={checkboxClicked}
                            onChange={() => setCheckboxClicked(!checkboxClicked)}
                        ></Form.Check>
                    </CheckboxDiv>
                    <div className="modal-footer" style={{ marginTop: "25px" }}>
                        <SubmitButtonContainer>
                            <DisagreeButton onClick={() => handleClose()} className="d-flex align-items-center">
                                {strings.programSchedule.nowButtonResetModalCancelButton}
                            </DisagreeButton>
                            <SubmitButtonNew
                                type="submit"
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => handleContinueButton()}
                            >
                                {strings.programSchedule.nowButtonResetModalAgreeButton}
                            </SubmitButtonNew>
                        </SubmitButtonContainer>
                    </div>
                </div>
            </ModalRoot>
        </>
    )
}

const SearchBarComponentRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
`

interface SearchBarComponentProps {
    currentSearch: string
    setCurrentSearch: (value: string) => void
    strings: any
    bookmarkFilter: boolean
    setBookmarkFilter: (value: boolean) => void
}

const SearchBarComponent: React.FunctionComponent<SearchBarComponentProps> = (props: SearchBarComponentProps) => {
    const { isMobile } = useWindowDimensions()
    const searchBarWidth = isMobile ? "72vw" : "244px"

    return (
        <SearchBarComponentRoot>
            <SearchBarContainer className={"ml-0"}>
                <SearchBar
                    setSearchParam={props.setCurrentSearch}
                    searchValue={props.currentSearch}
                    width={searchBarWidth}
                    placeholder={props.strings.sideIconBar.searchBarPlaceholder}
                    height={"38px"}
                    isSearchBar={true}
                    bottom={"12px"}
                    bottomEnd={"10px"}
                />
            </SearchBarContainer>
            <MobileVersionContainer>
                <OnlyBookmarksButton
                    bookmarkFilter={props.bookmarkFilter}
                    updateBookmarkFilter={() => props.setBookmarkFilter(!props.bookmarkFilter)}
                />
            </MobileVersionContainer>
        </SearchBarComponentRoot>
    )
}

type TitleRowProps = {
    groups: DayData[]
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    addBannerHeight?: number
    guestBannerHeight: number
    onScroll?: Function
    dateColumns?: boolean
    dateColumnDays?: moment.Moment[]
    onBookmarkClick?: Function
    myScheduleResponsive?: boolean
    showAsList: boolean
    roundScroller?: boolean
    day?: number
    setUpdatedMeetingKey?: (value: string) => void
    eventdates: EventDate[]
    hasMoreData: boolean
    setStartResultRow: (value: any) => void
    numResultRow: number
    isLoaded: boolean
    startResultRow: number
    pointsBadgeData?: Category
    isInMyFairPage?: boolean
    searchParam: string
    strings: any
    dateToShow: moment.Moment
    jumpToLive: number

    optionsOpen?: boolean
}

const ScheduleGroupsContainer: React.FunctionComponent<TitleRowProps> = (props) => {
    const isMySchedule = useRouteMatch(mySchedulePageRoute)?.isExact
    const isHome = useRouteMatch(homePageRoute)?.isExact
    const mySchedule = isMySchedule || isHome

    const { isMobile, useMobileDesign } = useWindowDimensions()

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setStartResultRow((e: number) => e + props.numResultRow)
        }
    }

    if (props.isLoaded && (!props.eventdates || props.eventdates.length === 0) && props.groups.length === 0) {
        return (
            <div style={{ marginTop: "12%" }}>
                <EmptyTile
                    header={
                        props.isFavourite
                            ? props.strings.programSchedule.noBookmarkedEventDatesText
                            : props.strings.programSchedule.noSearchResultsText
                    }
                    bgColor="transparent"
                    hideButton={true}
                />
            </div>
        )
    }

    if (mySchedule && props.groups.length > 0) {
        return (
            <DateColumnGroup
                key={1}
                dayData={props.groups[0]}
                isFavourite={props.isFavourite}
                revealerRef={props.revealerRef}
                addBannerHeight={props.addBannerHeight}
                onScroll={props.onScroll}
                dateColumns={props.dateColumns}
                dateColumnDays={props.dateColumnDays}
                myScheduleResponsive={props.myScheduleResponsive}
                roundScroller={props.roundScroller}
                groups={props.groups}
                day={props.day}
                setUpdatedMeetingKey={props.setUpdatedMeetingKey}
                pointsBadgeData={props.pointsBadgeData}
                isInMyFairPage={props.isInMyFairPage}
                dateToShow={props.dateToShow}
            />
        )
    } else {
        const adjustForHeader = isMobile
            ? props.optionsOpen
                ? 280
                : 120
            : (props.addBannerHeight ?? 0 + props.guestBannerHeight) + 320
        return (
            <div>
                {props.groups.map((day: DayData) => {
                    if (props.showAsList) {
                        return (
                            <ListViewRoot key={day.date}>
                                <ContentScrollContainer
                                    adjustForHeaderWith={`${adjustForHeader}px`}
                                    handleScroll={(e) => props.onScroll!(e)}
                                >
                                    <ListEventDateEntryContainer>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: useMobileDesign ? "100%" : "calc(100% - 10px)"
                                            }}
                                        >
                                            <ListEventDateEntryColumn
                                                eventDates={props.eventdates}
                                                helper={day.helper}
                                                pointBadgeData={props.pointsBadgeData}
                                            />
                                            {props.hasMoreData && (
                                                <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                                                    <NextPageLoader />
                                                </InView>
                                            )}
                                        </div>
                                    </ListEventDateEntryContainer>
                                </ContentScrollContainer>
                            </ListViewRoot>
                        )
                    } else {
                        return (
                            <ScheduleGroup
                                key={day.date}
                                dayData={day}
                                isFavourite={props.isFavourite}
                                revealerRef={props.revealerRef}
                                addBannerHeight={props.addBannerHeight}
                                guestBannerHeight={props.guestBannerHeight}
                                onScroll={props.onScroll}
                                dateColumns={props.dateColumns}
                                hasMoreData={props.hasMoreData}
                                setStartResultRow={props.setStartResultRow}
                                numResultRow={props.numResultRow}
                                isLoaded={props.isLoaded}
                                startResultRow={props.startResultRow}
                                pointsBadgeData={props.pointsBadgeData}
                                eventDate={props.eventdates}
                                jumpToLive={props.jumpToLive}
                            />
                        )
                    }
                })}
            </div>
        )
    }
}

// Only calculate the Height for the visible Day.
function getGroupsByDay(eventDates: EventDate[], selectedDate: Date): DayData[] {
    let groups: DayData[] = []
    let currentDayData: DayData | undefined
    if (selectedDate === undefined || eventDates.length === 0) return groups
    const dateString =
        selectedDate.getFullYear() +
        "-" +
        (selectedDate.getMonth() + 1 < 10 ? "0" + (selectedDate.getMonth() + 1) : selectedDate.getMonth() + 1) +
        "-" +
        (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate())
    eventDates
        .filter((eventDate) => eventDate.date === dateString)
        .forEach((eventDate) => {
            if (!currentDayData) {
                currentDayData = {
                    date: eventDate.date,
                    locations: new Map(),
                    times: [],
                    dayStartTime: new Date(),
                    helper: new ScheduleHelper()
                }
                groups.push(currentDayData)
            }
            if (eventDate.location === null || eventDate.location.length === 0) eventDate.location = ""
            let location = currentDayData.locations.get(eventDate.location)
            if (location == null) {
                location = { name: eventDate.location, events: [] }
                currentDayData.locations.set(eventDate.location, location)
            }
            location.events.push(eventDate)
        })
    if (!currentDayData?.helper) return groups

    let eventDatesCopy = [...eventDates]
    eventDatesCopy = eventDatesCopy.sort(compareByEndTimeEventDate)
    new ScheduleHelper().updateTimesEventDate(
        [...eventDatesCopy].sort(compareByStartTimeEventDate)[0],
        eventDatesCopy[eventDates.length - 1],
        currentDayData!
    )
    return groups
}

function getStagesByDay(eventDates: EventDate[], selectedDate: Date): { stage: string; filter: string; order?: number }[] {
    let stages: { stage: string; filter: string }[] = []

    if (selectedDate === undefined) return stages

    const dateString =
        selectedDate.getFullYear() +
        "-" +
        (selectedDate.getMonth() + 1 < 10 ? "0" + (selectedDate.getMonth() + 1) : selectedDate.getMonth() + 1) +
        "-" +
        (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate())

    eventDates
        .filter((eventDate) => eventDate.date === dateString)
        .forEach((eventDate) => {
            if (eventDate.location === null || eventDate.location.length === 0) eventDate.location = ""

            if (eventDate.location !== "" && stages.filter((s) => s.filter === eventDate.location).length === 0)
                stages.push({ stage: eventDate.location, filter: eventDate.location })
        })
    return stages
}

type EventDateEntryColumnCSS = {
    width: number
    backgroundColor: string
    justifyContent: string
    hasBefore?: boolean
    hasAfter?: boolean
}
const EventDateEntryColumnRoot = styled.div`
    min-width: calc(${(props: EventDateEntryColumnCSS) => props.width + "px"} - 0px);
    max-width: calc(${(props: EventDateEntryColumnCSS) => props.width + "px"} - 0px);

    background-color: ${(props: EventDateEntryColumnCSS) => props.backgroundColor};
    padding: 0 10px;
    position: relative;

    & .eventDate-entry-root {
        margin: 0 10px;
    }

    &::before,
    &::after {
        position: absolute;
        width: 1px;
        height: calc(100% + 100px);
        background-color: #c4c4c4;
        top: -100px;
        z-index: 100;
    }

    &::before {
        ${(props: EventDateEntryColumnCSS) => (props.hasBefore ? "content: '';" : "")};
    }

    &::after {
        ${(props: EventDateEntryColumnCSS) => (props.hasAfter ? "content: '';" : "")};
        right: -10px;
    }
`
const EventDateLocationColumn = styled(EventDateEntryColumnRoot)`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-flex;
    justify-content: ${(props: EventDateEntryColumnCSS) => props.justifyContent};
    height: 54px;
    font-weight: 700;
`
const EventDateTimeColumn = styled.div<{ isFavourite?: boolean; float?: string; mySchedule?: boolean }>`
    width: ${(props) => (props.mySchedule ? "80px" : "60px")};
    height: 100%;
    position: sticky; /* stay in view */
    left: 0; /* stay in view */
    float: ${(props) => props.float}; /* make room for the colums */
    margin-left: -15px; /* conter thet scroller */
    margin-top: ${(props) => (props.mySchedule ? "5px" : "43px")}; /* align with colums */
    z-index: ${(props) => (props.mySchedule ? "0" : "101")};
    background-color: #fff;
`
const EventDateTimeTableInnerWrapper = styled.div`
    position: relative;
    left: 50px;
    top: 5px;
    width: auto;
`
const EventDateTimeLabel = styled.div<{ showBorder?: boolean; hasBefore?: boolean; hasAfter?: boolean }>`
    padding: 10px 0px;
    font-size: 10px;
    font-family: ${branding.font1};
    font-weight: 300;
    background-color: transparent;
    color: ${branding.mainInfoColor};
    position: relative;

    &::before,
    &::after {
        position: absolute;
        width: calc(100% - 15px);
        height: 1px;
        background-color: #d9d9d9;
        left: 0;
        z-index: 0;
    }

    &::before {
        ${(props) => (props.showBorder && props.hasBefore ? "content: '';" : "")};
        top: 0;
    }

    &::after {
        ${(props) => (props.showBorder && props.hasAfter ? "content: '';" : "")};
        bottom: 0;
    }
`
const EventDateEntryContainer = styled.div<{ left?: string; responsive?: boolean; mySchedule?: boolean }>`
    display: flex;
    flex-grow: 1;
    position: absolute;
    transition: left 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    left: ${(props) => (props.left ? props.left : 0)};
    right: 0;
    padding-right: 50px; // make space when scroller is at end
    margin-left: 15px;
    width: ${(props) => (props.responsive ? "100%" : "50%")};
    overflow: ${(props) => (props.mySchedule ? "hidden" : "none")};
`

const ListViewRoot = styled.div``

const ListEventDateEntryContainer = styled.div`
    display: flex;
    flex-grow: 1;
    transition: left 0.5s ease-in-out;
    top: 0;
    bottom: 0;
    padding-bottom: 20px;
`
const GroupHeaderLabel = styled.div<{ datePickerOpen?: boolean }>`
    position: static;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    color: ${(props) => (props.datePickerOpen ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.mainInfoColor)};
    font-size: ${branding.programSchedule.dateLabelFontSize};
    font-family: ${branding.programSchedule.dateLabelFontFamily};
    text-transform: ${branding.programSchedule.dateLabelTextTransform};
    font-weight: ${branding.programSchedule.dateLabelFontWeight};
    letter-spacing: ${branding.programSchedule.dateLabelLetterSpacing};
    cursor: pointer;
    margin: -1px;

    & svg {
        margin-top: -7px;
        fill: ${(props) => (props.datePickerOpen ? branding.crsTabs.tabItemDefaultActiveStateColor : branding.mainInfoColor)};
    }

    & div:hover {
        border-bottom: 1px solid ${branding.crsTabs.tabItemDefaultActiveStateColor};
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};

        & svg {
            fill: ${branding.crsTabs.tabItemDefaultActiveStateColor};
        }
        display: inline-flex !important;
    }

    & span {
        padding-left: 15px;
        margin-left: -3px;

        @media ${device.laptop} {
            padding-left: 5px;
        }

        @media ${device.mobile} {
            margin-left: -14px;
        }
    }

    @media ${device.mobile} {
        font-size: 12px;
        line-height: 17px;
    }
`

const LocationColumnsWrapper = styled.div<{ isFavourite?: boolean }>`
    position: sticky;
    top: -1px;
    height: 55px;
    margin-left: -15px;
    margin-right: -50px;
    z-index: 100;
    background-color: #fff;
    color: ${branding.mainInfoColor};
`

const EventDateLocationColumns = styled.div`
    display: flex;
    position: absolute;
    transition: left 0.5s ease-in-out;
    text-align: start;
    left: 30px;
    margin-left: 40px;
`

const NextPageLoaderParent = styled.div`
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    background: ${branding.dangerButtonColor};
    z-index: 10;
`

type GroupProps = {
    dayData: DayData
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    addBannerHeight?: number
    guestBannerHeight?: number
    onScroll?: Function
    dateColumns?: boolean
    hasMoreData: boolean
    setStartResultRow: (value: any) => void
    numResultRow: number
    isLoaded: boolean
    startResultRow: number
    pointsBadgeData?: Category
    eventDate: EventDate[]
    jumpToLive: number
}
const columnWidth = 370
const columnColors = ["acc7dc", "c06c84", "bfb1d5", "403d50", "93642e", "fed88f", "546f5a", "907759", "5d3c59", "b87d9b"]

const ScheduleGroup: React.FunctionComponent<GroupProps> = (props) => {
    const language = useLanguageState().getLanguage()
    const appState = useAppState()
    const timezone = appState.timezone
    const scrollRef = useRef<HTMLDivElement>(null)
    const showCurrentTimeMarker = showCurrentTimeMarkerHelper(props.dayData.date, timezone)
    const checkCurrentDayEnd = checkLatestTime(props.eventDate)

    var date = moment()
    var currentDateTime = moment(new Date()).format("HH:mm")
    useEffect(() => {
        if (props.eventDate && props.eventDate.length > 0 && checkCurrentDayEnd) {
            if (props.dayData.date === date.format("YYYY-MM-DD").toString() && currentDateTime < checkCurrentDayEnd) {
                scrollRef.current?.scrollIntoView(false)
                scrollRef.current?.scrollIntoView({ block: "center", inline: "nearest" })
            }
        }
        // eslint-disable-next-line
    }, [props.jumpToLive])

    let locations = Array.from(props.dayData.locations.values())
    let masterClasses = locations.filter((m) => m.name === "Masterclasses")
    const masterClassOverlapCount =
        masterClasses.length === 1 ? props.dayData.helper.getMasterClassColumnWidth(masterClasses[0].events) : 1
    let columnsWidth = (props.dayData.locations.size + masterClassOverlapCount - 1) * columnWidth + 40 + "px"
    const adjustScrollWidth = appState.isNetworkingOpen() ? "calc(100vw - 440px)" : "calc(100vw - 180px)"

    const currentTime = props.dayData.helper.getTopForCurrentTime()

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setStartResultRow((e: number) => e + props.numResultRow)
        }
    }

    function formatTimeInEnglish(time: any) {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time]

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1) // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM" // Set AM/PM
            time[0] = +time[0] % 12 || 12 // Adjust hours
        }
        return time.join("") // return adjusted time or original string
    }

    const adjustForHeader = `${(props.addBannerHeight ?? 0) + (props.guestBannerHeight ?? 0) + 340}px`

    return (
        <div>
            <ContentScrollContainer
                adjustForHeaderWith={adjustForHeader}
                scrollType={props.isFavourite ? "round" : ""}
                noScrollX={true}
                handleScroll={(e) => {
                    props.onScroll!(e)
                }}
                width={adjustScrollWidth}
                roundHorizontalScrollbar={true}
            >
                {!props.isLoaded && props.startResultRow !== 0 && (
                    <NextPageLoaderParent>
                        {" "}
                        <NextPageLoader />
                    </NextPageLoaderParent>
                )}

                <div style={{ marginBottom: "20px", width: columnsWidth, scrollMarginTop: "50px" }}>
                    <EventDateTimeColumn isFavourite={props.isFavourite} float={"left"}>
                        {props.dayData.times.map((time, index) => {
                            const style = {
                                height: props.dayData.helper.getTimeLabelHeight() + "px"
                            }

                            return (
                                <EventDateTimeLabel key={time} style={style} hasBefore={true}>
                                    {language === "en" ? formatTimeInEnglish(time) : time}
                                </EventDateTimeLabel>
                            )
                        })}
                    </EventDateTimeColumn>
                    <LocationColumnsWrapper style={{ width: "auto" }} isFavourite={props.isFavourite}>
                        <EventDateLocationColumns>
                            {locations.map((location, index) => {
                                let isMasterclass = location.name === "Masterclasses"
                                const overlapCount = isMasterclass ? masterClassOverlapCount : 1
                                return (
                                    <EventDateLocationColumn
                                        key={index}
                                        width={overlapCount * columnWidth}
                                        backgroundColor={isMasterclass ? "white" : "transparent"}
                                        justifyContent={"center"}
                                    >
                                        {isMasterclass ? (
                                            <EventDateLocationNameMasterclass style={{ position: "relative", zIndex: 1 }}>
                                                {" "}
                                                <span></span>
                                                <div style={{}}>{location.name}</div>{" "}
                                            </EventDateLocationNameMasterclass>
                                        ) : (
                                            <EventDateLocationName> {location.name} </EventDateLocationName>
                                        )}
                                    </EventDateLocationColumn>
                                )
                            })}
                        </EventDateLocationColumns>
                    </LocationColumnsWrapper>
                    <EventDateTimeTableInnerWrapper style={{ width: columnsWidth }}>
                        {branding.programSchedule.showCurrentTimeLabelAndLine && showCurrentTimeMarker && (
                            <CurrentTimeWrapper
                                style={{ display: checkCurrentDayEnd && currentDateTime < checkCurrentDayEnd ? "flex" : "none" }}
                                ref={scrollRef}
                                top={currentTime.top}
                                width="100%"
                            >
                                <CurrentTimeTitle style={{ marginLeft: "-50px" }}>
                                    {language === "en" ? currentTime.timeEN : currentTime.timeDE}
                                </CurrentTimeTitle>
                                <CurrentTimePointer>
                                    <CurrentTimeLine />
                                </CurrentTimePointer>
                            </CurrentTimeWrapper>
                        )}
                        <EventDateEntryContainer>
                            {locations.map((location, index) => {
                                let isMasterclass = location.name === "Masterclasses" ? true : false
                                const eventDateEntryBackgroundColor =
                                    branding.programSchedule.stagesOrder.find((stage: any) => location.name === stage.stage)
                                        ?.backgroundColor! ?? "#fff"
                                const eventDateEntryBorderColor =
                                    branding.programSchedule.stagesOrder.find((stage: any) => location.name === stage.stage)
                                        ?.borderColor! ?? "#000"
                                const color = isMasterclass ? "#F2F2F2" : "#fff"
                                const overlapCount = isMasterclass
                                    ? props.dayData.helper.getMasterClassColumnWidth(location.events)
                                    : 1

                                return (
                                    <EventDateEntryColumn
                                        key={index}
                                        hasBefore={index < locations.length}
                                        hasAfter={index === locations.length - 1}
                                        eventDates={location.events}
                                        firstColumn={index === 0}
                                        helper={props.dayData.helper}
                                        dayStartTime={props.dayData.dayStartTime}
                                        width={overlapCount * columnWidth}
                                        backgroundColor={color}
                                        eventDateEntryBorderColor={eventDateEntryBorderColor}
                                        eventDateEntryBackgroundColor={eventDateEntryBackgroundColor}
                                        pointsBadgeData={props.pointsBadgeData}
                                    />
                                )
                            })}
                        </EventDateEntryContainer>
                        <div>
                            {props.dayData.times.map((time, index) => {
                                const style = {
                                    height: props.dayData.helper.getTimeLabelHeight() + "px"
                                }
                                return (
                                    <EventDateTimeLabel
                                        showBorder={true}
                                        key={time}
                                        style={style}
                                        hasBefore={index < props.dayData.times.length}
                                        hasAfter={index === props.dayData.times.length - 1}
                                    >
                                        &nbsp;
                                    </EventDateTimeLabel>
                                )
                            })}
                        </div>
                    </EventDateTimeTableInnerWrapper>
                </div>
                <div style={{ width: columnsWidth }}>
                    {props.hasMoreData && (
                        <InView threshold={0.1} onChange={onNextPageLoaderVisibilityChange}>
                            <div style={{ height: "1px" }} />
                        </InView>
                    )}
                </div>
            </ContentScrollContainer>
        </div>
    )
}

/* #region   My schedule part*/
const MyScheduleRoot = styled.div`
    margin-left: 30px;
    font-family: ${branding.font1};

    .rc-slider {
        top: -50px;
        z-index: 301;
    }

    .ScrollbarsCustom-Content {
        overflow: hidden;
    }

    .entry-column-root {
        padding: 0;
    }

    & #round-scrollable-div {
        overflow-x: hidden;
    }
`

const TempWrapperColumn = styled.div<{ column?: string; responsive?: boolean }>`
    display: flex;
    justify-content: center;
    /* ${(props) => props.column === "left" && `background-image: linear-gradient(180deg, #FFFFFF 0%, #F2F2F2 0.8%);`}; */
    /* width: ${(props) => (props.responsive ? "100%" : "50%")}; */
    width: 100%;
    z-index: 0;
`

const EntryColumnParent = styled.div<{ backgroundShadow?: boolean }>`
    background: none;
    /* linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(242,242,242,1) 56%); */
    width: 90%;
    display: flex;
    justify-content: center;
`

interface CurrentTimeWrapperProps {
    top: number
    width: string
}
const CurrentTimeWrapper = styled.div<CurrentTimeWrapperProps>`
    width: ${(props) => props.width};
    position: absolute;
    z-index: 102;
    top: ${(props) => props.top + "px"};
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`
const CurrentTimeTitle = styled.div`
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    border: 1px solid ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    border-radius: 5px;
    color: ${branding.programSchedule.currentTimeLabelAndLineColor};
    margin-top: -15px;
    margin-left: -50px;
    /* margin-left: 10px; */
    font-size: 14px;
`
const CurrentTimePointer = styled.div`
    /* width: 89%; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-right: 4%; */
`
// const CurrentTimeDot = styled.div`
//     width: 10px;
//     height: 10px;
//     border-radius: 50%;
//     background: ${branding.programSchedule.currentTimeLabelAndLineColor};
//     margin-top: -5px;
// `
const CurrentTimeLine = styled.hr`
    border-top: 1px dotted ${branding.programSchedule.currentTimeLabelAndLineBackgroundColor};
    /* width: 93%; */
    width: 100%;
    margin: 0;
`

const RightTimeColumn = styled.div`
    width: 80px;
`

const MyScheduleDateTitle = styled.div`
    font-family: ${branding.font1};
    font-size: 1.2rem;
    margin-top: 20px;
    color: ${branding.receptionPage.myScheduleTitleColor ?? "#000"};
    padding-bottom: 10px;
`

const ScrollerShadowTop = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    top: 74px;
    z-index: 300;
`
const ScrollerShadowBottom = styled.div`
    position: absolute;
    width: 100%;
    height: 10%;
    background: transparent;
    background: ${branding.primaryScrollWhiteShadowTTB};
    left: 0;
    bottom: 0;
    z-index: 300;
    transform: rotate(180deg);
`

type DateColumnProps = {
    dateColumnsDays?: moment.Moment[]
    isFavourite?: boolean
    revealerRef: (node?: Element | null | undefined) => void
    addBannerHeight?: number
    onScroll?: Function
    dateColumns?: boolean
    dayData: DayData
    dateColumnDays?: moment.Moment[]
    myScheduleResponsive?: boolean
    roundScroller?: boolean
    groups: DayData[]
    day?: number
    setUpdatedMeetingKey?: (value: string) => void
    pointsBadgeData?: Category
    isInMyFairPage?: boolean
    dateToShow: moment.Moment
    resetFilterVisible?: boolean
}

const DateColumnGroup: React.FunctionComponent<DateColumnProps> = (props) => {
    const scrollRef = useRef<HTMLDivElement>(null)

    const currentTime = props.dayData.helper.getTopForCurrentTime()
    const windowSize = useWindowDimensions()
    const languageState = useLanguageState()

    const strings = languageState.getStrings()
    const language = languageState.getLanguage()
    const eventDays = branding.eventTiming.eventDays

    //const showCurrentTimeMarker = showCurrentTimeMarkerHelper(props.dayData.date, props.dayData.date)

    function createDateTitle(day: any) {
        const dayFormat = strings.eventTiming.eventDaysFormatPattern

        return format(moment(day).toDate(), dayFormat, { locale: language === "de" ? de : en })
    }

    var date = moment()
    useEffect(() => {
        const selectedDate = props.groups[props.day ?? props.groups.length - 1].date
        if (isToday(moment(selectedDate)) && selectedDate === date.format("YYYY-MM-DD").toString()) {
            document.getElementById("round-scrollable-div")?.scrollTo(0, currentTime.top - 200)
        } else {
            document.getElementById("round-scrollable-div")?.scrollTo(0, 0)
        }
        // eslint-disable-next-line
    }, [scrollRef.current, props.day])

    let allMeetings: CalendarEntry[] = []

    props.groups.forEach((item) => {
        const formattedDateKey = moment(new Date(item.date)).format("YYYY-MM-DD")
        allMeetings.push.apply(allMeetings, item.locations.get(formattedDateKey)?.meetings!)
    })

    const eventDayFormat = strings.eventTiming.eventDaysFormatPattern
    const isHome = useRouteMatch(homePageRoute)?.isExact

    function checkIfCurrentDate() {
        return props.day && isToday(moment(props.groups[props.day].date))
    }

    const [isShadowTop, setIsShadowTop] = useState<boolean>(false)
    const [isShadowBottom, setIsShadowBottom] = useState<boolean>(false)

    return (
        <MyScheduleRoot className={"my-schedule-root"} style={{ marginLeft: "30px" }} id={props.dayData.date}>
            {isHome && (
                <>
                    <MyScheduleDateTitle>
                        {format(props.dateToShow.toDate(), eventDayFormat, { locale: language === "de" ? de : undefined })}
                    </MyScheduleDateTitle>
                    {isShadowTop && <ScrollerShadowTop />}
                </>
            )}
            <div style={{ height: "15px" }}></div>
            {!props.myScheduleResponsive && (
                <LocationColumnsWrapper style={{ width: "auto" }} isFavourite={props.isFavourite}>
                    {/** Sadly the ColumnButtonIcon has to be wrapped because it cannot receive a ref (https://github.com/FortAwesome/react-fontawesome/pull/200) */}
                    <div className="d-flex justify-content-around">
                        {eventDays &&
                            eventDays.map((day) => {
                                return (
                                    <div key={day}>
                                        <EventDateLocationName>{createDateTitle(day)}</EventDateLocationName>
                                    </div>
                                )
                            })}
                    </div>
                </LocationColumnsWrapper>
            )}
            <ContentScrollContainer
                adjustForHeaderWith={
                    props.dateColumns
                        ? `${props.addBannerHeight || 0}px`
                        : props.isFavourite
                        ? `${(props.addBannerHeight || 0) + 448}px`
                        : `${(props.addBannerHeight || 0) + 228}px`
                }
                noScrollX={false}
                handleScroll={(e: any) => {
                    props.onScroll!(e)
                    if (e.target.scrollTop > 0) {
                        setIsShadowTop(true)
                    } else {
                        setIsShadowTop(false)
                    }

                    if (e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight) {
                        setIsShadowBottom(false)
                    } else {
                        setIsShadowBottom(true)
                    }
                }}
                width={!props.isFavourite ? props.dayData.locations.size * 370 + "px" : "99%"}
                scrollType={props.roundScroller ? "round" : ""}
            >
                <EventDateTimeColumn isFavourite={props.isFavourite} float={"left"} mySchedule={true}>
                    {props.dayData.times.map((time) => {
                        const style = {
                            height: props.dayData.helper.getTimeLabelHeight() + "px"
                        }
                        var date = new Date()
                        date.setHours(parseInt(time.split(":")[0]))
                        date.setMinutes(parseInt(time.split(":")[1]))

                        return (
                            <EventDateTimeLabel key={time} style={style} showBorder={true} hasBefore={true}>
                                {language === "en" ? moment(date).format("h:mm a").toUpperCase() : moment(date).format("HH:mm")}
                            </EventDateTimeLabel>
                        )
                    })}
                </EventDateTimeColumn>
                <EventDateTimeTableInnerWrapper>
                    {checkIfCurrentDate() ? (
                        <CurrentTimeWrapper
                            ref={scrollRef}
                            top={currentTime.top}
                            width={props.myScheduleResponsive ? "100%" : "50%"}
                        >
                            <CurrentTimeTitle>{language === "en" ? currentTime.timeEN : currentTime.timeDE}</CurrentTimeTitle>
                            <CurrentTimePointer>
                                {/* <CurrentTimeDot /> */}
                                <CurrentTimeLine />
                            </CurrentTimePointer>
                        </CurrentTimeWrapper>
                    ) : (
                        <div />
                    )}
                    <div>
                        {props.dayData.times.map((time) => {
                            const style = {
                                height: props.dayData.helper.getTimeLabelHeight() + "px"
                            }
                            return (
                                <EventDateTimeLabel key={time} style={style} showBorder={true} hasBefore={true}>
                                    &nbsp;
                                </EventDateTimeLabel>
                            )
                        })}
                    </div>
                    {Array.from(props.groups).map((group, index: number) => {
                        if ((props.myScheduleResponsive && index === props.day!) || !props.myScheduleResponsive) {
                            return (
                                <EventDateEntryContainer
                                    key={index}
                                    id={group.date}
                                    responsive={props.myScheduleResponsive}
                                    left={index === 0 || props.myScheduleResponsive ? "0" : "50%"}
                                    mySchedule={true}
                                >
                                    {Array.from(group.locations.values()).map((location, index) => {
                                        const color = columnColors[(index - 1 + columnColors.length) % columnColors.length]
                                        return (
                                            <TempWrapperColumn
                                                key={index}
                                                column={index % 2 === 0 ? "left" : ""}
                                                responsive={props.myScheduleResponsive}
                                            >
                                                <EntryColumnParent
                                                    backgroundShadow={
                                                        moment(new Date()).format("YYYY-MM-DD") ===
                                                        moment(group.date).format("YYYY-MM-DD")
                                                    }
                                                >
                                                    <EventDateEntryColumn
                                                        key={location.name}
                                                        eventDates={location.events}
                                                        firstColumn={index === 0}
                                                        helper={props.dayData.helper}
                                                        dayStartTime={props.dayData.dayStartTime}
                                                        width={
                                                            windowSize.width < 1200
                                                                ? 400
                                                                : windowSize.width < 1400
                                                                ? 600
                                                                : windowSize.width < 1800
                                                                ? 800
                                                                : windowSize.width < 2000
                                                                ? 400
                                                                : windowSize.width < 2400
                                                                ? 600
                                                                : 800
                                                        }
                                                        backgroundColor={color}
                                                        meetings={location.meetings}
                                                        pointsBadgeData={props.pointsBadgeData}
                                                    />
                                                </EntryColumnParent>
                                            </TempWrapperColumn>
                                        )
                                    })}
                                    {index === props.groups.length - 1 && (
                                        <RightTimeColumn className={props.myScheduleResponsive ? "d-none" : "d-block"}>
                                            {props.dayData.times.map((time) => {
                                                const style = {
                                                    height: props.dayData.helper.getTimeLabelHeight() + "px"
                                                }
                                                var date = new Date()
                                                date.setHours(parseInt(time.split(":")[0]))
                                                date.setMinutes(parseInt(time.split(":")[1]))

                                                return (
                                                    <EventDateTimeLabel key={time} style={style} hasBefore={true}>
                                                        {language === "en"
                                                            ? moment(date).format("hh:mm a").toUpperCase()
                                                            : moment(date).format("HH:mm")}
                                                    </EventDateTimeLabel>
                                                )
                                            })}
                                        </RightTimeColumn>
                                    )}
                                </EventDateEntryContainer>
                            )
                        }
                        return null
                    })}
                </EventDateTimeTableInnerWrapper>
                {isShadowBottom && <ScrollerShadowBottom />}
                <Revealer ref={props.revealerRef} style={{ width: props.dayData.locations.size * 370 + "px" }} />
            </ContentScrollContainer>
        </MyScheduleRoot>
    )
}

/* #endregion */
export function compare(a: any, b: any) {
    var timeA = a.object.hasOwnProperty("startHour") ? a.object.start : moment(new Date(a.object.start)).format("HH:mm")
    var timeB = b.object.hasOwnProperty("startHour") ? b.object.start : moment(new Date(b.object.start)).format("HH:mm")

    const [aHours, aMinutes] = timeA.split(":")
    const aTimeStamp = parseInt(aHours) * 60 + parseInt(aMinutes)

    const [bHours, bMinutes] = timeB.split(":")
    const bTimeStamp = parseInt(bHours) * 60 + parseInt(bMinutes)

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    return 0
}

function compareByEndTime(a: any, b: any) {
    var timeA = a.object.hasOwnProperty("endHour") ? a.object.end : moment(new Date(a.object.end)).format("HH:mm")
    var timeB = b.object.hasOwnProperty("endHour") ? b.object.end : moment(new Date(b.object.end)).format("HH:mm")

    const [aHours, aMinutes] = timeA.split(":")
    const aTimeStamp = parseInt(aHours) * 60 + parseInt(aMinutes)

    const [bHours, bMinutes] = timeB.split(":")
    const bTimeStamp = parseInt(bHours) * 60 + parseInt(bMinutes)

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    return 0
}

function compareByEndTimeEventDate(a: EventDate, b: EventDate) {
    var x = ("0" + a.endHour).slice(-2) + ":" + ("0" + a.endMinutes).slice(-2)
    var y = ("0" + b.endHour).slice(-2) + ":" + ("0" + b.endMinutes).slice(-2)

    if (x > y) return 1
    if (y > x) return -1

    return 0
}
export function compareByStartTimeEventDate(a: EventDate, b: EventDate) {
    var x = ("0" + a.startHour).slice(-2) + ":" + ("0" + a.startMinutes).slice(-2)
    var y = ("0" + b.startHour).slice(-2) + ":" + ("0" + b.startMinutes).slice(-2)

    if (x > y) return 1
    if (y > x) return -1

    return 0
}

function getOverlappingObjects(all: any[], object: any): any {
    var objectDate = object.hasOwnProperty("startHour") ? object.date : moment(new Date(object.start)).format("YYYY-MM-DD")
    var objectTime = object.hasOwnProperty("startHour") ? object.start : moment(new Date(object.start)).format("HH:mm")
    const overlapping = all.filter((x) =>
        x.object.hasOwnProperty("startHour")
            ? x.object.date === objectDate && x.object.start === objectTime
            : moment(new Date(x.object.start)).format("YYYY-MM-DD") === objectDate &&
              moment(new Date(x.object.start)).format("HH:mm") === objectTime
    )
    return overlapping
}

function isAlreadyAdded(addedItems: string[], myScheduleObject: any) {
    const includes = addedItems.filter(
        (x) =>
            x ===
            (myScheduleObject!.hasOwnProperty("startHour")
                ? myScheduleObject!.start
                : moment(myScheduleObject!.start).format("HH:mm"))
    ).length

    return includes >= 2
}

type EntryColumnProps = {
    eventDates: EventDate[]
    meetings?: CalendarEntry[]
    firstColumn: boolean
    helper: ScheduleHelper
    dayStartTime: Date
    width: number
    backgroundColor: string
    pointsBadgeData?: Category
    eventDateEntryBackgroundColor?: string
    eventDateEntryBorderColor?: string
    hasBefore?: boolean
    hasAfter?: boolean
}
const EventDateEntryColumn: React.FunctionComponent<EntryColumnProps> = (props) => {
    let all: Array<any> = []
    let lastEventDate: any = null

    props.eventDates.forEach((x) => {
        all.push({
            type: "eventdate",
            object: x
        })
    })

    if (props.meetings) {
        props.meetings.forEach((x) => {
            all.push({
                type: "meeting",
                object: x
            })
        })
    }
    // setTimeout(() => {
    if (props.meetings) {
        all = all.sort(compare)
    }
    // }, 1000);

    let overlappingObjects: any[] = []
    let addedItems: string[] = []
    const isMySchedule = useRouteMatch(mySchedulePageRoute)?.isExact
    const isHome = useRouteMatch(homePageRoute)?.isExact
    const pathname = isMySchedule || isHome
    let zIndex = 0

    return (
        <EventDateEntryColumnRoot
            className="entry-column-root"
            width={props.width}
            backgroundColor={props.backgroundColor}
            justifyContent={"start"}
            hasBefore={props.hasBefore}
            hasAfter={props.hasAfter}
        >
            {all.map((item, index) => {
                let marginTop = 0
                let eventDate = props.eventDates.find((d) => d.id === item.object.id)!
                let myScheduleObject = eventDate
                    ? eventDate
                    : props.meetings
                    ? props.meetings!.find((d) => d.id === item.object.id)
                    : null

                //my schedule -> overlapping
                let overlappingTemp: any
                overlappingObjects = []
                if (myScheduleObject) {
                    overlappingTemp = getOverlappingObjects(all, myScheduleObject)
                    if (overlappingTemp.length >= 2) {
                        overlappingTemp.forEach((item: any) => {
                            overlappingObjects.push({
                                object: item.object,
                                height: props.helper.getHeightForEventDate(item.object)
                            })
                        })
                        addedItems.push(
                            myScheduleObject.hasOwnProperty("startHour")
                                ? myScheduleObject.start
                                : moment(myScheduleObject.start).format("HH:mm")
                        )
                    }
                }

                if (lastEventDate === null) marginTop = props.helper.getMarginTop(eventDate ?? myScheduleObject)
                else {
                    marginTop = props.helper.getMarginTopBetween(lastEventDate, eventDate ?? myScheduleObject)
                }

                if (overlappingObjects.length < 2) lastEventDate = eventDate ?? myScheduleObject
                else {
                    const sortedOverlappingObjects = overlappingObjects.sort(function (a, b) {
                        return b.height - a.height
                    })
                    const heightestOverlappingObject =
                        sortedOverlappingObjects && sortedOverlappingObjects.length > 1
                            ? props.eventDates.find((x) => x.id === sortedOverlappingObjects[0].object.id) ||
                              props?.meetings?.find((x) => x.id === sortedOverlappingObjects[0].object.id)
                            : eventDate
                    lastEventDate = heightestOverlappingObject
                }

                let overlapCount = 0
                if (item.object.location === "Masterclasses") {
                    overlapCount = props.helper.getMasterClassColumnPosition(props.eventDates, item.object.id)
                }
                let marginLeft = overlapCount > 0 ? overlapCount * 370 : 0

                zIndex++
                return (
                    <EventDateEntry
                        key={eventDate ? eventDate.id : myScheduleObject!.id}
                        mySchedule={pathname}
                        eventDate={eventDate}
                        marginTop={marginTop}
                        marginLeft={marginLeft}
                        height={props.helper.getHeightForEventDate(eventDate ? eventDate : myScheduleObject)}
                        myScheduleObject={myScheduleObject}
                        overlappingObjects={overlappingObjects && overlappingObjects.length >= 2 ? overlappingObjects : null}
                        isAdded={isAlreadyAdded(addedItems, myScheduleObject)}
                        zIndex={zIndex}
                        pointBadgeData={props.pointsBadgeData}
                        background={props.eventDateEntryBackgroundColor ?? "#fff"}
                        border={props.eventDateEntryBorderColor !== "" ? props.eventDateEntryBorderColor : "#000"}
                    />
                )
            })}
        </EventDateEntryColumnRoot>
    )
}

const EventDateLocationName = styled.div`
    align-self: center;
    line-height: 20px;
    font-size: 16px;
    padding-left: 20px;
    font-family: ${branding.font1};

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`
const EventDateLocationNameMasterclass = styled(EventDateLocationName)`
    width: 100%;
    position: relative;
    text-align: center;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${branding.mainInfoColor};

    span {
        content: " ";
        border-top: 1px solid black;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        z-index: -1;
    }

    div {
        background-color: white;
        display: inline-block;
        width: 200px;
    }
`

// TODO There should be a better way to do this
function getGroupsByDayMySchedule(
    eventDates: EventDate[],
    meetings: CalendarEntry[],
    timezone: string,
    eventDays: moment.Moment[]
): DayData[] {
    let groups: DayData[] = []
    const dayFormat = "YYYY-MM-DD"

    eventDays.forEach((date, index) => {
        const formattedDate = date.format(dayFormat)
        const currentDayData = {
            date: formattedDate,
            locations: new Map(),
            times: [],
            dayStartTime: new Date(),
            helper: new ScheduleHelper()
        }

        currentDayData.locations.set(formattedDate, { name: formattedDate, events: [], meetings: [] })
        groups.push(currentDayData)
    })
    groups.forEach((groupData) => {
        eventDates.forEach((eventDate, index) => {
            var formattedDate = moment(eventDate.date).format(dayFormat)
            let location = groupData.locations.get(formattedDate)
            if (location) {
                location.events.push(eventDate)
            }
        })

        meetings.forEach((meeting: any, index: number) => {
            var date = new Date(meeting.start)
            var formattedDate = moment(date).format(dayFormat)

            if (groupData.date === formattedDate) {
                let location = groupData.locations.get(formattedDate)
                if (location && location!.meetings) {
                    location.meetings.push(meeting)
                }
            }
        })

        if (groupData.locations) {
            let firstLastObject = getFirstLastTime(eventDates, meetings)

            if (firstLastObject)
                groupData!.helper.updateTimesCustom(
                    branding.eventTiming.eventDateDefaultStartTime,
                    branding.eventTiming.eventDateDefaultEndTime,
                    groupData
                )
        }
    })
    return groups
}

function getFirstLastTime(eventDates: EventDate[], meetings: CalendarEntry[]) {
    let all: any = []

    eventDates.forEach((x) => {
        all.push({
            type: "eventdate",
            object: x
        })
    })

    meetings.forEach((x) => {
        all.push({
            type: "meeting",
            object: x
        })
    })

    all = all.sort(compare)
    if (all.length !== 0) {
        var dateA = new Date(all[0].object.dateTimeStart)

        var x = all[0].object.hasOwnProperty("startHour")
            ? ("0" + all[0].object.startHour).slice(-2) + ":" + ("0" + all[0].object.startMinutes).slice(-2)
            : ("0" + dateA.getHours()).slice(-2) + ":" + ("0" + dateA.getMinutes()).slice(-2)

        all = all.sort(compareByEndTime)
        var dateB = new Date(all[all.length - 1].object.dateTimeStart)
        var y = all[all.length - 1].object.hasOwnProperty("endHour")
            ? ("0" + all[all.length - 1].object.endHour).slice(-2) + ":" + ("0" + all[all.length - 1].object.endMinutes).slice(-2)
            : ("0" + dateB.getHours()).slice(-2) + ":" + ("0" + dateB.getMinutes()).slice(-2)
        return {
            start: x,
            end: y
        }
    }
    return null
}
