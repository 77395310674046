import { useCallback, useEffect, useRef, useState, useReducer } from "react"
import * as React from "react"
import styled from "styled-components"
import { useLanguageState } from "../globalStates/LanguageState"
import { defaultLogger as logger } from "../globalStates/AppState"
import moment from "moment"
import {
    loadChatMessages,
    ChatMessage,
    getUserById,
    UserResponse,
    setLastReadConversation,
    getLastReadUserConversation
} from "../backendServices/GraphQLServices" // eslint-disable-line react-hooks/exhaustive-deps
import CenteredLoader from "../ui/CenteredLoader"
import { API, graphqlOperation } from "aws-amplify"
import Observable from "zen-observable-ts"
import { onMessageCreatedLight } from "../graphql/ownSubscriptions"
import { useNotificationContext } from "../conference/context/NotificationContext"
import { ConversationType, OnMessageCreatedLightSubscription } from "../API"
import { isToday, isYesterday } from "../utils/DateUtils"
import { ConversationParticipant, DisplayStyle } from "./ChatPage"
import { AvatarWithDefault } from "../ui/AvatarWithDefault"
import { DetailNavLink } from "../contentArea/detailPages/DetailNavLink"
import Linkify from "react-linkify"
import ContactModal from "../ui/modals/ContactModal"
import { backOff } from "exponential-backoff"
import branding from "../branding/branding"
import { meetingPageRoute } from "../navigationArea/RoutePaths"
import { useRouteMatch } from "react-router-dom"
import { isPostEventPhase } from "../utils/EventPhaseChecker"
import { SideBarViewMode } from "../conference/components/Roster"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import InView from "react-intersection-observer"

const ScrollingContainer = styled.div`
    flex: 1;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    color: ${branding.chatListColor ?? "black"};
`
const ConversationContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-top: auto !important;
    padding: 8px 0 8px 0;
    width: 100%;
    max-width: 100%;
    margin-bottom: 5px;
`
const NextPageLoader = styled(CenteredLoader)`
    height: 80px;
`

const ChatHeaderItem = styled.p`
    background: ${branding.chatBranding.headerInfoBubbleBackgroundColor ?? "#d9d9d9"} !important;
    color: ${branding.chatBranding.headerInfoBubbleColor ?? branding.mainInfoColor} !important;
`
interface NewMessageSubscription {
    value: {
        data: OnMessageCreatedLightSubscription
    }
}

class ChatItem {
    timestamp: Date
    constructor(timestamp: Date) {
        this.timestamp = timestamp
    }
}
class ChatMessageItem extends ChatItem {
    id: string
    content: string
    authorId: string
    authorName?: string | null
    authorPictureUrl?: string | null
    isMine: boolean
    constructor(
        timestamp: Date,
        id: string,
        content: string,
        authorId: string,
        authorName: string | null | undefined,
        authorPictureUrl: string | null | undefined,
        isMine: boolean
    ) {
        super(timestamp)
        this.id = id
        this.content = content
        this.authorId = authorId
        this.authorName = authorName
        this.authorPictureUrl = authorPictureUrl
        this.isMine = isMine
    }
}
class HeaderItem extends ChatItem {
    // constructor(timestamp: Date) {
    //     super(timestamp)
    // }
}

function getTypeFromUrl(urlString: string): "organization" | "person" | "user" | "eventdate" {
    let url: URL = new URL(urlString)

    let type: string = url.pathname.substring(1).split("/")[0]

    if (type === "person") {
        let userOrPerson: string = url.pathname.substring(1).split("--")[1].split("-")[0]

        if (userOrPerson === "u") {
            return "user"
        } else {
            return "person"
        }
    } else if (type === "company") {
        return "organization"
    }

    return "eventdate"
}

function getIdFromUrl(urlString: string): string {
    let url: URL = new URL(urlString)

    let splitUrl: string[] =
        getTypeFromUrl(urlString) === "person"
            ? url.pathname.substring(1).split("--p-")
            : getTypeFromUrl(urlString) === "user"
            ? url.pathname.substring(1).split("--u-")
            : url.pathname.substring(1).split("--")

    return splitUrl[splitUrl.length - 1]
}

function getNameFromUrl(urlString: string): string {
    let url: URL = new URL(urlString)
    let type: string = getTypeFromUrl(urlString)

    if (type === "person") {
        return url.pathname.split("person/person-")[1].split("--p" + getIdFromUrl(urlString))[0]
    } else if (type === "user") {
        return url.pathname.split("person/person-")[1].split("--u" + getIdFromUrl(urlString))[0]
    } else if (type === "organization") {
        return url.pathname.split("company/")[1].split("--" + getIdFromUrl(urlString))[0]
    }

    return url.pathname.split("eventdate/")[1].split("--" + getIdFromUrl(urlString))[0]
}

function checkIfInAppSharedUrl(urlString: string): boolean {
    const currentMainUrl = window.location.origin

    if (
        urlString.includes(currentMainUrl + "/person/person-") ||
        urlString.includes(currentMainUrl + "/company/") ||
        urlString.includes(currentMainUrl + "/eventdate/")
    ) {
        return true
    }

    return false
}

function checkIfVirtualCafeUrlInPostEventPhase(urlString: string): boolean {
    if (!isPostEventPhase) {
        return false
    }

    const currentMainUrl = window.location.origin

    if (urlString.includes(currentMainUrl + "/meeting/") || urlString.includes(currentMainUrl + "/meetings/")) {
        return true
    }

    return false
}

export const componentDecorator = (href: string, text: string, key: number) => (
    <>
        {checkIfInAppSharedUrl(text) ? (
            <DetailNavLink id={getIdFromUrl(text)} type={getTypeFromUrl(text)} name={getNameFromUrl(text)}>
                <div style={{ color: "#1675e0", textDecoration: "underline" }}>{text}</div>
            </DetailNavLink>
        ) : (
            <a
                href={checkIfVirtualCafeUrlInPostEventPhase(text) ? " " : href}
                key={key}
                style={{
                    userSelect: checkIfVirtualCafeUrlInPostEventPhase(text) ? "none" : "auto",
                    pointerEvents: checkIfVirtualCafeUrlInPostEventPhase(text) ? "none" : "auto"
                }}
                target="_blank"
                rel="noopener noreferrer"
            >
                {text}
            </a>
        )}
    </>
)

interface MessageListPanelProps {
    profileId: string
    conversationId?: string
    conversationType: ConversationType
    participants: Map<string, ConversationParticipant>
    displayStyle?: DisplayStyle
    userConversationId?: string
    viewMode?: SideBarViewMode
    virtualCafe?: boolean
}

const ChatPageMessageList: React.FC<MessageListPanelProps> = (props) => {
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const conversationBottom = useRef<HTMLDivElement>(null)
    const chatItemsRef = useRef<ChatItem[]>([])

    const notificationContext = useNotificationContext()

    const knownMessageIdsRef = useRef<Set<string>>(new Set())
    const [chatItems, dispatchChatItems] = useReducer(() => {
        return [...chatItemsRef.current].reverse()
    }, [])
    // const [pendingChatMessages, setPendingChatMessages] = useState<ChatMessage[]>([])
    // const [unsentChatMessages, setUnsentChatMessages] = useState<ChatMessage[]>([])
    const [nextPageToken, setNextPageToken] = useState<string | undefined>()

    const [pinToBottom, setPinToBottom] = useState(true)

    const scrollerRef = useRef<HTMLDivElement>(null)

    const [showContactModal, setShowContactModal] = useState<boolean>(false)
    const [targetId, setTargetId] = useState<string>("")
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const audio = new Audio("/chatChime2.mp3")
    const [isChatDataLoaded, setIsChatDataLoaded] = useState(false)

    useEffect(() => {
        if (props.conversationId) {
            notificationContext.addMutedConversationId(props.conversationId)
            if (props.userConversationId !== undefined) {
                let id = props.userConversationId
                ;(async () => {
                    const userConversation = await getLastReadUserConversation(id)
                    const lastRead = userConversation?.lastReadMessageCreatedAt
                    const mostRecentMessage = userConversation?.mostRecentMessageCreatedAt
                    const alreadyRead =
                        mostRecentMessage === null ||
                        (lastRead === null && mostRecentMessage === null) ||
                        userConversation?.lastReadMessageCreatedAt === userConversation?.mostRecentMessageCreatedAt
                    if (!alreadyRead) {
                        setLastReadConversation(props.userConversationId as string, mostRecentMessage!)
                    }
                })()
            }
        }
        return () => {
            if (props.conversationId) {
                notificationContext.removeMutedConversationId(props.conversationId)
            }
        }
    }, [props.conversationId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pinToBottom) scrollToBottom(false)
    }, [chatItems]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.viewMode === SideBarViewMode.CHAT) scrollToBottom(true)
    }, [props.viewMode, chatItems])

    // const showOpponentName = props.conversationType !== ConversationType.PRIVATE
    const showOpponentName =
        true /** The oponent name was not displayed for chats 1 on 1 so I set this to true. Old configuration is above  */
    const showAuthorAvatar = props.conversationType === ConversationType.PUBLIC
    const showDateSections = props.displayStyle !== DisplayStyle.PINBOARD

    const newMessageItemsCallback = useCallback(
        async (chatMessagesToAdd: ChatMessage[], index?: number) => {
            const missingOpponentIds = new Set<string>()
            chatMessagesToAdd.forEach((m) => knownMessageIdsRef.current.add(m.id))
            const newChatMessages = chatMessagesToAdd.map((msg) => {
                const isMine = msg.authorId === props.profileId
                const showAuthorName = showOpponentName && (!isMine || props.displayStyle === DisplayStyle.PINBOARD)
                const author = props.participants.get(msg.authorId)
                const authorName = showAuthorName ? author?.name ?? null : undefined
                const authorImg = showAuthorAvatar ? author?.pictureUrl ?? null : undefined
                if (authorName === null) {
                    missingOpponentIds.add(msg.authorId)
                }
                return new ChatMessageItem(msg.timestamp, msg.id, msg.content, msg.authorId, authorName, authorImg, isMine)
            })

            if (showDateSections) {
                addChatItemsWithHeaders(chatItemsRef.current, newChatMessages, index, props.virtualCafe ? audio : undefined)
            } else {
                addChatItemsWithoutHeaders(chatItemsRef.current, newChatMessages, index, props.virtualCafe ? audio : undefined)
            }

            dispatchChatItems()

            if (missingOpponentIds.size <= 0) return

            const userResponses = await Promise.all(Array.from(missingOpponentIds).map(async (opId) => getUserById(opId)))
            const opponents = userResponses
                .map((response) => (response as UserResponse)?.getUser)
                .filter(Boolean)
                .map((user) => {
                    return { id: user!.id, name: user!.name, pictureUrl: user!.pictureUrl }
                })
            opponents.forEach((opponent) => props.participants.set(opponent.id, opponent))

            newChatMessages.forEach((chatItem) => {
                if (missingOpponentIds.has(chatItem.authorId)) {
                    const author = props.participants.get(chatItem.authorId)
                    chatItem.authorName = author?.name
                    chatItem.authorPictureUrl = showAuthorAvatar ? author?.pictureUrl : undefined
                }
            })
            dispatchChatItems()
            // eslint-disable-next-line
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.participants, props.profileId, showOpponentName, showAuthorAvatar, props.displayStyle, showDateSections]
    )

    const newMessageItemsCallbackRef = useRef(newMessageItemsCallback)
    newMessageItemsCallbackRef.current = newMessageItemsCallback

    useEffect(() => {
        if (props.conversationId) {
            const onCreateMessageHandle = (
                API.graphql(
                    graphqlOperation(onMessageCreatedLight, { conversationId: props.conversationId })
                ) as Observable<NewMessageSubscription>
            ).subscribe({
                next: (resp: NewMessageSubscription) => {
                    const msg = resp.value.data.onMessageCreated

                    if (msg && props.userConversationId)
                        (async () => {
                            setLastReadConversation(props.userConversationId as string, msg.createdAt)
                        })()
                    if (msg && !knownMessageIdsRef.current.has(msg.id)) {
                        scrollToBottom(false)
                        const bottomMostExistingMessageIndex = chatItemsRef.current.findIndex((c) => c instanceof ChatMessageItem)
                        // const bottomMostExistingMessageIndex = chatItems.findIndex(c => c instanceof OpponentMessageItem || (c instanceof MyMessageItem && !c.failed)) // TODO if we show unsent/failed messages we need something like this
                        newMessageItemsCallbackRef.current(
                            [
                                {
                                    id: msg.id,
                                    content: msg.content,
                                    authorId: msg.authorId,
                                    isSent: true,
                                    timestamp: new Date(msg.createdAt),
                                    conversationId: msg.conversationId
                                }
                            ],
                            bottomMostExistingMessageIndex
                        )
                        dispatchChatItems()
                    }
                    if (audio && document.hidden && props.virtualCafe) {
                        audio.play()
                    }
                },
                error: (error) => {
                    logger.error({
                        message: "ChatPageMessageList.tsx Subscription Error Message Create",
                        errorMessage: error.message,
                        errorStack: error.stack
                    })
                },
                complete: () => {
                    logger.info("Subscription Complete Message Create")
                }
            })
            return () => onCreateMessageHandle.unsubscribe()
        }
    }, [props.conversationId, props.userConversationId]) //eslint-disable-line

    const loadNextPageCallback = useCallback(async () => {
        if (!props.conversationId) return
        setIsChatDataLoaded(false)
        const scroller = scrollerRef.current
        var scrollPos = scroller?.scrollTop ?? 0
        var scrollableHeightOld = scroller ? scroller.scrollHeight - scroller.clientHeight : 0

        const result = await backOff(() => loadChatMessages(props.conversationId!, nextPageToken), {
            retry: (error: any, attemptNumber: number) => {
                logger.error({
                    message: "ChatPageMesseageList loadChatMessages attempt " + attemptNumber + " failed.",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
                return true
            }
        })
        if (result) {
            setIsChatDataLoaded(true)
            const [newMessages, nextToken] = result
            setNextPageToken(nextToken)
            newMessageItemsCallbackRef.current(newMessages)

            if (scroller) {
                const scrollableHeightNew = scroller.scrollHeight - scroller.clientHeight
                const scrollableHeightDiff = scrollableHeightNew - scrollableHeightOld
                scroller.scrollTop = scrollPos + scrollableHeightDiff
            }
        } else {
            logger.warn("ChatPageMesseageList loadChatMessages failed, giving up")
        }
    }, [props.conversationId, nextPageToken])

    useEffect(() => {
        // Conversation changed => clear everything and load first page
        setNextPageToken(undefined)
        knownMessageIdsRef.current.clear()
        chatItemsRef.current.length = 0
        dispatchChatItems()
        loadNextPageCallback()
    }, [props.conversationId]) // eslint-disable-line react-hooks/exhaustive-deps

    const scrollToBottom = (animate: boolean) => {
        conversationBottom?.current?.scrollIntoView({ behavior: animate ? "smooth" : "auto" })
    }

    moment.locale(language)
    return (
        <>
            <ScrollingContainer
                className="chatlist-scrollref"
                ref={scrollerRef}
                style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "black" : "white" }}
            >
                <ConversationContainer>
                    {nextPageToken && isChatDataLoaded &&  (
                        <InView
                            onChange={(inView) => {
                                if (inView) loadNextPageCallback()
                            }}
                        >
                           <NextPageLoader size="md" />
                        </InView>
                    )}
                    {/* <MessageItemBubble className={"neutral"}>System message</MessageItemBubble> */}
                    {chatItems.map((chatItem, index) => {
                        if (chatItem instanceof ChatMessageItem) {
                            if (chatItem.authorName === null || props.displayStyle === DisplayStyle.PINBOARD) {
                                // no memoization for pending author name or pinboard style where relative time on message changes with language
                                return (
                                    <ChatMessageElement
                                        key={index}
                                        chatMessageItem={chatItem}
                                        displayStyle={props.displayStyle}
                                        setTargetId={setTargetId}
                                        setShowContactModal={setShowContactModal}
                                        isPublic={props.conversationType === ConversationType.PUBLIC}
                                    />
                                )
                            } else {
                                return (
                                    <MemoizedChatMessageElement
                                        key={index}
                                        chatMessageItem={chatItem}
                                        displayStyle={props.displayStyle}
                                        setTargetId={setTargetId}
                                        setShowContactModal={setShowContactModal}
                                        isPublic={props.conversationType === ConversationType.PUBLIC}
                                    />
                                )
                            }
                        } else {
                            return (
                                <MemoizedChatHeaderElement key={index}>
                                    <ChatHeaderItem>{calcHeaderItemTime(chatItem.timestamp)}</ChatHeaderItem>
                                </MemoizedChatHeaderElement>
                            )
                        }
                    })}
                    <InView
                        onChange={(inView) => {
                            setPinToBottom(inView)
                        }}
                    >
                        <div style={{ float: "left", clear: "both", height: "1px" }} ref={conversationBottom} />
                    </InView>
                </ConversationContainer>
            </ScrollingContainer>
            {showContactModal && <ContactModal userId={targetId} close={() => setShowContactModal(false)}></ContactModal>}
        </>
    )
}

function calcHeaderItemTime(time: Date): string {
    const timeMoment = moment(time)
    if (isToday(timeMoment) || isYesterday(timeMoment)) {
        const timeString = timeMoment.calendar().split(" ")[0]
        return timeString.charAt(0).toUpperCase() + timeString.slice(1)
    }
    if (moment().diff(timeMoment, "days") < 7) {
        // show relative dates for a week
        return timeMoment.format("dddd")
    }
    return timeMoment.format("L")
}

function addChatItemsWithoutHeaders(
    existingChatItems: ChatItem[],
    newMessages: ChatItem[],
    index: number | undefined = undefined,
    audio?: HTMLAudioElement
) {
    if (index === undefined) {
        existingChatItems.push(...newMessages)
    } else {
        existingChatItems.splice(index, 0, ...newMessages)
    }
}

function addChatItemsWithHeaders(
    existingChatItems: ChatItem[],
    newMessages: ChatItem[],
    index: number | undefined = undefined,
    audio?: HTMLAudioElement
) {
    const result = existingChatItems //[...existingChatItems]
    const lastIndex = result.length - 1
    let insertIndex = index
    if (index === undefined && result.length > 0) {
        const previousMessage = result.pop()
        newMessages.unshift(previousMessage!)
    } else if (index !== undefined) {
        if (index >= 0 && index < result.length) {
            const previousMessage = result[index]
            newMessages.push(previousMessage)
            result.splice(index, 1)
        }
        if (index - 1 >= 0 && index - 1 < result.length) {
            const nextMessage = result[index - 1]
            newMessages.unshift(nextMessage)
            result.splice(index - 1, 1)
            insertIndex = index - 1
        }
    }
    if (insertIndex === undefined) {
        calcHeaderItems(newMessages, true)
        result.push(...newMessages)
    } else {
        calcHeaderItems(newMessages, insertIndex === lastIndex)
        result.splice(insertIndex, 0, ...newMessages)
    }
}

function calcHeaderItems(chatItems: ChatItem[], addEndHeader: Boolean) {
    let previousItem: ChatItem | undefined
    let i = 0
    while (i < chatItems.length) {
        const chatItem = chatItems[i]

        if (previousItem != null) {
            const isSameDay = checkSameDay(previousItem.timestamp, chatItem.timestamp)
            const previousItemIsHeader = previousItem instanceof HeaderItem
            if (previousItemIsHeader && isSameDay) {
                chatItems.splice(i - 1, 1) // chatItems.removeAt(i-1)
                i--
            }
            if (!previousItemIsHeader && !isSameDay) {
                chatItems.splice(i, 0, new HeaderItem(previousItem.timestamp))
                i++
            }
        }

        previousItem = chatItem
        i++
    }

    if (previousItem != null && addEndHeader) {
        chatItems.push(new HeaderItem(previousItem.timestamp))
    }
}

function checkSameDay(first: Date, second: Date) {
    return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
    )
}

export const MessageRow = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 80%;
    margin-bottom: 10px;
    font-family: ${branding.font1};
    position: relative;
    &.withAvatar {
        flex-direction: row;
    }

    &.neutral {
        align-self: center;
    }
    &.mine {
        align-self: flex-end;
    }
    &.their {
        align-self: flex-start;
    }
`
const MessageElement = styled.div`
    display: flex;
    flex-direction: column;
`
const HeaderElement = styled.div`
    font-size: 10px;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 16px;
    margin-bottom: 8px;
    width: 95%;

    p:after,
    p:before {
        content: " ";
        display: block;
    }

    p {
        text-align: center;
        left: 4px;
        right: 4px;
        border-radius: 5px;
        background: #d9d9d9;
        color: ${branding.chatInputColorTime ?? branding.mainInfoColor};
        margin-right: auto;
        margin-left: auto;
        width: 71px;
        height: 20px;
    }
`

const MemoizedChatMessageElement = React.memo(ChatMessageElement)
const MemoizedChatHeaderElement = React.memo(HeaderElement)

const MessageItemBubble = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px;
    max-width: 100%;
    font-family: ${branding.font1};

    &.neutral {
        font-style: normal;
        font-size: 12px;
        align-self: center;
        background: antiquewhite;
        border-radius: 15px;
        padding: 7px 10px;
        margin: 10px 0;
    }
    &.mine {
        align-self: flex-end;
        background: #ffffff;
        border-radius: 10px;
        border-bottom-right-radius: 0px;
        border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    }
    &.their {
        align-self: flex-start;
        background: #d9d9d9;
        border-radius: 10px;
        border-bottom-left-radius: 0px;
    }
`
const MessageItemAuthor = styled.div`
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
`
const MessageContent = styled.div`
    /* font-size: 12px; */
    font-size: 12px;
    font-family: ${branding.font1};
    line-height: 14px;
    white-space: pre-wrap;
    word-break: break-word;
    padding-right: 30px;
    max-width: 417px;
`
const MessageItemDate = styled.div`
    font-size: 10px;
    font-family: ${branding.font1};
    line-height: 12px;
    margin: 0;
    color: ${branding.chatInputColorTime ?? branding.mainInfoColor};
    align-self: flex-end;

    &.dark {
        color: ${branding.chatInputColorTime ?? "#fff"};
    }
`
const MessageStatus = styled.div``
export const AvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: 15px;
`

export const TheirAvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: 15px;

    bottom: 0;
    position: absolute;
`

export const MineAvatarWrapper = styled.div`
    cursor: pointer;
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    margin-left: calc(100% - 50px);

    bottom: 0;
    position: absolute;
`

interface ChatItemProps {
    chatMessageItem: ChatMessageItem
    displayStyle?: DisplayStyle
    setShowContactModal: (show: boolean) => void
    setTargetId: (id: string) => void

    isPublic?: boolean
}

function ChatMessageElement(props: ChatItemProps) {
    if (props.displayStyle === DisplayStyle.PINBOARD) {
        return <ChatMessageElementPinboard chatMessageItem={props.chatMessageItem} />
    }
    return (
        <ChatMessageElementDefault
            chatMessageItem={props.chatMessageItem}
            dark={props.displayStyle === DisplayStyle.DEFAULT_DARK}
            setTargetId={props.setTargetId}
            setShowContactModal={props.setShowContactModal}
            isPublic={props.isPublic}
        />
    )
}

const ChatMessageElementDefault: React.FC<{
    chatMessageItem: ChatMessageItem
    dark: boolean
    setShowContactModal: (show: boolean) => void
    setTargetId: (id: string) => void
    isPublic?: boolean
}> = (props) => {
    const chatMessageItem = props.chatMessageItem
    const showAuthorAvatar = !chatMessageItem.isMine && chatMessageItem.authorPictureUrl !== undefined // if authorPictureUrl is null => avatar url still unknown & being queried
    const className = (props.chatMessageItem.isMine ? "mine" : "their") + (props.dark ? " dark" : "")
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()

    const [loaded, setLoaded] = useState<boolean>(false)

    const [systemMessageUserName, setSystemMessageUserName] = useState<string>("")
    const [systemMessage, setSystemMessage] = useState<string | undefined>()

    function createSystemMessage() {
        let name = props.chatMessageItem.content.split("/")[1]
        setSystemMessageUserName(name)

        if (props.chatMessageItem.content.startsWith("systemAdded")) {
            setSystemMessage(strings.chatBranding.participantJoinedMessageText)
        } else if (props.chatMessageItem.content.startsWith("systemDeleted")) {
            setSystemMessage(strings.chatBranding.participantRemovedMessageText)
        } else if (props.chatMessageItem.content.startsWith("systemExited")) {
            setSystemMessage(strings.chatBranding.participantLeftMessageText)
        }
    }

    useEffect(() => {
        createSystemMessage()
        setLoaded(true)
        // eslint-disable-next-line
    }, [props.chatMessageItem, lang])

    if (!loaded) {
        return null
    }

    if (systemMessage) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "10px",
                    color: branding.sideIconBar.sideIconColorDark,
                    marginTop: "10px"
                }}
            >
                <div style={{ display: "inline-block", fontWeight: "bold", marginRight: "3px" }}>{systemMessageUserName}</div>
                <div style={{ display: "inline-block" }}>{systemMessage}</div>
            </div>
        )
    }

    if (showAuthorAvatar) {
        return (
            <MessageRow className={className + " withAvatar"}>
                <TheirAvatarWrapper
                    onClick={() => {
                        props.setTargetId(chatMessageItem.authorId)
                        props.setShowContactModal(true)
                    }}
                >
                    <AvatarWithDefault
                        size={40}
                        src={chatMessageItem.authorPictureUrl ?? undefined}
                        alt={chatMessageItem.authorName ?? "#"}
                        backgroundSize="cover"
                    />
                </TheirAvatarWrapper>
                <MessageElementContentDefault
                    className={className}
                    {...chatMessageItem}
                    margin={props.isPublic ? "65px" : "10px"}
                />
            </MessageRow>
        )
    }
    return (
        <MessageRow className={className}>
            <MessageElementContentDefault className={className} {...chatMessageItem} margin={props.isPublic ? "60px" : "15px"} />
            {props.isPublic && (
                <MineAvatarWrapper
                    onClick={() => {
                        props.setTargetId(chatMessageItem.authorId)
                        props.setShowContactModal(true)
                    }}
                >
                    <AvatarWithDefault
                        size={40}
                        src={chatMessageItem.authorPictureUrl ?? undefined}
                        alt={chatMessageItem.authorName ?? "#"}
                        backgroundSize="cover"
                    />
                </MineAvatarWrapper>
            )}
        </MessageRow>
    )
}

interface MessageElementContentDefaultProps {
    content: string
    className: string
    timestamp: Date
    authorName?: string | null
    margin?: string
}
export const MessageElementContentDefault: React.FC<MessageElementContentDefaultProps> = (props) => {
    const loggedInState = useLoggedInState()
    return (
        <MessageElement style={props.margin ? { marginLeft: props.margin, marginRight: props.margin } : {}}>
            <MessageItemBubble className={props.className}>
                <MessageItemAuthor>
                    {props.authorName
                        ? props.authorName
                        : props.className === "mine"
                        ? loggedInState.user()?.firstName + " " + loggedInState.user()?.lastName
                        : "#"}
                </MessageItemAuthor>
                <Linkify componentDecorator={componentDecorator}>
                    <MessageContent>{props.content}</MessageContent>
                    <MessageItemDate className={props.className}>{moment(props.timestamp).format("HH:mm")}</MessageItemDate>
                </Linkify>
            </MessageItemBubble>

            <MessageStatus />
        </MessageElement>
    )
}

const PinboardMessageRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin: 10px 0;
    align-self: flex-start;
    padding: 0 10px 0 20px;
`
const AvatarWrapperPinboard = styled(DetailNavLink)`
    flex: 0 0 auto;
    width: 25px;
    height: 25px;
`
const PinboardChatMessageHeader = styled.div`
    display: flex;
    flex-direction: row;
`
const PinboardChatMessageAuthor = styled(DetailNavLink)`
    font-weight: bold;
    flex-direction: row;
    font-size: 12px;
`
const PinboardChatMessageTime = styled.div`
    font-size: 12px;
    color: #b3b3b3;
    margin-left: 5px;
`

const ChatMessageElementPinboard: React.FC<{ chatMessageItem: ChatMessageItem }> = (props) => {
    const chatMessageItem = props.chatMessageItem
    const [timeString, setTimeString] = useState(moment(chatMessageItem.timestamp).fromNow())
    const [isVisible, setIsVisible] = useState(false)
    const lang = useLanguageState().getLanguage()

    moment.locale(lang)

    useEffect(() => {
        // Update the timestamp every 10 sec
        setInterval(() => {
            setTimeString(moment(chatMessageItem.timestamp).fromNow())
        }, 10000)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeString(moment(chatMessageItem.timestamp).fromNow())

        if (!isVisible) return
        const timePassed = new Date().getTime() - chatMessageItem.timestamp.getTime() // difference in milli seconds
        if (timePassed < 1000 * 60 * 60 * 24) {
            if (timePassed <= 0) {
                // if this system time is lower than the system time of the participants mashine
                const adaptTimestamp = new Date(chatMessageItem.timestamp.getTime() + (timePassed - 1000))
                setTimeString(moment(adaptTimestamp).fromNow())
            } else {
                setTimeString(moment(chatMessageItem.timestamp).fromNow())
            }
        }
    }, [isVisible, chatMessageItem.timestamp, lang])

    return (
        <PinboardMessageRow>
            <AvatarWrapperPinboard type="user" id={props.chatMessageItem.authorId} name={props.chatMessageItem.authorName ?? "#"}>
                <AvatarWithDefault
                    size={25}
                    src={chatMessageItem.authorPictureUrl ?? undefined}
                    alt={chatMessageItem.authorName ?? "#"}
                    backgroundSize="cover"
                />
            </AvatarWrapperPinboard>
            <MessageElement style={{ marginLeft: "10px" }}>
                <PinboardChatMessageHeader>
                    <PinboardChatMessageAuthor
                        type="user"
                        id={props.chatMessageItem.authorId}
                        name={props.chatMessageItem.authorName ?? "#"}
                    >
                        {chatMessageItem.authorName ?? "#"}
                    </PinboardChatMessageAuthor>
                    <InView onChange={setIsVisible}>
                        <PinboardChatMessageTime>{timeString}</PinboardChatMessageTime>
                    </InView>
                </PinboardChatMessageHeader>
                <MessageContent style={{ whiteSpace: "normal" }}>
                    <Linkify componentDecorator={componentDecorator}>{chatMessageItem.content}</Linkify>
                </MessageContent>
                <MessageStatus />
            </MessageElement>
        </PinboardMessageRow>
    )
}

export default ChatPageMessageList
