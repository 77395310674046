import { useNavigationSource } from "../../../tracking/NavigationSource"
import { Exhibitor } from "../../../backendServices/Types"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import * as React from "react"
import branding from "../../../branding/branding"
import ActionsWrapper from "../../../contentArea/entitiesActions/ActionsWrapper"
import MeetingDetails from "../../MeetingDetails"
import { device } from "../../../utils/Device"
import useWindowDimensions from "../../WindowDimensionsHook"
import BadgeArea from "../../BadgeArea"

interface OrganizationProps {
    exhibitor: Exhibitor
    backgroundUrl?: string
    maxBadgeCount?: number
}

export const OrganizationComponent: React.FunctionComponent<OrganizationProps> = (props) => {
    const nav = useNavigationSource()
    const { isMobile } = useWindowDimensions()

    return (
        <OrganizationComponentRoot>
            <Tile
                onClick={() => nav.set("COLLECTION")}
                to={buildDetailLink(props.exhibitor.id, props.exhibitor.name, "organization")}
                $background={props.backgroundUrl}
                className={isMobile ? "isMobile" : ""}
            >
                <Logo url={props.exhibitor.pictureURL} initials={props.exhibitor.initials} />
                <Actions organization={props.exhibitor} />
                <Infos className={isMobile ? "isMobile" : ""}>
                    <InfosItem className={isMobile ? "isMobile" : ""}>{props.exhibitor.name}</InfosItem>
                    <EventDateInfos organization={props.exhibitor} />
                    <BadgeArea
                        maxBadgeCount={props.maxBadgeCount}
                        categories={props.exhibitor.categories || []}
                        marginTop="8px"
                    />
                </Infos>
                <Sessions organization={props.exhibitor} />
            </Tile>
        </OrganizationComponentRoot>
    )
}

/* #region  Styled Components */

export const OrganizationComponentRoot = styled.div`
    border-radius: 5px;
`

const IconContainer = styled.div`
    margin-top: 15px;
    margin-left: 85px;
    color: white;
    display: flex;
    justify-content: center;

    span {
        margin-left: 2px;
        margin-top: 0px;
    }
`

export const Tile = styled(NavLink)<{ $background?: string }>`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 115px;
    width: 205px;
    color: white !important;
    ${(props) =>
        props.$background ? `background: url("${props.$background}");` : "background: url('/branding/no-profile.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top; /* left top */
    padding: 25px 25px 25px 8px;
    border-radius: 5px;
    margin: ${branding.receptionPage.showfloorTileMargins ?? "0px 10px 20px 0px"};

    ${IconContainer} {
        margin-top: -5px;
        margin-left: 0;
        margin-right: 20px;
    }

    .rs-row {
        position: initial;

        div {
            margin-top: 5px;
            width: 50px;
            height: 50px;
            display: none;
            img {
                width: 40px;
                height: 40px;
                margin-top: 3px;
            }
        }

        div:first-child {
            display: initial;
        }
    }

    &:active,
    &:link {
        text-decoration: none;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${branding.receptionPage.lobbypageShowfloorTilesDarkenOverlay ?? "rgba(0,0,0, .5)"};
        border-radius: 5px;
    }

    & > * {
        z-index: 2;
    }

    @media ${device.tablet} {
        height: 280px;
        width: 500px;
        padding-left: 25px;

        &.isMobile {
            height: 115px;
            width: 205px;
            padding: 25px 25px 25px 8px;
        }
    }

    @media ${device.mobile} {
        height: 115px;
        width: 205px;
        padding: 25px 25px 25px 8px;
    }
`
export const InfosItem = styled.div<{ fullWidth?: boolean }>`
    display: flex;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    width: 110px;
    margin-top: 10px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    @media ${device.tablet} {
        font-size: 12px;
        line-height: inherit;
        width: ${(props) => (props.fullWidth ? branding.receptionPage.lobbyTileTextWidth : "220px")};
        margin-top: inherit;
        -webkit-line-clamp: 4;

        &.isMobile {
            display: flex;
            font-family: ${branding.font1};
            font-style: normal !important;
            font-weight: 400 !important;
            font-size: 10px !important;
            line-height: 12px !important;
            width: auto !important;
            margin-top: 10px !important;
        }
    }

    @media ${device.mobile} {
        display: flex;
        font-family: ${branding.font1};
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 10px !important;
        line-height: 12px !important;
        width: auto !important;
        margin-top: 10px !important;
    }
`

export const Infos = styled.div`
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 5px;
    margin-left: 75px;

    ${InfosItem}:first-child {
        width: 103px;
        font-weight: bold !important;
        font-size: 14px !important;
        line-height: 16px !important;
        -webkit-line-clamp: 3;

        @media ${device.tablet} {
            width: unset;
            font-size: 18px !important;
            line-height: 22px !important;
            line-height: unset;

            &.isMobile {
                width: 103px;
                font-weight: bold !important;
                font-size: 12px !important;
                line-height: 14px !important;
                -webkit-line-clamp: 3;
            }
        }

        @media ${device.mobile} {
            width: 103px;
            font-weight: bold !important;
            font-size: 12px !important;
            line-height: 14px !important;
            -webkit-line-clamp: 3;
        }
    }

    @media ${device.tablet} {
        top: unset;
        bottom: 25px;
        margin-left: unset;

        &.isMobile {
            top: 5px;
            margin-left: 75px;
            bottom: unset;
        }
    }

    @media ${device.mobile} {
        top: 5px;
        margin-left: 75px;
        bottom: unset;
    }
`

/* #endregion */

/* #region  Actions */

const ActionsRoot = styled.div`
    display: flex;
    position: absolute;
    right: 0;
    justify-content: flex-end;

    div {
        position: relative;
    }
`

interface ActionsProps {
    organization: Exhibitor
}
function Actions(props: ActionsProps) {
    const { organization } = props
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null
    return (
        <ActionsRoot>
            <ActionsWrapper
                layout="lobby-layout"
                organization={organization}
                basisPremiumType={organization.basisPremium}
                entity={{ id: organization.id, entityType: "organization" }}
                exhibitorHasContacts={organization && organization.contacts && organization.contacts.length > 0}
            />
        </ActionsRoot>
    )
}

/* #endregion */

/* #region  EventDate Infos */

interface EventDateInfosProps {
    organization: Exhibitor
}
function EventDateInfos(props: EventDateInfosProps) {
    const { organization } = props
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null

    return (
        <InfosItem fullWidth={!organization.eventdates}>
            {(organization.teaser &&
                organization.teaser.split("\\n").map((item, i) => (
                    <span key={i}>
                        {item}
                        <br />
                    </span>
                ))) ||
                " "}
        </InfosItem>
    )
}

/* #endregion */

/* #region  Sessions */

const SessionRoot = styled.div`
    position: absolute;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
    right: 20px;
    bottom: 20px;
    margin-bottom: -10px;
`

interface SessionsProps {
    organization: Exhibitor
}
function Sessions(props: SessionsProps) {
    const { organization } = props
    const { isMobile } = useWindowDimensions()

    if (isMobile) return null

    return (
        <SessionRoot>
            <MeetingDetails content={organization}></MeetingDetails>
        </SessionRoot>
    )
}

/* #endregion */

/* #region  CompanyLogo */

const LogoRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    width: 60px;
    height: 60px;
    padding: 2px;
    margin-top: -10px;

    & p {
        font-family: ${branding.font1};
        max-width: 40px;
        font-size: 14px;
        line-height: 0.6rem;
        color: rgb(0, 0, 0);
        text-align: center;
        display: inline-table;
        margin-bottom: 0;
        white-space: normal;
        word-break: break-word;
    }

    @media ${device.tablet} {
        width: 80px;
        height: 80px;
        padding: 10px;
        margin: inherit;

        &.isMobile {
            width: 60px;
            height: 60px;
            padding: 2px;
            margin-top: -10px;
        }
    }

    @media ${device.mobile} {
        width: 60px;
        height: 60px;
        padding: 2px;
        margin-top: -10px;
    }
`

const LogoImg = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`

interface LogoProps {
    url?: string
    initials?: string
}
export function Logo(props: LogoProps) {
    const { url, initials } = props
    const { isMobile } = useWindowDimensions()

    let logo
    if (url) logo = <LogoImg src={url} />
    else if (branding.exhibitorsPageContent.defaultLogoVisibleOnCompanyTiles)
        logo = <LogoImg src={"/branding/rsz_defaultgrey.png"} />
    else logo = <p style={{ fontFamily: branding.font1 }}>{initials}</p>

    return <LogoRoot className={isMobile ? "isMobile" : ""}>{logo}</LogoRoot>
}

/* #endregion */
