import { Category } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { getStaticDomain } from "../../environments"
import { getActiveLanguage } from "../../globalStates/LanguageState"

type HallsChangedHandler = (halls: Hall[]) => void

class HallplanWrapper {
    container: HTMLDivElement | null = null

    halls: Hall[] = []
    hallsChangedHandler: HallsChangedHandler[] = []
    eventMapper: Map<any, any> = new Map()

    pointerCaptured = false
    pointerX: number = 0
    pointerY: number = 0

    mouseMoveFactor = 2

    loaded = false
    // Base url to the topic in S3
    baseUrl = `${getStaticDomain()}/${branding.configuration.topicName}`
    // URL for the folder where every hall svg is stored
    svgUrl = `${this.baseUrl}/onlineGuideHallplan/`

    pageX: number = 0
    pageY: number = 0

    constructor() {
        // URL for information about stands and halls
        const hallAndStand = `${this.baseUrl}/${getActiveLanguage()}/hallsAndStands.json`

        ;(window as any).isBrowserHallplan = true
        ;(window as any).browserHallplanSvgPath = this.svgUrl
        ;(window as any).browserHallplanHallAndStand = hallAndStand

        this.addHallplanReadyHandler(() => {
            this.loaded = true
            this.halls = []
            this.halls.push(new Hall({ id: "overview", n: branding.hallPlanPageBranding.hallSelectorPlaceholder }))
            ;(window as any).hallplanDataModel()?.halls.forEach((h: any) => this.halls.push(new Hall(h)))
            this.hallsChangedHandler.forEach((h) => h(this.halls))
        })

        const script = document.createElement("script")
        script.src = "/hallplan/hallplan.nocache.js"

        document.body.appendChild(script)
    }

    loadLang = (lang: string) => {
        if (!(window as any).hallplanInit) {
            return
        }
        // URL for information about stands and halls
        const hallAndStand = `${this.baseUrl}/${lang}/hallsAndStands.json`
        ;(window as any).hallplanInit(this.svgUrl, hallAndStand)
    }

    isLoaded = () => this.loaded

    addToContainer = (container: HTMLDivElement) => {
        if (!(window as any).hallplanAddToContainer) {
            console.warn("hallplanAddToContainer used before hallplan initialied")
            return
        }

        this.container = container

        const that = this
        function onPointerDown(ev: PointerEvent) {
            if (!that.container) return // No container? Not added to dom! (shouldn't even be possible)

            // Capture the pointer. That way pointer events only fire on this one even after leaving the window. Doesn't work on chrome
            if ((that.container as any).setCapture) (that.container as any).setCapture()

            // hallplan instead of this, because of parameter fuckery
            that.pointerCaptured = true
            that.pointerX = ev.x
            that.pointerY = ev.y

            that.pageX = ev.pageX
            that.pageY = ev.pageY
        }

        function onPointerUp(ev: PointerEvent): any {
            that.pointerCaptured = false

            // Release capture for pointer events to be normally handled again
            if ((document as any).releaseCapture) (document as any).releaseCapture()
        }

        function onPointerMove(ev: PointerEvent): any {
            if (that.pointerCaptured) {
                that.setPan((ev.x - that.pointerX) * that.mouseMoveFactor, (ev.y - that.pointerY) * that.mouseMoveFactor)
                that.pointerX = ev.x
                that.pointerY = ev.y
            }
        }

        // Remove existing bindings. In case addToContainer is done multiple times
        container.removeEventListener("pointerdown", onPointerDown)
        container.addEventListener("pointerdown", onPointerDown)

        container.removeEventListener("pointerup", onPointerUp)
        container.addEventListener("pointerup", onPointerUp)

        container.removeEventListener("pointermove", onPointerMove)
        container.addEventListener("pointermove", onPointerMove)
        ;(window as any).hallplanAddToContainer(container)
    }

    setZoomScale = (scale: number) => {
        if (!(window as any).hallplanZoomScale) {
            console.warn("hallplanZoomScale used before hallplan initialied")
            return
        }
        ;(window as any).hallplanZoomScale(scale)
    }

    /** x, y must be integers */
    setPan = (x: number, y: number) => {
        if (!(window as any).hallplanPan) {
            console.warn("hallplanPan used before hallplan initialied")
            return
        }
        ;(window as any).hallplanPan(x, y)
    }

    setHall = (hall: string) => {
        if (!(window as any).hallplanSetTarget) {
            console.warn("hallplanSetTarget used before hallplan initialied")
            return
        }
        ;(window as any).hallplanSetTarget(hall)
    }
    getHalls = () => {
        return this.halls
    }

    setMarkings = (markings: Marking[]) => {
        if (!(window as any).hallplanSetMarkings) {
            console.warn("hallplanSetMarkings used before hallplan initialied")
            return
        }
        ;(window as any).hallplanSetMarkings(markings)
    }

    getHall = (hallId?: string) => {
        return this.getHalls().find((hall) => hall.getId() === hallId)
    }

    addHallplanReadyHandler = (handler: () => void) => {
        window.addEventListener("hallplanReady", handler)
    }

    removeHallplanReadyHandler = (handler: () => void) => {
        window.removeEventListener("hallplanReady", handler)
    }

    addContentLinkClickedHandler = (handler: ContentLinkClickedHandler) => {
        const handlerWrapper = (e: Event) => {
            // Make sure we have the correct event type
            if (!Array.isArray((e as any).detail)) return

            // detail contains the click parameters from the hallplan
            const params: string[] = (e as any).detail

            handler(params[0] as ContentClickType, params[1])
        }
        this.eventMapper.set(handler, handlerWrapper)
        window.addEventListener("contentLinkClicked", handlerWrapper)
    }

    removeContentLinkClickedHandler = (handler: ContentLinkClickedHandler) => {
        window.removeEventListener("contentLinkClicked", this.eventMapper.get(handler))
    }

    addContentDidChangeTargetHandler = (handler: ContentDidChangeTargetHandler) => {
        const handlerWrapper = (e: Event) => {
            // Make sure we have the correct event type
            if (!Array.isArray((e as any).detail)) return

            // detail contains the click parameters from the hallplan
            const params: string[] = (e as any).detail

            handler(params[0], params[1])
        }
        this.eventMapper.set(handler, handlerWrapper)
        window.addEventListener("contentDidChangeTarget", handlerWrapper)
    }

    removeContentDidChangeTargetHandler = (handler: (e: Event) => void) => {
        window.removeEventListener("contentDidChangeTarget", this.eventMapper.get(handler))
    }

    addStandClickedHandler = (handler: StandClickedHandler) => {
        const handlerWrapper = (e: Event) => {
            // Make sure we have the correct event type
            if (!Array.isArray((e as any).detail)) return

            // detail contains the click parameters from the hallplan
            const params: string[] = (e as any).detail

            handler(params[0], params[1], params[2])
        }
        this.eventMapper.set(handler, handlerWrapper)
        window.addEventListener("contentStandClicked", handlerWrapper)
    }

    removeStandClickedHandler = (handler: StandClickedHandler) => {
        window.removeEventListener("contentStandClicked", this.eventMapper.get(handler))
    }

    addHallsChangedHandler = (handler: HallsChangedHandler) => {
        this.hallsChangedHandler.push(handler)
    }

    removeHallsChangedHandler = (handler: HallsChangedHandler) => {
        const index = this.hallsChangedHandler.indexOf(handler)
        if (index > -1) {
            this.hallsChangedHandler.splice(index, 1)
        }
    }
}
const hallplan = new HallplanWrapper()
export default hallplan

export type StandClickedHandler = (hallId: string, standId: string, standName: string) => void
export type ContentDidChangeTargetHandler = (hallId: string, level: string) => void
export type ContentLinkClickedHandler = (type: ContentClickType, param: string) => void
export type ContentClickType = "halllink" | "organizationlink" | "url"

export class Hall {
    data: any
    constructor(data: any) {
        this.data = data
    }

    /**
     * @return our system id
     */
    getId(): string {
        return this.data.id
    }

    getName(): string {
        return this.data.n
    }
}

export type MarkingType =
    | "badge"
    | "group"
    | "group2"
    | "group3"
    | "group4"
    | "group5"
    | "group6"
    | "group7"
    | "group8"
    | "group9"
    | "group10"
    | "entries"
    | "exits"
    | "favorite"
    | "hallgroup"
    | "lastminute"
    | "scheduled"
    | "selected"
    | "visited"
    | "whereami"
export interface Marking {
    markType: MarkingType
    /** Comma separated list of hallIds*/
    stands: string
    badge?: Category
}
