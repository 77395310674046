import { ColorString, LocalizedString, PluralString } from "../../branding/branding"
import { SocialMediaType } from "../myprofile/MyProfilePageContentBranding"

export enum DetailSection {
    "DETAIL_INFO" = "DETAIL_INFO",
    "TEAM" = "TEAM",
    "LIVE_SESSIONS" = "LIVE_SESSIONS",
    "UPCOMING_SESSIONS" = "UPCOMING_SESSIONS",
    "PREVIOUS_SESSIONS" = "PREVIOUS_SESSIONS",
    "VIRTUAL_CAFES" = "VIRTUAL_CAFES",
    "PRODUCTS" = "PRODUCTS",
    "TRADEMARKS" = "TRADEMARKS",
    "NEWS" = "NEWS",
    "ORGANIZATIONS" = "ORGANIZATIONS",
    "COUPONS" = "COUPONS",
    "LINKS" = "LINKS",
    "MEDIA" = "MEDIA",
    "ADDRESS_PHONE_EMAIL" = "ADDRESS_PHONE_EMAIL",
    "COMPANY_DETAILS" = "COMPANY_DETAILS",
    "COMPANY_INFORMATION_TITLE" = "COMPANY_INFORMATION_TITLE",
    "CATEGORIES" = "CATEGORIES",
    "CO_EXHIBITORS" = "CO_EXHIBITORS",
    "PARENT" = "PARENT",
    "SHOWCASE" = "SHOWCASE",
    "REMOTE_LIVESTREAMS" = "REMOTE_LIVESTREAMS",
    "JOB_OFFERS" = "JOB_OFFERS",
    "PERSONS" = "PERSONS",
    "COMPANY" = "COMPANY",
    "HALLE_STAND_INFO" = "HALLE_STAND_INFO"
}

export enum InfoContentOrderType {
    "DESCRIPTION" = "DESCRIPTION",
    "BADGES" = "BADGES",
    "SOCIAL_MEDIA" = "SOCIAL_MEDIA",
    "ADDRESS_PHONE_EMAIL" = "ADDRESS_PHONE_EMAIL",
    "TALK_WITH_TEAM" = "TALK_WITH_TEAM",
    "COMPANY_DETAILS" = "COMPANY_DETAILS",
    "JOBOFFER_DETAILS" = "JOBOFFER_DETAILS",
    "CONFERENCE_ROOMS" = "CONFERENCE_ROOMS",
    "HALLE_STAND_INFO" = "HALLE_STAND_INFO",
    "NEWS_SUBHEADLINE" = "NEWS_SUBHEADLINE",
    "NEWS_DATE" = "NEWS_DATE"
}

export interface OrganizationDetailPageContentBranding {
    /**
     * @title Show join showroom icon
     * @description Enable / disable visibility of join showroom icon
     * @title_de Show join showroom icon
     * @description_de Enable / disable visibility of join showroom icon
     */
    showJoinShowRoomIcon: boolean
    /**
     * @title Show schedule a meeting icon
     * @description Enable / disable visibility of schedule a meeting icon
     * @title_de Show schedule a meeting icon
     * @description_de Enable / disable visibility of schedule a meeting icon
     */
    showScheduleMeetingIcon: boolean
    /**
     * @title Show register interest icon
     * @description Enable / disable visibility of register interest icon
     * @title_de Show register interest icon
     * @description_de Enable / disable visibility of register interest icon
     */
    showRegisterInterestIcon: boolean
    /**
     * @title Show recommend icon
     * @description Enable / disable visibility of recommend icon
     * @title_de Show recommend icon
     * @description_de Enable / disable visibility of recommend icon
     */
    showRecommendIcon: boolean
    /**
     * @title Show bookmark icon
     * @description Enable / disable visibility of bookmark icon
     * @title_de Show bookmark icon
     * @description_de Enable / disable visibility of bookmark icon
     */
    showBookmarkIcon: boolean
    /**
     * @title Show download v card icon
     * @description Enable / disable visibility of download v card icon
     * @title_de Show download v card icon
     * @description_de Enable / disable visibility of download v card icon
     */
    showDownloadIcon: boolean
    /**
     * @title Show backoffice icon
     * @description Enable / disable visibility of backoffice icon
     * @title_de Show backoffice icon
     * @description_de Enable / disable visibility of backoffice icon
     */
    showBackofficeIcon: boolean

    /**
     * @title Navbar title
     * @description Text for section line contact person label
     * @title_de Navbar title
     * @description_de Text for company detail page title
     */
    navbarTitle: LocalizedString
    /**
     * @title Section line contact person
     * @description Text for section line contact person label
     * @title_de Section line contact person
     * @description_de Text for section line contact person label
     */
    sectionLineContactPerson: LocalizedString
    /**
     * @title Section line categories
     * @description Text for section line categories label
     * @title_de Section line categories
     * @description_de Text for section line categories label
     */
    sectionLineCategories: LocalizedString
    /**
     * @title Section line products description
     * @description Text for section line products description label
     * @title_de Section line products description
     * @description_de Text for section line products description label
     */
    sectionLineProductDescription: LocalizedString
    /**
     * @title Button show more
     * @description Text for show more button's label
     * @title_de Button show more
     * @description_de Text for show more button's label
     */
    buttonShowMore: LocalizedString
    /**
     * @title Button collapse
     * @description Text for collapse button
     * @title_de Button collapse
     * @description_de Text for collapse button
     */
    buttonCollapse: LocalizedString
    /**
     * @title Join showroom
     * @description Text for join showroom option title
     * @title_de Join showroom
     * @description_de Text for join showroom option title
     */
    joinShowroom: LocalizedString
    /**
     * @title Request meeting
     * @description Text for request meeting option title
     * @title_de Request meeting
     * @description_de Text for request meeting option title
     */
    requestMeeting: LocalizedString
    /**
     * @title Register interest
     * @description Text for register interest option title
     * @title_de Register interest
     * @description_de Text for register interest option title
     */
    registerInterest: LocalizedString
    /**
     * @title Recommemd
     * @description Text for recommemd option title
     * @title_de Recommemd
     * @description_de Text for recommemd option title
     */
    recommend: LocalizedString
    /**
     * @title Bookmark
     * @description Text for Bookmark option title
     * @title_de Bookmark
     * @description_de Text for Bookmark option title
     */
    bookmark: LocalizedString
    /**
     * @title Backoffice
     * @description Text for backoffice option title
     * @title_de Backoffice
     * @description_de Text for backoffice option title
     */
    backoffice: LocalizedString
    /**
     * @title Connect with us
     * @description Text for Connect with us option title
     * @title_de Connect with us
     * @description_de Text for Connect with us option title
     */
    connectWithUs: LocalizedString
    /**
     * @title Team section title visible
     * @description Show team section title instead of company name
     * @title_de Team section title visible
     * @description_de Show team section title instead of company name
     */
    teamSectionTitleVisible: boolean
    /**
     * @title Team section title
     * @description Title of team section
     * @title_de Team section title
     * @description_de Title of team section
     */
    teamSectionTitle: LocalizedString
    /**
     * @title Talk with team before string
     * @description Text for the first part of the talk with team label
     * @title_de Talk with team before string
     * @description_de Text for the first part of the talk with team label
     */
    talkWithTeamBefString: LocalizedString
    /**
     * @title Talk with team after string
     * @description Text for the second part of the talk with team label
     * @title_de Talk with team after string
     * @description_de Text for the second part of the talk with team label
     */
    talkWithTeamAftString: LocalizedString
    /**
     * @title Join button
     * @description Text for join button label
     * @title_de Join button
     * @description_de Text for join button label
     */
    joinButton: LocalizedString
    /**
     * @title Live sessions title
     * @description Text for live sessions title
     * @title_de Live sessions title
     * @description_de Text for live sessions title
     */
    liveSessionsTitle: LocalizedString
    /**
     * @title Upcoming sessions title
     * @description Text for upcoming sessions title
     * @title_de Upcoming sessions title
     * @description_de Text for upcoming sessions title
     */
    upcomingSessionsTitle: LocalizedString
    /**
     * @title Past sessions title
     * @description Text for past sessions title
     * @title_de Past sessions title
     * @description_de Text for past sessions title
     */
    pastSessionsTitle: LocalizedString
    /**
     * @title Virtual cafe title
     * @description Text for virtual cafe section title
     * @title_de Virtual cafe title
     * @description_de Text for virtual cafe section title
     */
    virtualCafesTitle: LocalizedString
    /**
     * @title Media title
     * @description Text for media section title
     * @title_de Media title
     * @description_de Text for media section title
     */
    mediaTitle: LocalizedString
    /**
     * @title Video download enabled
     * @description Set downloadability of videos in media section
     * @title_de Video download enabled
     * @description_de Set downloadability of videos in media section
     */
    videoDownloadEnabled: boolean
    /**
     * @title Links title
     * @description Text for links section title
     * @title_de Links title
     * @description_de Text for links section title
     */
    linksTitle: LocalizedString
    /**
     * @title Join now button
     * @description Text for Join now button
     * @title_de Join now button
     * @description_de Text for Join now button
     */
    joinNowButton: LocalizedString
    /**
     * @title Show full screen
     * @description Text for Show full screen option
     * @title_de Show full screen
     * @description_de Text for Show full screen option
     */
    showFullScreen: LocalizedString
    /**
     * @title Bookmark
     * @description Text for bookmark option
     * @title_de Bookmark
     * @description_de Text for bookmark option
     */
    bookmarkPerson: LocalizedString
    /**
     * @title Unbookmark person
     * @description Text for unbookmark option
     * @title_de Unbookmark person
     * @description_de Text for unbookmark option
     */
    unBookmarkPerson: LocalizedString
    /**
     * @title Favorite
     * @description Text for Favorite option
     * @title_de Favorisieren
     * @description_de Text für Favorisieren option
     */
    favoritePerson: LocalizedString
    /**
     * @title Remove favorite person
     * @description Text for remove favorite option
     * @title_de Favorit entfernen
     * @description_de Text für Favorit entfernen option
     */
    removeFavoritePerson: LocalizedString
    /**
     * @title Founding year title
     * @description Text for founding year label
     * @title_de Founding year title
     * @description_de Text for founding year label
     */
    foundingYearTitle: LocalizedString
    /**
     * @title Company size title
     * @description Text for company size label
     * @title_de Company size title
     * @description_de Text for company size label
     */
    companySizeTitle: LocalizedString
    /**
     * @title Industry title
     * @description Text for industry label
     * @title_de Industry title
     * @description_de Text for industry label
     */
    industryTitle: LocalizedString
    /**
     * @title Exploration phase placeholder
     * @description Text for exploration phase placeholder
     * @title_de Exploration phase placeholder
     * @description_de Text for exploration phase placeholder
     */
    explorationPhasePlaceholder: LocalizedString
    /**
     * @title Post phase placeholder
     * @description Text for post phase placeholder
     * @title_de Post phase placeholder
     * @description_de Text for post phase placeholder
     */
    postPhasePlaceholder: LocalizedString
    /**
     * @title Media slider primary color
     * @description Text color for media slider
     * @title_de Media slider primary color
     * @description_de Text color for media slider
     */
    mediaSliderPrimaryColor: ColorString
    /**
     * @title Icon color
     * @description Custom icon color
     * @title_de Icon color
     * @description_de Custom icon color
     */
    iconColor: ColorString
    /**
     * @title Company information title
     * @description Text for company information title
     * @title_de Company information title
     * @description_de Text for company information title
     */
    companyInformationTitle: LocalizedString
    /**
     * @title Company contact to title
     * @description Text for company contact to title
     * @title_de Company contact to title
     * @description_de Text for company contact to title
     */
    companyContactToTitle: LocalizedString
    /**
     * @title Organization detail page main content order
     * @description Main order of sections inside of the organization detail page - Possible options (DETAIL_INFO, TEAM, LIVE_SESSIONS, UPCOMING_SESSIONS, PREVIOUS_SESSIONS, CATEGORIES, VIRTUAL_CAFES, PARENT, CO_EXHIBITORS, PRODUCTS, TRADEMARKS, COUPONS, SHOWCASE, LINKS, MEDIA, COMPANY_INFORMATION_TITLE, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS)
     * @title_de Organization detail page main content order
     * @description_de Main order of sections inside of the organization detail page - Possible options (DETAIL_INFO, TEAM, LIVE_SESSIONS, UPCOMING_SESSIONS, PREVIOUS_SESSIONS, CATEGORIES, VIRTUAL_CAFES, PARENT, CO_EXHIBITORS, PRODUCTS, TRADEMARKS, COUPONS, SHOWCASE, LINKS, MEDIA, COMPANY_INFORMATION_TITLE, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS)
     */
    orgaDetailPageMainContentOrder: Array<DetailSection>
    /**
     * @title Organization detail page info content order
     * @description Info order of sections inside of the organization detail page - Possible options (SOCIAL_MEDIA, DESCRIPTION, TALK_WITH_TEAM, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS, CONFERENCE_ROOMS)
     * @title_de Organization detail page info content order
     * @description_de Info order of sections inside of the organization detail page - Possible options (SOCIAL_MEDIA, DESCRIPTION, TALK_WITH_TEAM, ADDRESS_PHONE_EMAIL, COMPANY_DETAILS, CONFERENCE_ROOMS)
     */
    orgaDetailPageInfoSectionOrder: Array<InfoContentOrderType>
    /**
     * @title Default header basis premium types
     * @description Visibility of header image by basis premium type
     * @title_de Default header basis premium types
     * @description_de Visibility of header image by basis premium type
     */
    defaultHeaderBasisPremiumTypes: Array<string>
    /**
     * @title Company links font weight
     * @description Font weight for company links
     * @title_de Company links font weight
     * @description_de Font weight for company links
     */
    companyLinksFontWeight: string
    /**
     * @title Categories section title
     * @description Text for categories section title
     * @title_de Categories section title
     * @description_de Text for categories section title
     */
    categoriesSectionTitle: LocalizedString
    /**
     * @title Co exhibitors section title
     * @description Text for Co exhibitors section title
     * @title_de Co exhibitors section title
     * @description_de Text for Co exhibitors section title
     */
    coExhibitorsSectionTitle: LocalizedString
    /**
     * @title Parent section title
     * @description Text for parent section title
     * @title_de Parent section title
     * @description_de Text for parent section title
     */
    parentSectionTitle: LocalizedString
    /**
     * @title Remove expo showroom during post event
     * @description Enable / disable expo showroom option during post event
     * @title_de Remove expo showroom during post event
     * @description_de Enable / disable expo showroom option during post event
     */
    removeExpoShowroomDuringPostEvent: boolean
    /**
     * @title Blocked staff icon color
     * @description Icon color for blocked staff icon
     * @title_de Blocked staff icon color
     * @description_de Icon color for blocked staff icon
     */
    blockedStaffIconColor: string
    /**
     * @title Number of products none
     * @description Number of products shown for organization which has none as basis premium type package
     * @title_de Number of products none
     * @description_de Number of products shown for organization which has none as basis premium type package
     */
    numberOfProductsNone: number
    /**
     * @title Number of products basic
     * @description Number of products shown for organization which has basic as basis premium type package
     * @title_de Number of products basic
     * @description_de Number of products shown for organization which has basic as basis premium type package
     */
    numberOfProductsBasic: number
    /**
     * @title Number of products standard
     * @description Number of products shown for organization which has standard as basis premium type package
     * @title_de Number of products standard
     * @description_de Number of products shown for organization which has standard as basis premium type package
     */
    numberOfProductsStandard: number
    /**
     * @title Number of products premium
     * @description Number of products shown for organization which has premium as basis premium type package
     * @title_de Number of products premium
     * @description_de Number of products shown for organization which has premium as basis premium type package
     */
    numberOfProductsPremium: number
    /**
     * @title Number of products ultimate
     * @description Number of products shown for organization which has ultimate as basis premium type package
     * @title_de Number of products ultimate
     * @description_de Number of products shown for organization which has ultimate as basis premium type package
     */
    numberOfProductsUltimate: number
    /**
     * @title Number of trademarks none
     * @description Number of trademarks shown for organization which has none as basis premium type package
     * @title_de Number of trademarks none
     * @description_de Number of trademarks shown for organization which has none as basis premium type package
     */
    numberOfTrademarksNone: number
    /**
     * @title Number of trademarks basic
     * @description Number of trademarks shown for organization which has basic as basis premium type package
     * @title_de Number of trademarks basic
     * @description_de Number of trademarks shown for organization which has basic as basis premium type package
     */
    numberOfTrademarksBasic: number
    /**
     * @title Number of trademarks standard
     * @description Number of trademarks shown for organization which has standard as basis premium type package
     * @title_de Number of trademarks standard
     * @description_de Number of trademarks shown for organization which has standard as basis premium type package
     */
    numberOfTrademarksStandard: number
    /**
     * @title Number of trademarks premium
     * @description Number of trademarks shown for organization which has premium as basis premium type package
     * @title_de Number of trademarks premium
     * @description_de Number of trademarks shown for organization which has premium as basis premium type package
     */
    numberOfTrademarksPremium: number
    /**
     * @title Number of trademarks ultimate
     * @description Number of trademarks shown for organization which has ultimate as basis premium type package
     * @title_de Number of trademarks ultimate
     * @description_de Number of trademarks shown for organization which has ultimate as basis premium type package
     */
    numberOfTrademarksUltimate: number
    /**
     * @title Number of news none
     * @description Number of news shown for organization which has none as basis premium type package
     * @title_de Number of news none
     * @description_de Number of news shown for organization which has none as basis premium type package
     */
    numberOfNewsNone: number
    /**
     * @title Number of news basic
     * @description Number of news shown for organization which has basic as basis premium type package
     * @title_de Number of news basic
     * @description_de Number of news shown for organization which has basic as basis premium type package
     */
    numberOfNewsBasic: number
    /**
     * @title Number of news standard
     * @description Number of news shown for organization which has standard as basis premium type package
     * @title_de Number of news standard
     * @description_de Number of news shown for organization which has standard as basis premium type package
     */
    numberOfNewsStandard: number
    /**
     * @title Number of news premium
     * @description Number of news shown for organization which has premium as basis premium type package
     * @title_de Number of news premium
     * @description_de Number of news shown for organization which has premium as basis premium type package
     */
    numberOfNewsPremium: number
    /**
     * @title Number of news ultimate
     * @description Number of news shown for organization which has ultimate as basis premium type package
     * @title_de Number of news ultimate
     * @description_de Number of news shown for organization which has ultimate as basis premium type package
     */
    numberOfNewsUltimate: number
    /**
     * @title vCard note template
     * @description Text for vCard note template
     * @title_de vCard note template
     * @description_de Text for vCard note template
     */
    vCardNoteTemplate: LocalizedString
    /**
     * @title vCard Download available
     * @description Enable / disable vCard download option
     * @title_de vCard Download available
     * @description_de Enable / disable vCard download option
     */
    vCardDownloadAvailable: boolean
    /**
     * @title Available social medias
     * @description Array of available social medias - Possible options (FACEBOOK, TWITTER, LINKEDIN, XING, INSTAGRAM, YOUTUBE)
     * @title_de Available social medias
     * @description_de Array of available social medias - Possible options (FACEBOOK, TWITTER, LINKEDIN, XING, INSTAGRAM, YOUTUBE)
     */
    availableSocialMedias: SocialMediaType[]
    /**
     * @title Company teaser font size
     * @description Font size for company teaser on organization detail page
     * @title_de Company teaser font size
     * @description_de Font size for company teaser on organization detail page
     */
    companyTeaserFontSize: string
    /**
     * @title Showcase
     * @description Text for showcase label
     * @title_de Showcase
     * @description_de Text for showcase label
     */
    showcase: LocalizedString
    /**
     * @title Fullscreen button
     * @description Text for fullscreen button label
     * @title_de Fullscreen button
     * @description_de Text for fullscreen button label
     */
    fullscreenButton: LocalizedString
    /**
     * @title New tab button
     * @description Text for new tab button label
     * @title_de New tab button
     * @description_de Text for new tab button label
     */
    newTabButton: LocalizedString
    /**
     * @title Root categories
     * @description Root categories for organization categories
     * @title_de Root categories
     * @description_de Root categories for organization categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the organization detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the organization detail page
     */
    showCategoryHierarchy: boolean
    /**
     * @title Show bounded categories
     * @description Show only bounded categories
     * @title_de Show bounded categories
     * @description_de Show only bounded categories
     */
    showBoundedOrganizationCategories: boolean
    /**
     * @title Category fill color
     * @description Unactive category fill color
     * @title_de Category fill color
     * @description_de Unactive category fill color
     */
    categoryFillColor: string
    /**
     * @title Category text color
     * @description Unactive category text color
     * @title_de Category text color
     * @description_de Unactive category text color
     */
    categoryTextColor: string
    /**
     * @title Category fill color
     * @description Active category fill color
     * @title_de Category fill color
     * @description_de Active category fill color
     */
    categoryActiveFillColor: string
    /**
     * @title Category text color
     * @description Active category text color
     * @title_de Category text color
     * @description_de Active category text color
     */
    categoryActiveTextColor: string

    /**
     * @title Action icons color in list view
     * @description defines the colors of the action items in the list view
     * @title_de Action icons color in list view
     * @description_de Action icons color in list view
     */
    listViewActionIconsColor: ColorString

    /**
     * @title List view divider color
     * @description Color of the list view divider
     * @title_de List view divider color
     * @description_de Color of the list view divider
     */
    listViewDividerColor: ColorString

    /**
     * @title Iframe border color
     * @description CSS for the border of organization's iframe element
     * @title_de Iframe border color
     * @description_de CSS for the border of organization's iframe element
     */
    iframeBorder: string

    /**
     * @title Hide email address
     * @description Set to true if you want to hide email address on company detail page.
     * @title_de Hide email address
     * @description_de Set to true if you want to hide email address on company detail page.
     */
    hideEmailAddress: boolean

    /**
     * @title Hide telephone address
     * @description Set to true if you want to hide telephone on company detail page.
     * @title_de Hide telephone address
     * @description_de Set to true if you want to hide telephone on company detail page.
     */
    hideTelephone: boolean
    /**
     * @title RemoteLivestreams title
     * @description Title which is shown above the remote livestreams section on company detail page.
     * @title_de RemoteLivestreams title
     * @description_de Title which is shown above the remote livestreams section on company detail page.
     */
    remoteLivestreamsTitle: LocalizedString

    /**
     * @title List layout title color
     * @description Color of the title in list layout
     * @title_de List layout title color
     * @description_de Color of the title in list layout
     */
    staffListViewTitleColor: string

    /**
     * @title List layout title font
     * @description font of the title in list layout
     * @title_de List layout title font
     * @description_de font of the title in list layout
     */
    staffListViewTitleFont: string

    /**
     * @title List layout title color
     * @description Color of the title in list layout
     * @title_de List layout title color
     * @description_de Color of the title in list layout
     */
    listViewEntitiesTitleColor: string

    /**
     * @title List layout title font
     * @description font of the title in list layout
     * @title_de List layout title font
     * @description_de font of the title in list layout
     */
    listViewEntitiesTitleFont: string

    /**
     * @title View all button text
     * @description Text in button for revealing all elements of company detail page in mobile version
     * @title_de View all button text
     * @description_de Text in button for revealing all elements of company detail page in mobile version
     */
    viewAllButtonText: LocalizedString

    /**
     * @title View less button text
     * @description Text in button for showing less elements of company detail page in mobile version
     * @title_de View less button text
     * @description_de Text in button for showing less elements of company detail page in mobile version
     */
    viewLessButtonText: LocalizedString

    /**
     * @title Mobile breadcrumb label
     * @description Text used for breadcrumb in mobile version
     * @title_de Mobile breadcrumb label
     * @description_de Text used for breadcrumb in mobile version
     */
    mobileBreadcrumbLabel: LocalizedString

    /**
     * @title Description label
     * @description Label for company description text
     * @title_de Description label
     * @description_de Label for company description text
     */
    descriptionLabel: LocalizedString

    /**
     * @title Halle stand section title
     * @description Title for the halle and stand section inside of the detail page info.
     * @title_de Halle stand section title
     * @description_de Title for the halle and stand section inside of the detail page info.
     */
    halleStandSectionTitle: LocalizedString

    /**
     * @title Background color for halle stand info.
     * @description Background color for the single halle and stand info block inside of the detail page.
     * @title_de Background color for halle stand info.
     * @description_de Background color for the single halle and stand info block inside of the detail page.
     */
    halleStandItemBgColor: string

    /**
     * @title Color for halle stand info.
     * @description Color for the single halle and stand info block inside of the detail page.
     * @title_de Color for halle stand info.
     * @description_de Color for the single halle and stand info block inside of the detail page.
     */
    halleStandItemColor: string

    /**
     * @title Halle stand link enabled
     * @description Toggle the linking of the hall/stand badge to the hallplan page
     * @title_de Halle stand link enabled
     * @description_de Toggle the linking of the hall/stand badge to the hallplan page
     */
    halleStandLinkEnabled: boolean

    /**
     * @title Link tooltip
     * @description Tooltip text for links in the links section
     * @title_de Link tooltip
     * @description_de Tooltip text for links in the links section
     */
    copyToClipboardText: LocalizedString

    /**
     * @title Join info desk hint
     * @description Tooltip text for showroom button
     * @title_de Join info desk hint
     * @description_de Tooltip text for showroom button
     */
    joinInfoDeskHint: LocalizedString

    /**
     * @title Meeting request modal title placeholer
     * @description Placeholder in the input field when requesting a meeting
     * @title_de Meeting request modal title placeholer
     * @description_de Placeholder in the input field when requesting a meeting
     */
    meetingRequestModalTitlePlaceholder: LocalizedString
}

export interface MeetingSlotsSectionBranding {
    /**
     * @title Meeting Slots Section Visible
     * @description Visibility of meeting slots section.
     * @title_de Meeting Slots Section Visible
     * @description_de Visibility of meeting slots section.
     */
    visible: boolean

    /**
     * @title Meeting Slots Section Title
     * @description Title for the meeting slots section.
     * @title_de Meeting Slots Section Title
     * @description_de Title for the meeting slots section.
     */
    title: LocalizedString

    /**
     * @title Show More Button Active Label
     * @description Label for the show more button when it's active.
     * @title_de Show More Button Active Label
     * @description_de Label for the show more button when it's active.
     */
    showMoreButtonActiveLabel: LocalizedString

    /**
     * @title Show More Button Inactive Label
     * @description Label for the show more button when it's inactive.
     * @title_de Show More Button Inactive Label
     * @description_de Label for the show more button when it's inactive.
     */
    showMoreButtonInactiveLabel: LocalizedString

    /**
     * @title Predefined Meeting Title Template
     * @description Title template for the meeting.
     * @title_de Predefined Meeting Title Template
     * @description_de Title template for the meeting.
     */
    predefinedMeetingTitleTemplate: LocalizedString

    /**
     * @title Time Slot Interval Minutes
     * @description Interval for time slots in minutes.
     * @title_de Time Slot Interval Minutes
     * @description_de Interval for time slots in minutes.
     */
    timeSlotIntervalMinutes: number

    /**
     * @title Meeting Slots Section Start Date
     * @description Start date for meeting days in format YYYY-MM-DD
     * @title_de Meeting Slots Section Start Date
     * @description_de Start date for meeting days in format YYYY-MM-DD
     */
    startDate: string

    /**
     * @title Meeting Slots Section End Date
     * @description End date for meeting days in format YYYY-MM-DD
     * @title_de Meeting Slots Section End Date
     * @description_de End date for meeting days in format YYYY-MM-DD
     */
    endDate: string

    /**
     * @title Meeting Slots Section Start Time
     * @description Start time for meeting day in a format HH:mm
     * @title_de Meeting Slots Section Start Time
     * @description_de Start time for meeting day in a format HH:mm
     */
    startTime: string

    /**
     * @title Meeting Slots Section End Time
     * @description End time for meeting day in a format HH:mm
     * @title_de Meeting Slots Section End Time
     * @description_de End time for meeting day in a format HH:mm
     */
    endTime: string

    /**
     * @title Meeting Slots Section Title Color
     * @description Color for the meeting slots section title.
     * @title_de Meeting Slots Section Title Color
     * @description_de Color for the meeting slots section title.
     */
    titleColor: ColorString

    /**
     * @title Date Picker Input Border Color
     * @description Border color for the date picker input.
     * @title_de Date Picker Input Border Color
     * @description_de Border color for the date picker input.
     */
    datePickerInputBorderColor: ColorString

    /**
     * @title Date Picker Input Text Color
     * @description Text color for the date picker input.
     * @title_de Date Picker Input Text Color
     * @description_de Text color for the date picker input.
     */
    datePickerInputTextColor: ColorString

    /**
     * @title Mobile Arrow Box Background Color
     * @description Background color for the mobile arrow box.
     * @title_de Mobile Arrow Box Background Color
     * @description_de Background color for the mobile arrow box.
     */
    mobileArrowBoxBgColor: ColorString

    /**
     * @title Mobile Arrow Box Border Color
     * @description Border color for the mobile arrow box.
     * @title_de Mobile Arrow Box Border Color
     * @description_de Border color for the mobile arrow box.
     */
    mobileArrowBoxBorderColor: ColorString

    /**
     * @title Mobile Arrow Icon Color
     * @description Color for the mobile arrow icon.
     * @title_de Mobile Arrow Color
     * @description_de Color color for the mobile arrow icon.
     */
    mobileArrowIconColor: ColorString

    /**
     * @title Mobile Arrow Box Background Color Disabled
     * @description Background color for the disabled mobile arrow box.
     * @title_de Mobile Arrow Box Background Color Disabled
     * @description_de Background color for the disabled mobile arrow box.
     */
    mobileArrowBoxBgColorDisabled: ColorString

    /**
     * @title Mobile Arrow Box Border Color Disabled
     * @description Border color for the disabled mobile arrow box.
     * @title_de Mobile Arrow Box Border Color Disabled
     * @description_de Border color for the disabled mobile arrow box.
     */
    mobileArrowBoxBorderColorDisabled: ColorString

    /**
     * @title Mobile Arrow Icon Color Disabled
     * @description Color for the disabled mobile arrow icon.
     * @title_de Mobile Arrow Color Disabled
     * @description_de Color color for the disabled mobile arrow icon.
     */
    mobileArrowIconColorDisabled: ColorString

    /**
     * @title Desktop Arrow Icon Color
     * @description Color for the desktop arrow icon.
     * @title_de Desktop Arrow Color
     * @description_de Color color for the desktop arrow icon.
     */
    desktopArrowIconColor: ColorString

    /**
     * @title Desktop Arrow Icon Color Disabled
     * @description Color for the disabled desktop arrow icon.
     * @title_de Desktop Arrow Color Disabled
     * @description_de Color color for the disabled desktop arrow icon.
     */
    desktopArrowIconColorDisabled: ColorString

    /**
     * @title Time Slot Background Color
     * @description Background color for the time slot.
     * @title_de Time Slot Background Color
     * @description_de Background color for the time slot.
     */
    timeSlotBgColor: ColorString

    /**
     * @title Time Slot Background Color Disabled
     * @description Background color for the disabled time slot.
     * @title_de Time Slot Background Color Disabled
     * @description_de Background color for the disabled time slot.
     */
    timeSlotBgColorDisabled: ColorString

    /**
     * @title Time Slot Hover Background Color
     * @description Background color for the time slot on hover.
     * @title_de Time Slot Hover Background Color
     * @description_de Background color for the time slot on hover.
     */
    timeSlotHoverBgColor: ColorString

    /**
     * @title Time Slot Color
     * @description Color for the time slot.
     * @title_de Time Slot Color
     * @description_de Color for the time slot.
     */
    timeSlotColor: ColorString

    /**
     * @title Time Slot Hover Color
     * @description Color for the time slot on hover.
     * @title_de Time Slot Hover Color
     * @description_de Color for the time slot on hover.
     */
    timeSlotHoverColor: ColorString

    /**
     * @title Time Slot Color
     * @description Color for the disabled time slot.
     * @title_de Time Slot Color
     * @description_de Color for the disabled time slot.
     */
    timeSlotColorDisabled: ColorString

    /**
     * @title Time Slot Border Color
     * @description Border color for the time slot.
     * @title_de Time Slot Border Color
     * @description_de Border color for the time slot.
     */
    timeSlotBorderColor: ColorString

    /**
     * @title Time Table Header Color
     * @description Color for the time table header.
     * @title_de Time Table Header Color
     * @description_de Color for the time table header.
     */
    timeTableHeaderColor: ColorString

    /**
     * @title Show More Button Background Color
     * @description Background color for the show more button.
     * @title_de Show More Button Background Color
     * @description_de Background color for the show more button.
     */
    showMoreButtonBgColor: ColorString

    /**
     * @title Show More Button Background Hover Color
     * @description Background color for the show more button on hover.
     * @title_de Show More Button Background Hover Color
     * @description_de Background color for the show more button on hover.
     */
    showMoreButtonBgHoverColor: ColorString

    /**
     * @title Show More Button Color
     * @description Color for the show more button.
     * @title_de Show More Button Color
     * @description_de Color for the show more button.
     */
    showMoreButtonColor: ColorString

    /**
     * @title Show More Button Hover Color
     * @description Color for the show more button on hover.
     * @title_de Show More Button Hover Color
     * @description_de Color for the show more button on hover.
     */
    showMoreButtonHoverColor: ColorString

    /**
     * @title Show More Button Border Color
     * @description Border color for the show more button.
     * @title_de Show More Button Border Color
     * @description_de Border color for the show more button.
     */
    showMoreButtonBorderColor: ColorString

    /**
     * @title Show More Button Icon Color
     * @description Icon color for the show more button.
     * @title_de Show More Button Icon Color
     * @description_de Icon color for the show more button.
     */
    showMoreButtonIconColor: ColorString

    /**
     * @title Show More Button Icon Hover Color
     * @description Icon color for the show more button on hover.
     * @title_de Show More Button Icon Hover Color
     * @description_de Icon color for the show more button on hover.
     */
    showMoreButtonIconHoverColor: ColorString
}

export interface PersonDetailPageContentBranding {
    /**
     * @title Navbar text
     * @description Text for person detail page title
     * @title_de Navbar text
     * @description_de Text for person detail page title
     */
    navbarText: LocalizedString
    /**
     * @title Cancel request message
     * @description Text for cancel request message
     * @title_de Cancel request message
     * @description_de Text for cancel request message
     */
    cancelRequestMessage: LocalizedString
    /**
     * @title Cancel connection message
     * @description Text for cancel connection message
     * @title_de Cancel connection message
     * @description_de Text for cancel connection message
     */
    cancelConnectionMessage: LocalizedString
    /**
     * @title Ignored message
     * @description Text for ignored request message
     * @title_de Ignored message
     * @description_de Text for ignored request message
     */
    ignoredMessage: LocalizedString
    /**
     * @title Open request message
     * @description Text for open request message
     * @title_de Open request message
     * @description_de Text for open request message
     */
    openRequestMessage: LocalizedString
    /**
     * @title Send request message
     * @description Text for send request message
     * @title_de Send request message
     * @description_de Text for send request message
     */
    sendRequestMessage: LocalizedString
    /**
     * @title Chat text
     * @description Text for Chat label
     * @title_de Chat text
     * @description_de Text for Chat label
     */
    chatText: LocalizedString
    /**
     * @title Video call text
     * @description Text for Video call label
     * @title_de Video call text
     * @description_de Text for Video call label
     */
    videoCallText: LocalizedString
    /**
     * @title Voice call text
     * @description Text for Voice call label
     * @title_de Voice call text
     * @description_de Text for Voice call label
     */
    voiceCallText: LocalizedString
    /**
     * @title Voice calling text
     * @description Text for calling label
     * @title_de Voice calling text
     * @description_de Text for calling label
     */
    voiceCallingText: LocalizedString
    /**
     * @title Meeting text
     * @description Text for meeting label
     * @title_de Meeting text
     * @description_de Text for meeting label
     */
    meetingText: LocalizedString
    /**
     * @title Live sessions title
     * @description Text for Live sessions label
     * @title_de Live sessions title
     * @description_de Text for Live sessions label
     */
    liveSessionsTitle: LocalizedString
    /**
     * @title Upcoming sessions title
     * @description Text for Upcoming sessions label
     * @title_de Upcoming sessions title
     * @description_de Text for Upcoming sessions label
     */
    upcomingSessionsTitle: LocalizedString
    /**
     * @title Past sessions title
     * @description Text for past sessions label
     * @title_de Past sessions title
     * @description_de Text for past sessions label
     */
    pastSessionsTitle: LocalizedString
    /**
     * @title Interests title
     * @description Text for Interests label
     * @title_de Interests title
     * @description_de Text for Interests label
     */
    interestsTitle: LocalizedString
    /**
     * @title Offers title
     * @description Text for Offers label
     * @title_de Offers title
     * @description_de Text for Offers label
     */
    offersTitle: LocalizedString
    /**
     * @title Needs title
     * @description Text for Needs label
     * @title_de Needs title
     * @description_de Text for Needs label
     */
    needsTitle: LocalizedString
    /**
     * @title View more categories button text
     * @description Text for view more categories button
     * @title_de View more categories button text
     * @description_de Text for view more categories button
     */
    viewMoreCategoriesButtonText: LocalizedString
    /**
     * @title Categories limit number
     * @description Number of categories initially shown in categories lists in mobile version
     * @title_de Categories limit number
     * @description_de Number of categories initially shown in categories lists in mobile version
     */
    categoriesLimitNumber: number
    /**
     * @title No interests selected message
     * @description Text for no selected interests message
     * @title_de No interests selected message
     * @description_de Text for no selected interests message
     */
    noInterestsSelectedMessage: LocalizedString
    /**
     * @title Person detail page speaker title name
     * @description Text color for speaker title inside of the person detail page
     * @title_de Person detail page speaker title name
     * @description_de Text color for speaker title inside of the person detail page
     */
    personDetailPageSpeakerTitleName: ColorString
    /**
     * @title Person detail page speaker title position
     * @description Text color for speaker position inside of the person detail page
     * @title_de Person detail page speaker title position
     * @description_de Text color for speaker position inside of the person detail page
     */
    personDetailPageSpeakerTitlePosition: ColorString
    /**
     * @title Person contact details container color
     * @description Text color for person contact details container
     * @title_de Person contact details container color
     * @description_de Text color for person contact details container
     */
    personContactDetailsContainerTextColor: ColorString
    /**
     * @title Person contact details container color on hover
     * @description Text color on hover for person contact details container
     * @title_de Person contact details container color on hover
     * @description_de Text color on hover for person contact details container
     */
    personContactDetailsContainerTextColorOnHover: ColorString
    /**
     * @title Organization logo div
     * @description Border details for organization logo div - Example: 1px solid #000
     * @title_de Organization logo div
     * @description_de Border details for organization logo div - Example: 1px solid #000
     */
    organizationLogoDiv: string
    /**
     * @title Company logo div
     * @description Border details for Company logo div - Example: 1px solid #000
     * @title_de Company logo div
     * @description_de Border details for Company logo div - Example: 1px solid #000
     */
    companyLogoDiv: string
    /**
     * @title Upcoming sessions title color
     * @description Text color for Upcoming sessions title color
     * @title_de Upcoming sessions title color
     * @description_de Text color for Upcoming sesstions title color
     */
    upcomingSessionsTitleTextColor: ColorString
    /**
     * @title Formatted date div
     * @description Border details for Formatted date div - Example: 1px solid #000
     * @title_de Formatted date div
     * @description_de Border details for Formatted date div - Example: 1px solid #000
     */
    formattedDateDiv: string
    /**
     * @title Interests item border color
     * @description Border color and details for interests item - Example: 1px solid #000
     * @title_de Interests item border color
     * @description_de Border color and details for interests item - Example: 1px solid #000
     */
    interestBorderColor: string
    /**
     * @title Interests item text color
     * @description Text color interests item
     * @title_de Interests item text color
     * @description_de Text color interests item
     */
    interestTextColor: ColorString
    /**
     * @title Common interests item border color
     * @description Text color for common interests item
     * @title_de Common interests item border color
     * @description_de Text color for common interests item
     */
    commonInterestTextColor: string
    /**
     * @title Upcoming session share icon color
     * @description Fill color for upcoming session share icon
     * @title_de Upcoming session share icon color
     * @description_de Fill color for upcoming session share icon
     */
    upcomingSessionShareIconColor: ColorString
    /**
     * @title Live tile header color
     * @description Text color for live tile header label
     * @title_de Live tile header color
     * @description_de Text color for live tile header label
     */
    liveTileHeaderTextColor: ColorString

    /**
     * @title Bookmark events popup visibility
     * @description When a user bookmarks a speaker, show a pop-up asking the user whether they want to bookmark all the eventDates the speaker is participating in
     * @title_de Bookmark events popup visibility
     * @description_de When a user bookmarks a speaker, show a pop-up asking the user whether they want to bookmark all the eventDates the speaker is participating in
     */
    bookmarkEventsPopupVisible: boolean

    /**
     * @title Bookmark events popup text
     * @description Text in a popup optionally shown when a user bookmarks a speaker
     * @title_de Bookmark events popup text
     * @description_de Text in a popup optionally shown when  auser bookmarks a speaker
     */
    bookmarkEventsPopupText: LocalizedString

    /**
     * @title Bookmark events confirm button text
     * @description Text of the confirm button in a popup optionally shown when a user bookmarks a speaker
     * @title_de Bookmark events confirm button text
     * @description_de Text of the confirm button in a popup optionally shown when a user bookmarks a speaker
     */
    bookmarkEventsPopupConfirmButtonText: LocalizedString

    /**
     * @title Bookmark events cancel button text
     * @description Text of the cancel button in a popup optionally shown when a user bookmarks a speaker
     * @title_de Bookmark events cancel button text
     * @description_de Text of the cancel button in a popup optionally shown when  auser bookmarks a speaker
     */
    bookmarkEventsPopupCancelButtonText: LocalizedString

    /**
     * @title Share speaker button text
     * @description Text in button for sharing the speaker in speaker's profile page
     * @title_de Share speaker button text
     * @description_de Text in button for sharing the speaker in speaker's profile page
     */
    shareSpeakerButtonText: LocalizedString

    /**
     * @title Bookmark speaker button text
     * @description Text in button for bookmarking the speaker in speaker's profile page
     * @title_de Bookmark speaker button text
     * @description_de Text in button for bookmarking the speaker in speaker's profile page
     */
    bookmarkSpeakerButtonText: LocalizedString

    /**
     * @title Unbookmark speaker button text
     * @description Text in button for unbookmarking all of the speaker's events in speaker's profile page
     * @title_de Unbookmark speaker button text
     * @description_de Text in button for unbookmarking all of the speaker's events in speaker's profile page
     */
    unbookmarkSpeakerButtonText: LocalizedString

    /**
     * @title Bookmark all events button text
     * @description Text in button for bookmarking all of the speaker's events in speaker's profile page
     * @title_de Bookmark all events button text
     * @description_de Text in button for bookmarking all of the speaker's events in speaker's profile page
     */
    bookmarkAllEventsButtonText: LocalizedString

    /**
     * @title Unbookmark all events button text
     * @description Text in button for unbookmarking all of the speaker's events in speaker's profile page
     * @title_de Unbookmark all events button text
     * @description_de Text in button for unbookmarking all of the speaker's events in speaker's profile page
     */
    unbookmarkAllEventsButtonText: LocalizedString

    /**
     * @title Meeting Slots Section
     * @description Configuration for meeting slots section.
     * @title_de MeetingSlotsSection
     * @description_de Configuration for meeting slots section.
     */
    meetingSlotsSection: MeetingSlotsSectionBranding

    /**
     * @title Show phone number
     * @description Toggle visibility of phone number
     * @title_de Show phone number
     * @description_de Toggle visibility of phone number
     */
    showPhoneNumber: boolean

    /**
     * @title Show mobile number
     * @description Toggle visibility of mobile number
     * @title_de Show mobile number
     * @description_de Toggle visibility of mobile number
     */
    showMobileNumber: boolean
}

export interface ProductDetailPageContentBranding {
    /**
     * @title Navbar text
     * @description Text for product detail page title
     * @title_de Navbar text
     * @description_de Text for product detail page title
     */
    navbarText: LocalizedString
    /**
     * @title Sections
     * @description Available sections and order of them for the product detail page
     * @title_de Sections
     * @description_de Available sections and order of them for the product detail page
     */
    sectionOrder: Array<DetailSection>
    /**
     * @title Info content order
     * @description Order for the Info content part of the page inside of the product detail page
     * @title_de Info content order
     * @description_de Order for the Info content part of the page inside of the product detail pag
     */
    infoContentOrder: Array<InfoContentOrderType>
    /**
     * @title Root categories
     * @description Root categories for product categories
     * @title_de Root categories
     * @description_de Root categories for product categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the product detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the product detail page
     */
    showCategoryHierarchy: boolean
    /**
     * @title Media full screen text
     * @description Text that will indicate that the user can click to opet the gallery in full screen
     * @title_de Media full screen text
     * @description_de Text that will indicate that the user can click to opet the gallery in full screen
     */
    mediaFullScreenText: LocalizedString
    /**
     * @title Show more text
     * @description Text that will be showed on the product detail page description if its length goes over 900 caracters
     * @title_de Show more text
     * @description_de Text that will be showed on the product detail page description if its length goes over 900 caracters
     */
    showMoreText: LocalizedString
    /**
     * @title Show less text
     * @description Text that will be showed if the show more text is clicked. Product detail page description.
     * @title_de Show less text
     * @description_de Text that will be showed if the show more text is clicked. Product detail page description.
     */
    showLessText: LocalizedString
    /**
     * @title Halle stand section title
     * @description Title for the halle and stand section inside of the detail page info.
     * @title_de Halle stand section title
     * @description_de Title for the halle and stand section inside of the detail page info.
     */
    halleStandSectionTitle: LocalizedString
}

export interface TrademarkDetailPageContentBranding {
    /**
     * @title Navbar text
     * @description Text for trademarks detail page title
     * @title_de Navbar text
     * @description_de Text for trademarks detail page title
     */
    navbarText: LocalizedString
    /**
     * @title Sections
     * @description Available sections and order of them for the trademarks detail page
     * @title_de Sections
     * @description_de Available sections and order of them for the trademarks detail page
     */
    sectionOrder: Array<DetailSection>
    /**
     * @title Info content order
     * @description Order for the Info content part of the page inside of the trademarks detail page
     * @title_de Info content order
     * @description_de Order for the Info content part of the page inside of the trademarks detail pag
     */
    infoContentOrder: Array<InfoContentOrderType>
    /**
     * @title Root categories
     * @description Root categories for trademark categories
     * @title_de Root categories
     * @description_de Root categories for trademark categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the trademark detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the trademark detail page
     */
    showCategoryHierarchy: boolean

    /**
     * @title Downloads title
     * @description Text for downloads section title
     * @title_de Downloads title
     * @description_de Text for downloads section title
     */
    downloadsTitle: LocalizedString

    /**
     * @title Download title
     * @description Text for download section title
     * @title_de Download title
     * @description_de Text for download section title
     */
    downloadTitle: LocalizedString
}

export interface JobOfferDetailPageContentBranding {
    /**
     * @title Navbar text
     * @description Text for joboffer detail page title
     * @title_de Navbar text
     * @description_de Text for joboffer detail page title
     */
    navbarText: LocalizedString

    /**
     * @title Sections
     * @description Available sections and order of them for the joboffer detail page
     * @title_de Sections
     * @description_de Available sections and order of them for the joboffer detail page
     */
    sections: Array<DetailSection>

    /**
     * @title Info content order
     * @description Order for the Info content part of the page inside of the joboffer detail page
     * @title_de Info content order
     * @description_de Order for the Info content part of the page inside of the joboffer detail pag
     */
    infoContentOrder: Array<InfoContentOrderType>

    /**
     * @title Downloads title
     * @description Text for downloads section title
     * @title_de Downloads title
     * @description_de Text for downloads section title
     */
    downloadsTitle: LocalizedString

    /**
     * @title Download title
     * @description Text for download section title
     * @title_de Download title
     * @description_de Text for download section title
     */
    downloadTitle: LocalizedString

    /**
     * @title Person List title
     * @description Person list title
     * @title_de Personenlisten Titel
     * @description_de Titel der Personen Liste
     */
    personListTitle: LocalizedString

    /**
     * @title Description title
     * @description Description title
     * @title_de Beschreibungstitel
     * @description_de Beschreibungstitel
     */
    descriptionTitle: LocalizedString

    /**
     * @title Requirements title
     * @description Requirements title
     * @title_de Voraussetzungentitel
     * @description_de Voraussetzungentitel
     */
    requirementsTitle: LocalizedString

    /**
     * @title Start date pattern
     * @description start date pattern. see https://date-fns.org/v2.28.0/docs/format
     * @title_de Startdatum format
     * @description_de Format für das Startdatum. Siehe https://date-fns.org/v2.28.0/docs/format
     */
    startDatePattern: LocalizedString

    /**
     * @title Home office title
     * @description Home office title
     * @title_de Home office title
     * @description_de Home office title
     */
    homeOfficeTitle: LocalizedString

    /**
     * @title Root categories
     * @description Root categories for job offer categories
     * @title_de Root categories
     * @description_de Root categories for job offer categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the job offer detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the job offer detail page
     */
    showCategoryHierarchy: boolean
}

export interface NewsDetailPageContentBranding {
    /**
     * @title Sections
     * @description Available sections and order of them for the news detail page
     * @title_de Sections
     * @description_de Available sections and order of them for the news detail page
     */
    sections: Array<DetailSection>
    /**
     * @title Info content order
     * @description Order for the Info content part of the page inside of the news detail page
     * @title_de Info content order
     * @description_de Order for the Info content part of the page inside of the news detail pag
     */
    infoContentOrder: Array<InfoContentOrderType>
    /**
     * @title Root categories
     * @description Root categories for news categories
     * @title_de Root categories
     * @description_de Root categories for news categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the news detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the news detail page
     */
    showCategoryHierarchy: boolean
    /**
     * @title Authors section title
     * @description Title for list of news authors
     * @title_de Authors section title
     * @description_de Title for list of news authors
     */
    authorsSectionTitle: LocalizedString
}

export interface ProductsPageContentBranding {
    /**
     * @title Navigation search placeholder products
     * @description Text for search products placeholder
     * @title_de Navigation search placeholder products
     * @description_de Text for search products placeholder
     */
    navigationSearchPlaceholderProducts: LocalizedString
    /**
     * @title Loading message
     * @description Text for loading label
     * @title_de Loading message
     * @description_de Text for loading label
     */
    loadingMessage: LocalizedString
    /**
     * @title No bookmarks message
     * @description Text for empty search results for bookmarked products
     * @title_de No bookmarks message
     * @description_de Text for empty search results for bookmarked products
     */
    noBookmarksMessage: LocalizedString
}

export enum EventDateDetailSection {
    BASIC_INFO = "BASIC_INFO",
    TYPEFORM = "TYPEFORM",
    COMPANY_DETAILS = "COMPANY_DETAILS",
    DESCRIPTION_PERSONS = "DESCRIPTION_PERSONS",
    EVENT_LAYOUT = "EVENT_LAYOUT",
    PRIVATE_EVENT_ACCESS_VIEW = "PRIVATE_EVENT_ACCESS_VIEW",
    LINKS = "LINKS",
    MEDIA = "MEDIA",
    NON_GALLERY_MEDIA = "NON_GALLERY_MEDIA",
    CATEGORIES = "CATEGORIES",
    COMING_UP = "COMING_UP",
    EVENTDATES = "EVENTDATES"
}

export interface EventDateDetailPageContentBranding {
    /**
     * @title Company details section header
     * @description Header text for company details section on the Event date detail page
     * @title_de Company details section header
     * @description_de Header text for company details section on the Event date detail page
     */
    companyDetailsHeader: LocalizedString
    /**
     * @title Event layout title
     * @description Title for event layout part of the Event date detail page
     * @title_de Event layout title
     * @description_de Title for event layout part of the Event date detail page
     */
    eventLayoutTitle: LocalizedString
    /**
     * @title Description text for event date
     * @description Text for description of the event date on the Event detail page
     * @title_de Description text for event date
     * @description_de Text for description of the event date on the Event detail page
     */
    descriptionTextForEventDate: LocalizedString
    /**
     * @title Description text for event dates binded to event
     * @description Title for description of the event dates binded to the Events
     * @title_de Description text for event dates binded to event
     * @description_de Title for description of the event dates binded to the Events
     */
    descriptionForEventDatesBindedToEvent: LocalizedString
    /**
     * @title Event layout visible on eventDateDetail page
     * @description Enable / disable visibility of event layout on event date detail page
     * @title_de Event layout visible on eventDateDetail page
     * @description_de Enable / disable visibility of event layout on event date detail page
     */
    eventLayoutVisibleOnEventDateDetailPage: boolean
    /**
     * @title Media visible on eventDateDetail page
     * @description Enable / disable visibility of Media on event date detail page
     * @title_de Media visible on eventDateDetail page
     * @description_de Enable / disable visibility of Media on event date detail page
     */
    mediaVisibleOnEventDateDetailPage: boolean
    /**
     * @title Downloads visible on eventDateDetail page
     * @description Enable / disable visibility of Downloads on event date detail page
     * @title_de Downloads visible on eventDateDetail page
     * @description_de Enable / disable visibility of Downloads on event date detail page
     */
    downloadsVisibleOnEventDateDetailPage: boolean
    /**
     * @title Persons title
     * @description Text for persons title on event date detail page
     * @title_de Persons title
     * @description_de Text for persons title on event date detail page
     */
    personsTitle: LocalizedString
    /**
     * @title No persons message
     * @description Text for empty search of persons message
     * @title_de No persons message
     * @description_de Text for empty search of persons message
     */
    noPersonsMessage: LocalizedString
    /**
     * @title Gallery title
     * @description Text for gallery title
     * @title_de Gallery title
     * @description_de Text for gallery title
     */
    galleryTitle: LocalizedString
    /**
     * @title No section title
     * @description Text for no section title
     * @title_de No section title
     * @description_de Text for no section title
     */
    noSectionTitle: LocalizedString
    /**
     * @title Event date round table no access text
     * @description Text for no access message of the event date round table
     * @title_de Event date round table no access text
     * @description_de Text for no access message of the event date round table
     */
    eventDateRoundTableNoAccessText: LocalizedString
    /**
     * @title Event date round granted access text
     * @description Text for granted access message of the event date round table
     * @title_de Event date round granted access text
     * @description_de Text for granted access message of the event date round table
     */
    eventDateRoundTableGrantedAccessText: LocalizedString
    /**
     * @title Private event date no access text
     * @description Text for no access message of the private event date
     * @title_de Private event date no access text
     * @description_de Text for no access message of the private event date
     */
    privateEventDateNoAccessText: LocalizedString
    /**
     * @title Private event date no access via tickets text
     * @description Private event date user has no ticket that grants them access
     * @title_de Private Veranstaltung Nutzer kein Ticket
     * @description_de Private Veranstaltung: wird angezeigt, wenn der Nutzer oder die Nutzerin kein passendes Ticket hat, dass für den Zugan nötig ist
     */
    privateEventDateTicketNoAccessText: LocalizedString
    /**
     * @title Private event date no tickets available
     * @description Private event date: no tickets are available yet and therefore no ticket-shop-links are available
     * @title_de Private Veranstaltung keine Tickets verfügbar
     * @description_de Text für die Nachricht, die erscheint, wenn keine Tickets bzw. keine Links zum Ticketshop zur Verfügung stehen
     */
    privateEventDateNoTicketsAvailable: LocalizedString
    /**
     * @title Get Ticket Button Text
     * @description Text displayed on button that is redirecting user to ticket shop
     * @title_de Ticket Kaufen Button Text
     * @description_de Text auf Knopf, der Nutzer_innen zum Ticketshop weiterleitet
     */
    getTicketButtonText: LocalizedString
    /**
     * @title Private event date granted access text
     * @description Text for granted access message of the private event date
     * @title_de Private event date granted access text
     * @description_de Text for granted access message of the private event date
     */
    privateEventDateGrantedAccessText: LocalizedString
    /**
     * @title Event date round table post event text
     * @description Text for post event usage of the round table
     * @title_de Event date round table post event text
     * @description_de Text for post event usage of the round table
     */
    eventDateRoundTablePostEventText: LocalizedString
    /**
     * @title Event date round table button access granted live
     * @description Text for access granted live button
     * @title_de Event date round table button access granted live
     * @description_de Text for access granted live button
     */
    eventDateRoundTableButtonAccessGrantedLive: LocalizedString
    /**
     * @title Event date round table button post event
     * @description Text for expired round table in post event phase
     * @title_de Event date round table button post event
     * @description_de Text for expired round table in post event phase
     */
    eventDateRoundTableButtonPostEvent: LocalizedString
    /**
     * @title Event date round table button countdown
     * @description Countdown button text in singular and plural for the round table
     * @title_de Event date round table button countdown
     * @description_de Countdown button text in singular and plural for the round table
     */
    eventDateRoundTableButtonCountdown: PluralString
    /**
     * @title Chat visible
     * @description Enable / disable visibility of the chat item in the event detail page switcher
     * @title_de Chat visible
     * @description_de Enable / disable visibility of the chat item in the event detail page switcher
     */
    chatVisible: boolean
    /**
     * @title Polls visible
     * @description Enable / disable visibility of the polls item in the event detail page switcher
     * @title_de Polls visible
     * @description_de Enable / disable visibility of the polls item in the event detail page switcher
     */
    pollsVisible: boolean
    /**
     * @title Q&A visible
     * @description Enable / disable visibility of the Q&A item in the event detail page switcher
     * @title_de Q&A visible
     * @description_de Enable / disable visibility of the Q&A item in the event detail page switcher
     */
    qandaVisible: boolean
    /**
     * @title Participants visible
     * @description Enable / disable visibility of the Participants item in the event detail page switcher
     * @title_de Participants visible
     * @description_de Enable / disable visibility of the Participants item in the event detail page switcher
     */
    participantsVisible: boolean
    /**
     * @title Multi switch items
     * @description Array of multi switch items for event date detail page
     * @title_de Multi switch items
     * @description_de Array of multi switch items for event date detail page
     */
    multiSwitchItems: Array<string>
    /**
     * @title Multi switch item visible
     * @description Enable / disable visibility of the complete multi switch items part of detail page
     * @title_de Multi switch item visible
     * @description_de Enable / disable visibility of the complete multi switch items part of detail page
     */
    multiSwitchItemVisible: boolean
    /**
     * @title Link section visible
     * @description Show/hide link section
     * @title_de Theme color
     * @description_de Linkabschnitt anzeigen
     */
    linksVisible: boolean
    /**
     * @title Categories section toggle
     * @description Set true if you want to show categories section on the event date detail page
     * @title_de Categories section toggle
     * @description_de Set true if you want to show categories section on the event date detail page
     */
    showCategories: boolean
    /**
     * @title Root categories
     * @description Root categories for event date categories
     * @title_de Root categories
     * @description_de Root categories for event date categories
     */
    rootCategories: Array<string>
    /**
     * @title Hierarchy for categories
     * @description Set to true if you want to get hierarchy for the categories on the event date detail page
     * @title_de Hierarchy for categories
     * @description_de Set to true if you want to get hierarchy for the categories on the event date detail page
     */
    showCategoryHierarchy: boolean
    /**
     * @title Channel participants tab title
     * @description Title for participants tab on channel page
     * @title_de Channel participants tab title
     * @description_de Title for participants tab on channel page
     */
    channelParticipantsTabTitle: LocalizedString
    /**
     * @title No participants message
     * @description Message when there are no participants on the channel
     * @title_de No participants message
     * @description_de Message when there are no participants on the channel
     */
    noParticipantsMessage: LocalizedString

    /**
     * @title Typeform section enabled
     * @description Set visibility of Typeform section
     * @title_de Typeform section enabled
     * @description_de Set visibility of Typeform section
     */
    typeformSectionEnabled: boolean

    /**
     * @title Typeform section description text
     * @description Description text for the Typeform section
     * @title_de Typeform section description text
     * @description_de Description text for the Typeform section
     */
    typeformSectionDescriptionText: LocalizedString

    /**
     * @title Typeform section button text
     * @description Text for the button in the Typeform section
     * @title_de Typeform section button text
     * @description_de Text for the button in the Typeform section
     */
    typeformSectionButtonText: LocalizedString

    /**
     * @title Typeform section countdown visible
     * @description Description text for the Typeform section when the countdown is present
     * @title_de Typeform section countdown visible
     * @description_de Description text for the Typeform section when the countdown is present
     */
    typeformSectionCountdownVisible: boolean

    /**
     * @title Typeform section waiting description text
     * @description Enable / disable visibility of the countdown in the Typeform section
     * @title_de Typeform section waiting description text
     * @description_de Enable / disable visibility of the countdown in the Typeform section
     */
    typeformSectionWaitingDescriptionText: LocalizedString

    /**
     * @title Minutes before enabling Typeform section
     * @description Set how many minutes before the Typeform section should be visible
     * @title_de Minutes before enabling Typeform section
     * @description_de Set how many minutes before the Typeform section should be visible
     */
    minutesBeforeEnablingTypeformSection: number

    /**
     * @title Event page content order
     * @description Main order of sections inside of the tablet/desktop version of event detail page - Possible options: BASIC_INFO, DESCRIPTION_PERSONS, EVENTDATES, CATEGORIES
     * @title_de Event page content order
     * @description_de Main order of sections inside of the tablet/desktop version of event detail page - Possible options: BASIC_INFO, DESCRIPTION_PERSONS, EVENTDATES, CATEGORIES
     */
    eventDetailSectionsOrder: EventDateDetailSection[]

    /**
     * @title Eventdate page content order (tablet/desktop)
     * @description Main order of sections inside of the tablet/desktop version of eventdate detail page - Possible options: BASIC_INFO, TYPEFORM, COMPANY_DETAILS, DESCRIPTION_PERSONS, EVENT_LAYOUT, PRIVATE_EVENT_ACCESS_VIEW, LINKS, MEDIA, NON_GALLERY_MEDIA, CATEGORIES, COMING_UP
     * @title_de Eventdate page content order (tablet/desktop)
     * @description_de Main order of sections inside of the tablet/desktop version of eventdate detail page - Possible options: BASIC_INFO, TYPEFORM, COMPANY_DETAILS, DESCRIPTION_PERSONS, EVENT_LAYOUT, PRIVATE_EVENT_ACCESS_VIEW, LINKS, MEDIA, NON_GALLERY_MEDIA, CATEGORIES, COMING_UP
     */
    desktopSectionsOrder: EventDateDetailSection[]

    /**
     * @title Eventdate page content order (mobile)
     * @description Main order of sections inside of the mobile version of eventdate detail page - Possible options: BASIC_INFO, TYPEFORM, COMPANY_DETAILS, DESCRIPTION_PERSONS, EVENT_LAYOUT, PRIVATE_EVENT_ACCESS_VIEW, LINKS, MEDIA, NON_GALLERY_MEDIA, CATEGORIES, COMING_UP
     * @title_de Eventdate page content order (mobile)
     * @description_de Main order of sections inside of the mobile version of eventdate detail page - Possible options: BASIC_INFO, TYPEFORM, COMPANY_DETAILS, DESCRIPTION_PERSONS, EVENT_LAYOUT, PRIVATE_EVENT_ACCESS_VIEW, LINKS, MEDIA, NON_GALLERY_MEDIA, CATEGORIES, COMING_UP
     */
    mobileSectionsOrder: EventDateDetailSection[]
}
